<template>
  <v-card>
    <v-card-title>
      <span class="headline">{{ $ml.get("departments.department") }}</span>
    </v-card-title>

    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-text-field
          :label="`${$ml.get('departments.name')} *`"
          v-model="name"
          :rules="[(v) => !!v || this.$ml.get('message.NameIsRequired')]"
          required
        />
        <v-select
          item-text="name"
          item-value="id"
          :items="departmentsOptions"
          v-model="parent"
          :label="$ml.get('departments.parent')"
        />
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text @click="close">{{ $ml.get("button.close") }}</v-btn>
      <v-btn color="primary" text @click="store">{{
        $ml.get("button.save")
      }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: null,
    },
    departments: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    const name = this.data?.name || "";
    const parent = this.data?.parentId || null;

    return {
      valid: true,
      name,
      parent,

      isPending: false,
      isSuccess: false,
      isFailure: false,
    };
  },

  methods: {
    close() {
      this.name = "";
      this.parent = null;
      this.$refs.form.reset();
      this.$emit("close", false);
    },

    store() {
      if (this.$refs.form.validate()) {
        if (this.isEdit) {
          this.edit();
        } else {
          this.add();
        }
      }
    },

    add() {
      this.isPending = true;
      this.isSuccess = false;
      this.isFailure = false;

      const { name, parent: parent_id } = this;

      const data = {
        name,
        parent_id,
      };

      axios
        .post("department/add", data)
        .then(() => {
          this.isPending = false;
          this.isSuccess = true;
          this.isFailure = false;

          this.close();

          this.$emit("store");
        })
        .catch((error) => {
          this.isPending = false;
          this.isSuccess = false;
          this.isFailure = true;

          this.$notify({
            group: "info",
            type: "error",
            text: error.response.data.status.message,
          });
        });
    },

    edit() {
      this.isPending = true;
      this.isSuccess = false;
      this.isFailure = false;

      const {
        data: { id },
        name,
        parent: parent_id,
      } = this;

      const data = {
        name,
        parent_id,
      };
      if (data.parent_id === null) {
        data.parent_id = -1;
      }

      console.log(data);

      axios
        .put(`department/${id}`, data)
        .then(() => {
          this.isPending = false;
          this.isSuccess = true;
          this.isFailure = false;

          this.close();

          this.$emit("store");
        })
        .catch((error) => {
          this.isPending = false;
          this.isSuccess = false;
          this.isFailure = true;

          this.$notify({
            group: "info",
            type: "error",
            text: error.response.data.status.message,
          });
        });
    },
  },

  watch: {
    data(data) {
      this.name = data?.name || "";
      this.parent = data?.parentId || null;
    },
  },

  computed: {
    departmentsOptions() {
      const departments = this.departments.filter(
        (department) => department.id !== this.data?.id
      );

      const options = [
        {
          id: null,
          name: this.$ml.get("departments.withoutParent"),
        },

        ...departments,
      ];

      return options;
    },

    isEdit() {
      if (this.data) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
