<template>
  <div class="mt-5">
    <v-row>
      <v-col cols="12" md="6">
        <v-switch
          v-model="cloudenable"
          :label="$ml.get('setting.sync.CLOUD')"
        />

        <template v-if="cloudenable">
          <template v-if="!syncLoginCloud">
            <div>
              <h5>
                {{ $ml.get("setting.connection") }}
              </h5>
              <v-text-field
                v-model.lazy="cloudlogin"
                :label="$ml.get('setting.sync.cloudLogin')"
                outlined
                autocomplete="off"
              />
              <v-text-field
                v-model.lazy="cloudpassw"
                :label="$ml.get('setting.sync.cloudPassw')"
                outlined
                autocomplete="off"
                type="password"
              />
              <v-text-field
                v-model.lazy="cloudphone"
                :label="$ml.get('setting.sync.cloudUserPhone')"
                outlined
                @input="handleUserInput"
                autocomplete="off"
                type="text"
              />
              <v-card-actions style="margin-left: -10px">
                <button @click="cloudLogin" class="btn btn-primary mr-2">
                  {{ $ml.get("button.next") }}
                </button>
              </v-card-actions>
            </div>
          </template>
          <template v-else-if="!syncEnabledCloud">
            <div>
              <h5>{{ $ml.get("setting.sync.enterSmsCode") }}</h5>
              <v-text-field
                v-model.lazy="phonecode"
                :label="$ml.get('setting.sync.smsCode')"
                outlined
                style="width: 110px; font-size: 20px"
                autocomplete="off"
                type="text"
              />
              <v-card-actions style="margin-left: -10px">
                <button @click="cloudConnect" class="btn btn-primary mr-2">
                  {{ $ml.get("button.next") }}
                </button>
              </v-card-actions>
            </div>
          </template>
          <template v-else-if="!selectCompanyCloud">
            <div>
              <div v-if="companies.length">
                <h5>
                  {{ $ml.get("setting.sync.selectCompany") }}
                </h5>
                <select
                  v-model.lazy="cloudComapnyId"
                  class="Sbrowser-default custom-select custom-select-lg mb-5"
                  :label="$ml.get('setting.sync.cloudUserPhone')"
                >
                  <option v-for="it in companies" :value="it.id" :key="it.id">
                    {{ it.name }}
                  </option>
                </select>
                <v-card-actions style="margin-left: -10px">
                  <button
                    @click="cloudCompanySelect"
                    class="btn btn-primary mr-2"
                  >
                    {{ $ml.get("button.connect") }}
                  </button>
                </v-card-actions>
              </div>
              <div v-if="!companies.length">
                <h5>{{ $ml.get("setting.sync.addCompany") }}</h5>
                <v-text-field
                  v-model.lazy="cloudComapnyName"
                  :label="$ml.get('setting.sync.companyName')"
                  outlined
                  autocomplete="off"
                  type="text"
                />
                <v-text-field
                  v-model.lazy="cloudComapnyInn"
                  :label="$ml.get('setting.sync.companyInn')"
                  outlined
                  autocomplete="off"
                  type="text"
                />
                <v-card-actions style="margin-left: -10px">
                  <button @click="cloudCompanyAdd" class="btn btn-primary mr-2">
                    {{ $ml.get("setting.sync.createAndConnect") }}
                  </button>
                </v-card-actions>
              </div>
            </div>
          </template>
          <v-card-actions v-else style="margin-left: -10px">
            <button @click="cloudDisconnect" class="btn btn-primary mr-2">
              {{ $ml.get("button.disconnect") }}
            </button>
          </v-card-actions>
        </template>
      </v-col>

      <v-col cols="12" md="6">
        <v-switch v-model="skudenable" :label="$ml.get('setting.sync.SKUD')" />

        <v-row v-if="skudenable">
          <v-col>
            <h5>{{ $ml.get("setting.connection") }}</h5>
            <div class="form-inline">
              <v-text-field
                :disabled="syncEnabled"
                v-model.lazy="syncServer"
                :label="$ml.get('setting.sync.syncServer')"
                outlined
                :rules="serverRules"
              />
              <v-text-field
                :disabled="syncEnabled"
                style="width: 80px; margin-left: 5px"
                v-model.lazy="syncPort"
                :label="$ml.get('setting.sync.syncPort')"
                outlined
                type="number"
                :rules="serverRules"
              />
            </div>
            <v-text-field
              :disabled="syncEnabled"
              v-model.lazy="syncKey"
              :label="$ml.get('setting.sync.syncToken')"
              outlined
              autocomplete="off"
              type="password"
            />
            <div class="form-inline">
              <select
                :disabled="syncEnabled || syncId > 0"
                class="Sbrowser-default custom-select custom-select-lg mb-5"
                v-model.lazy="syncName"
                style="width: 100%"
              >
                <option
                  v-for="imp in scudList"
                  :value="imp.value"
                  :key="imp.value"
                  :selected="imp.value == syncName"
                >
                  {{ imp.name }}
                </option>
              </select>
            </div>
          </v-col>
        </v-row>

        <v-row v-if="syncSettings.length && synsInit">
          <v-col>
            <h5>{{ $ml.get("setting.sync.remoteserver") }}</h5>
            <div v-for="sett in syncSettings" :key="sett">
              <v-text-field
                :disabled="syncEnabled"
                v-if="
                  sett.type == 'text' ||
                  sett.type == 'number' ||
                  sett.type == 'password'
                "
                v-model.lazy="sett.value"
                :label="sett.title"
                outlined
                :type="sett.type"
                :key="sett"
              />
              <select
                :disabled="syncEnabled"
                v-if="sett.type == 'select'"
                class="browser-default custom-select custom-select-lg mb-3"
                v-model.lazy="sett.value"
                :title="sett.title"
                :key="sett"
              >
                <option
                  v-for="it in sett.selectlist"
                  :value="it"
                  :key="it"
                  :selected="it == sett.value"
                >
                  {{ it }}
                </option>
              </select>
              <v-select
                v-if="sett.type == 'multiselect'"
                :key="sett.name"
                :disabled="syncEnabled"
                :items="sett.selectlist"
                v-model="sett.selected"
                outlined
                multiple
                chips
                :label="sett.title"
              />
              <v-switch
                :disabled="syncEnabled"
                v-if="sett.type == 'bool'"
                v-model.lazy="sett.checked"
                :label="sett.title"
                :key="sett"
              />
            </div>
          </v-col>
        </v-row>

        <v-row v-if="skudenable">
          <v-col>
            <v-card-actions>
              <v-spacer></v-spacer>
              <button
                v-if="syncId && !syncEnabled"
                @click="inegrationDel"
                class="btn btn-primary mr-2"
              >
                {{ $ml.get("button.clear") }}
              </button>
              <button
                v-if="syncName && syncServer && syncPort && !syncEnabled"
                @click="inegrationStore"
                class="btn btn-primary mr-2"
              >
                {{ $ml.get("button.apply") }}
              </button>

              <button
                v-if="syncId && !syncEnabled"
                @click="startSyncro"
                class="btn btn-primary mr-2"
              >
                {{ $ml.get("button.start") }}
              </button>

              <button
                v-if="syncId && syncEnabled"
                @click="stopSyncro"
                class="btn btn-primary"
              >
                {{ $ml.get("button.stop") }}
              </button>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row v-if="(syncId && syncEnabled) || log">
      <v-col>
        <textarea class="form-control" rows="15" v-model="log" readonly />
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  props: ["value"],
  data() {
    return {
      serverRules: [
        (v) => !!v || "Address is required",
        //v =>
        //  v.indexOf("://") != -1 || "Address must contain http:// or https://"
      ],
      requiredRules: [
        (v) => !!v || "Required",
        //v =>
        //  v.indexOf("://") != -1 || "Address must contain http:// or https://"
      ],
      connect: "CLOUD",
      connects: [
        { name: this.$ml.get("setting.sync.CLOUD"), val: "CLOUD" },
        { name: this.$ml.get("setting.sync.SKUD"), val: "SKUD" },
      ],
      scudList: [
        { name: "Apacs3000", value: "apacs" },
        { name: "Bolid", value: "bolid" },
        { name: "Itrium", value: "itrium" },
        { name: "RusGuard", value: "rusguard" },
        { name: "Parsec", value: "parsec" },
        { name: "Perco", value: "perco" },
        { name: "Sigur", value: "sigur" },
        { name: "Docont", value: "docont" },
        { name: "Codos", value: "codos" },
      ],
      companies: [],
      cloudlogin: "",
      cloudpassw: "",
      phonecode: "",
      cloudenable: false,
      skudenable: false,
      isEdit: false,
      syncInit: false,
      syncId: 0,
      syncEnabled: false,
      syncEnabledCloud: false,
      syncLoginCloud: false,
      selectCompanyCloud: false,
      cloudComapnyId: 0,
      syncName: "CLOUD",
      syncServer: "http://127.0.0.1",
      syncKey: "SPBOVISION",
      syncData: "",
      syncPort: 6002,
      company: [],
      IntegrationActive: {},
      servers: [],
      cloudphone: "",
      apidialog: false,
      editApiItem: {},
      log: "",
      fields: [
        {
          group: [
            { name: "name", style: "font-size: 16px; font-weight: bold;" },
          ],
          header: { text: this.$ml.get("setting.connection") },
        },
        {
          group: [
            { name: "server" },
            {
              name: "port",
              style: "font-size: 10px; float: left; margin-right: 3px;",
            },
          ],
          header: { text: this.$ml.get("setting.api_server") },
          style: "width: 150px; padding-left:10px;",
        },
        {
          type: this.$auth.user().type_user == "admin" ? "button" : "none",
          title: this.$ml.get("setting.configureConnect"),
          class: "btn btn-primary v-icon mdi mdi-cog",
          click: this.inegrationEdit,
          style: "width: 40px; margin-right: 2px;",
        },
        {
          type: "button",
          title: this.$ml.get("setting.syncroApi"),
          class: "btn btn-default v-icon mdi mdi-reload",
          click: this.apiSyncro,
          style: "width: 40px; margin-left: 2px;",
        },
      ],
      syncSettings: [],
    };
  },
  created() {
    let self = this;
    this.$root.$off("checkexternal");
    this.$root.$on("checkexternal", function (item) {
      self.checkexternal(item);
    });
    this.$root.$off("setlog");
    this.$root.$on("setlog", function (item) {
      self.setlog(item);
    });
    this.$root.$off("adduser");
    this.$root.$on("adduser", function (item) {
      self.adduser(item);
    });
    this.$root.$off("setuser");
    this.$root.$on("setuser", function (item) {
      self.setuser(item);
    });
    this.$root.$off("deluser");
    this.$root.$on("deluser", function (item) {
      self.deluser(item);
    });
  },
  methods: {
    handleUserInput(e) {
      var num = this.cloudphone.replace(/\D/g, "");
      if (num.length > 0 && num[0] == "8") num = "7" + num.substring(1);
      console.log(num);
      this.cloudphone = num;
      //this.cloudphone = !replacedInput[2] ? replacedInput[1] : replacedInput[1] + ') ' + replacedInput[2] + (replacedInput[3] ? '-' + replacedInput[3] : '');
    },

    checkexternal: function (item) {
      this.logLength(14);
      if (!item.success) this.log += item.message + "\n";
    },
    setlog: function (item) {
      this.logLength(14);
      let status = item.success ? "Success" : "Error";
      if (item.success) this.log += status + ": " + item.message + "\n";
      else this.log += "Error Add: " + item.message + "\n";
    },
    adduser: function (item) {
      this.logLength(14);
      if (item.success) this.log += "Success Add: " + item.message + "\n";
      else this.log += "Error Add: " + item.message + "\n";
    },
    setuser: function (item) {
      this.logLength(14);
      if (item.success) this.log += "Success Update: " + item.message + "\n";
      else this.log += "Error Update: " + item.message + "\n";
    },
    deluser: function (item) {
      this.logLength(14);
      if (item.success) this.log += "Success Delete: " + item.message + "\n";
      else this.log += "Error Delete: " + item.message + "\n";
    },
    logLength(l) {
      if (this.log) {
        let ll = this.log.split("\n");
        if (ll.length > l) {
          ll.splice(0, ll.length - l);
          this.log = ll.join("\n");
        }
      }
    },
    integratinList() {
      let values = {
        host: this.syncServer + ":" + this.syncPort,
      };
      axios
        .get(
          "/integration/list",
          { params: values },
          {
            timeout: 2000,
          }
        )
        .then((response) => {
          if (response.data.status.code == 0) {
            this.scudList = [];
            response.data.data.forEach((el) => {
              this.scudList.push({
                name: el.charAt(0).toUpperCase() + el.slice(1),
                value: el,
              });
            });
          }
        });
    },
    inegrationDel() {
      // Удаляем интеграцию
      this.log = "";
      this.synsInit = false;
      let url = "/integration/" + this.syncId;
      let _this = this;
      this.$dialog
        .confirm(
          `<h3>${this.$ml.get("message.Delete")}</h3>` +
            this.$ml.get("message.confirmDelete"),
          {
            loader: false,
            okText: this.$ml.get("button.delete"),
            cancelText: this.$ml.get("button.cancel"),
            backdropClose: true,
          }
        )
        .then(function () {
          axios.delete(url).then((response) => {
            _this.$notify({
              group: "info",
              type: "success",
              text: _this.$ml.get("message.deleteSuccess"),
            });
            _this.apidialog = false;
            _this.syncId = 0;
            this.syncSettings = [];
            _this.getInegration();
          });
        });
    },
    cloudLogin() {
      let values = {
        clientid: this.cloudlogin,
        secret: this.cloudpassw,
        number: this.cloudphone,
      };
      axios
        .post("/cloud/login", values, {
          header: {
            "Content-Type": "application/json",
          },
          timeout: 20000,
        })
        .then((response) => {
          this.syncLoginCloud = true;
          this.$notify({
            group: "info",
            type: "success",
            text: this.$ml.get("message.saveSuccess"),
          });
        })
        .catch((error) => {
          this.$notify({
            group: "info",
            type: "error",
            text: this.$ml.get("message.notServerConnect"),
          });
        });
    },
    cloudConnect() {
      let values = {
        smsCode: this.phonecode,
      };
      axios
        .post("/cloud/connect", values, {
          header: {
            "Content-Type": "application/json",
          },
          timeout: 20000,
        })
        .then((response) => {
          this.syncEnabledCloud = true;
          this.$notify({
            group: "info",
            type: "success",
            text: this.$ml.get("message.saveSuccess"),
          });
          this.cloudCompanies();
          this.selectCompanyCloud = false;
        })
        .catch((error) => {
          this.$notify({
            group: "info",
            type: "error",
            text: this.$ml.get("message.notServerConnect"),
          });
        });
    },
    cloudDisconnect() {
      let values = {
        username: this.cloudlogin,
        password: this.cloudpassw,
      };
      axios
        .put("/cloud/disconnect", values, {
          header: {
            "Content-Type": "application/json",
          },
          timeout: 20000,
        })
        .then((response) => {
          this.syncEnabledCloud = false;
          this.cloudenable = false;
          this.syncLoginCloud = false;
          this.syncLoginCloud = false;

          this.$notify({
            group: "info",
            type: "success",
            text: this.$ml.get("message.saveSuccess"),
          });
        })
        .catch((error) => {
          this.$notify({
            group: "info",
            type: "error",
            text: this.$ml.get("message.notServerConnect"),
          });
        });
    },
    cloudStatus() {
      axios.get("/cloud/status").then((response) => {
        if (response.status == 200) {
          this.cloudenable = response.data.data.auth;
          this.syncEnabledCloud = response.data.data.connect;
          this.syncLoginCloud = response.data.data.connect;
          // this.cloudCompanies();
          this.selectCompanyCloud = response.data.data.connect;
        }
      });
    },
    cloudCompanySelect() {
      let values = {
        company_id: "" + this.cloudComapnyId + "",
      };
      axios
        .put("/cloud/company/select", values, {
          header: {
            "Content-Type": "application/json",
          },
          timeout: 20000,
        })
        .then((response) => {
          this.syncEnabledCloud = true;
          this.cloudenable = true;
          this.syncLoginCloud = true;
          this.selectCompanyCloud = true;
          this.$notify({
            group: "info",
            type: "success",
            text: this.$ml.get("message.saveSuccess"),
          });
        })
        .catch((error) => {
          this.$notify({
            group: "info",
            type: "error",
            text: this.$ml.get("message.notServerConnect"),
          });
        });
    },
    cloudCompanyAdd() {
      let values = {
        name: this.cloudComapnyName,
        inn: this.cloudComapnyInn,
      };
      axios
        .post("/cloud/company/add", values, {
          header: {
            "Content-Type": "application/json",
          },
          timeout: 20000,
        })
        .then((response) => {
          if (response.data.status.code == 0) {
            this.syncEnabledCloud = true;
            this.cloudenable = true;
            this.syncLoginCloud = true;
            this.selectCompanyCloud = true;
            this.$notify({
              group: "info",
              type: "success",
              text: this.$ml.get("message.saveSuccess"),
            });
          } else {
            this.$notify({
              group: "info",
              type: "error",
              text: response.data.status.message,
            });
          }
        })
        .catch((error) => {
          this.$notify({
            group: "info",
            type: "error",
            text: this.$ml.get("message.notServerConnect"),
          });
        });
    },
    cloudCompanies() {
      axios.get("/cloud/companies").then((response) => {
        if (response.data.status.code == 0) {
          this.companies = response.data.data;
          if (this.companies.length) {
            this.cloudComapnyId = this.companies[0].id;
          }
        } else {
          this.$notify({
            group: "info",
            type: "error",
            text: response.data.status.message,
          });
        }
      });
    },
    inegrationStore() {
      // Сохраняем интеграцию
      let url = "/integration/add";
      let data = JSON.stringify(this.syncSettings);

      let _this = this;
      let values = {
        name: this.syncName,
        server: this.syncServer,
        port: parseInt(this.syncPort),
        key: this.syncKey,
        enable: this.syncEnabled,
        data: data,
      };
      if (this.syncId) {
        values.id = this.syncId;
        url = "/integration/" + this.syncId;
      }
      axios
        .put(url, values, {
          header: {
            "Content-Type": "application/json",
          },
          timeout: 20000,
        })
        .then((response) => {
          this.$notify({
            group: "info",
            type: "success",
            text: this.$ml.get("message.saveSuccess"),
          });
          this.syncId = 0;
          this.apidialog = false;
          this.getInegration();
        })
        .catch((error) => {
          this.$notify({
            group: "info",
            type: "error",
            text: this.$ml.get("message.notServerConnect"),
          });
        });
    },
    startSyncro() {
      // Запускаем интеграцию
      let url = "/integration/" + this.syncId + "/start";
      axios.get(url).then((response) => {
        if (response.status == 200) {
          this.getInegration();
          this.$notify({
            group: "info",
            type: "success",
            text: this.$ml.get("setting.sync.start"),
          });
          this.log = "";
        }
      });
    },
    stopSyncro() {
      // Запускаем интеграцию
      let url = "/integration/" + this.syncId + "/stop";
      axios.get(url).then((response) => {
        if (response.status == 200) {
          this.getInegration();
          this.$notify({
            group: "info",
            type: "success",
            text: this.$ml.get("setting.sync.stop"),
          });
        }
      });
    },

    styleRow() {},
    getInegration() {
      this.syncId = 0;
      axios.get("/integrations").then((response) => {
        if (response.status == 200) {
          this.servers = response.data.data;
          if (this.servers !== null && this.servers.length > 0) {
            //console.log(this.servers[0].id);
            let item = this.servers[0];
            this.syncId = item.id;
            this.syncName = item.name;
            if (this.syncName) {
              this.skudenable = true;
            } else {
              this.skudenable = false;
            }
            this.syncKey = item.key;
            this.syncServer = item.server;
            this.syncPort = item.port;
            this.syncData = item.data;
            this.syncEnabled = item.enable;
            this.synsInit = true;
            //item.data = item.data || [];
            if (item.data !== "null" && item.data !== "") {
              this.syncSettings = JSON.parse(item.data);
              console.log(this.syncSettings);
            }
          }
        }
      });
    },
  },
  mounted() {
    this.getInegration();
    this.cloudStatus();
    if (this.skudenable) {
      this.integratinList();
    }
  },
  watch: {
    skudenable(val) {
      if (val) {
        this.syncKey = "SPBOVISION";
        this.integratinList();
      }
    },
  },
};
</script>
<style scoped>
.top-button-box {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  flex-flow: row-reverse;
}

.btn.add {
  color: #ffffff;
}
</style>
