<template>
  <div class="modal-background" style="display: block" @click.self="close">
    <div
      class="take-photo"
      :style="{ minHeight: isStreamLoaded ? '90vh' : 'auto' }"
    >
      <a href="#" class="modal-close" @click="close" />

      <div class="camera-box">
        <div
          v-if="selectedCamera && selectedCamera.device_type === ''"
          class="camera-box__stream"
        >
          <v-progress-circular
            v-if="isLoadingStream"
            :size="100"
            color="primary"
            indeterminate
          />
          <div
            v-else
            :style="{
              backgroundImage: `url(http://${
                this.selectedCamera.ip_address
              }:7777/mjpeg?ver=${this.$moment().unix()})`,
            }"
            class="camera-box__stream-image"
          />
        </div>

        <div v-else class="camera-box__message">
          <v-alert v-show="!isAwaitingPhoto" type="info">
            {{ $ml.get("imageBox.chooseDeviceMessage") }}
          </v-alert>

          <v-alert v-show="isAwaitingPhoto" type="info">
            {{ $ml.get("imageBox.goToDeviceMessage") }}
          </v-alert>

          <v-alert v-show="isFailure" type="error">{{
            $ml.get("imageBox.noactive")
          }}</v-alert>
        </div>

        <div class="camera-box__controls">
          <v-row align="center">
            <v-col cols="6">
              <v-select
                v-model="selectedCamera"
                :items="cameraList"
                item-text="name"
                item-value="id"
                :label="$ml.get('imageBox.camera_for_reg')"
                return-object
                outlined
                hide-details
                :disabled="isAwaitingPhoto"
                @change="onSelectedCamera"
              />
            </v-col>
            <v-col cols="6">
              <v-btn
                block
                large
                color="primary"
                @click="takePhoto"
                :disabled="!selectedCamera || isAwaitingPhoto"
              >
                {{ $ml.get("button.makePhoto") }}
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getImage } from "@/plugins/ImageMjpeg.js";

export default {
  props: {
    devices: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      selectedCamera: null,

      isAwaitingPhoto: false,
      isFailure: false,

      isLoadingStream: false,
      isStreamLoaded: false,
    };
  },

  methods: {
    close() {
      this.$emit("toggle", false);
    },

    takePhoto() {
      this.isAwaitingPhoto = true;
      this.isFailure = false;

      axios.put(`device/${this.selectedCamera.id}/takePhoto`).catch(() => {
        this.isAwaitingPhoto = false;
        this.isFailure = true;
      });
    },

    onGetPhoto(item) {
      this.isAwaitingPhoto = false;

      this.$emit("image", `data:image/jpeg;base64,${item.image}`);

      this.close();
    },

    async onSelectedCamera() {
      this.isLoadingStream = true;
      this.isStreamLoaded = false;

      if (this.selectedCamera.device_type === "") {
        await getImage(
          `http://${
            this.selectedCamera.ip_address
          }:7777/mjpeg?ver=${this.$moment().unix()}`
        );

        this.isLoadingStream = false;
        this.isStreamLoaded = true;
      }
    },

    recalcTimestamp() {
      setInterval(() => {
        this.timestamp = this.$moment().unix();
      }, 5000);
    },
  },

  computed: {
    cameraList() {
      return this.devices.data.filter((device) => device.purpose != "off");
    },
  },

  created() {
    this.$root.$on("takephoto", (item) => {
      this.onGetPhoto(item);
    });
  },

  destroyed() {
    this.$root.$off("takephoto");
  },
};
</script>

<style lang="scss">
.camera-box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px;

  &__message {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__stream {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__stream-image {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  &__controls {
    margin-top: 15px;
    height: 80px;
    flex: none;
    display: flex;
    align-items: center;
  }
}
</style>
