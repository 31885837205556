<template>
  <v-row>
    <v-col v-show="!isEdit">
      <v-text-field
        v-model="pass"
        :label="`${$ml.get('devices.pass')} *`"
        type="password"
        outlined
        required
        :rules="[(v) => !!v || $ml.get('message.NameIsRequired')]"
        @change="inputValue"
      />
    </v-col>

    <template v-if="isEdit">
      <v-col>
        <v-tabs v-model="settingTab" background-color="grey lighten-3 mb-5">
          <v-tab>{{ $ml.get("devices.mainParameters") }}</v-tab>
          <v-tab>{{ $ml.get("devices.recognitionParameters") }}</v-tab>
          <v-tab>{{ $ml.get("devices.recognitionSettings") }}</v-tab>
          <v-tab>{{ $ml.get("devices.other") }}</v-tab>
        </v-tabs>

        <v-tabs-items v-model="settingTab">
          <!-- Main -->
          <v-tab-item>
            <v-row>
              <v-col cols="12" md="6">
                <v-subheader>{{
                  $ml.get("devices.saveIdentifyTime")
                }}</v-subheader>
                <v-slider
                  v-model="saveIdentifyTime"
                  class="align-center"
                  max="86400"
                  min="0"
                  hide-details
                  @change="inputValue"
                >
                  <template v-slot:append>
                    <v-text-field
                      v-model="saveIdentifyTime"
                      hide-details
                      max="86400"
                      min="0"
                      step="1"
                      type="number"
                      style="width: 100px"
                      outlined
                      @change="inputValue"
                    />
                  </template>
                </v-slider>
              </v-col>

              <v-col cols="12" md="6">
                <v-subheader>{{
                  $ml.get("devices.delayTimeForCloseDoor")
                }}</v-subheader>
                <v-slider
                  v-model="delayTimeForCloseDoor"
                  class="align-center"
                  max="25500"
                  min="100"
                  hide-details
                  @change="inputValue"
                >
                  <template v-slot:append>
                    <v-text-field
                      v-model="delayTimeForCloseDoor"
                      hide-details
                      max="25500"
                      min="100"
                      step="1"
                      type="number"
                      style="width: 100px"
                      outlined
                      @change="inputValue"
                    />
                  </template>
                </v-slider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                <v-select
                  v-model="identifyDistance"
                  :items="identifyDistanceOptions"
                  :label="$ml.get('devices.identifyDistance')"
                  outlined
                  hide-details
                  @change="inputValue"
                />
              </v-col>

              <v-col cols="12" md="6">
                <v-select
                  v-model="recDisplayImageMode"
                  :items="recDisplayImageModeOptions"
                  :label="$ml.get('devices.recDisplayImageMode')"
                  outlined
                  hide-details
                  @change="inputValue"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                <v-switch
                  v-model="recRank"
                  :label="$ml.get('devices.recRank')"
                  @change="inputValue"
                />
              </v-col>

              <v-col cols="12" md="6">
                <v-switch
                  v-model="touchTone"
                  :label="$ml.get('devices.touchTone')"
                  @change="inputValue"
                />
              </v-col>
            </v-row>

            <!--h4 class="mt-5">{{ $ml.get("devices.savePhoto") }}</h4>
            <v-row>
              <v-col cols="12" md="4">
                <v-switch
                  v-model="recSucSaveSpotImage"
                  :label="$ml.get('devices.recSucSaveSpotImage')"
                  @change="inputValue"
                />
              </v-col>

              <v-col cols="12" md="4">
                <v-switch
                  v-model="recFailSaveSpotImage"
                  :label="$ml.get('devices.recFailSaveSpotImage')"
                  @change="inputValue"
                />
              </v-col>

              <v-col cols="12" md="4">
                <v-switch
                  v-model="recNoPerSaveSpotImage"
                  :label="$ml.get('devices.recNoPerSaveSpotImage')"
                  @change="inputValue"
                />
              </v-col>
            </v-row-->
          </v-tab-item>

          <!-- Recognition -->
          <v-tab-item>
            <v-row>
              <v-col>
                <v-switch
                  v-model="isRecognitionOpen"
                  :label="
                    isRecognitionOpen
                      ? $ml.get('devices.on')
                      : $ml.get('devices.off')
                  "
                  @change="inputValue"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <v-switch
                  v-model="recModeFaceEnable"
                  :label="$ml.get('devices.recModeFaceEnable')"
                  @change="inputValue"
                />
              </v-col>

              <v-col cols="12" md="4">
                <v-switch
                  v-model="recModeCardEnable"
                  :label="$ml.get('devices.recModeCardEnable')"
                  @change="inputValue"
                />
              </v-col>

              <v-col cols="12" md="4">
                <v-switch
                  v-model="recModeCardFaceEnable"
                  :label="$ml.get('devices.recModeCardFaceEnable')"
                  @change="inputValue"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="4">
                <v-select
                  v-model="multiplayerDetection"
                  :items="multiplayerDetectionOptions"
                  :label="$ml.get('devices.multiplayerDetection')"
                  outlined
                  @change="inputValue"
                />
              </v-col>

              <v-col cols="12" md="4">
                <v-select
                  v-model="recModeQREnable"
                  :items="recModeQREnableOptions"
                  :label="$ml.get('devices.recModeQREnable')"
                  outlined
                  @change="inputValue"
                />
              </v-col>

              <v-col cols="12" md="4">
                <v-select
                  v-model="recModeQRCodeComparison"
                  :items="recModeQRCodeComparisonOptions"
                  :label="$ml.get('devices.recModeQRCodeComparison')"
                  outlined
                  @change="inputValue"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-slider
                  v-model="identifyScores"
                  class="align-center"
                  max="100"
                  min="50"
                  :label="$ml.get('devices.identifyScores')"
                  hide-details
                  @change="inputValue"
                >
                  <template v-slot:append>
                    <v-text-field
                      v-model="identifyScores"
                      hide-details
                      max="100"
                      min="50"
                      step="1"
                      type="number"
                      style="width: 100px"
                      outlined
                      @change="inputValue"
                    />
                  </template>
                </v-slider>
              </v-col>
            </v-row>
          </v-tab-item>

          <!-- Settings -->
          <v-tab-item>
            <v-row>
              <v-col cols="12" md="6">
                <h4 class="mb-5">{{ $ml.get("devices.onSucRec") }}</h4>
                <v-select
                  v-model="voiceType"
                  :items="voiceTypeOptions"
                  :label="$ml.get('devices.voiceType')"
                  outlined
                  @change="inputValue"
                />

                <v-select
                  v-model="displayModType"
                  :items="displayModTypeOptions"
                  :label="$ml.get('devices.displayModType')"
                  outlined
                  @change="inputValue"
                />

                <v-text-field
                  v-show="displayModType === 100"
                  v-model="displayModContent"
                  :label="$ml.get('devices.displayModContent')"
                  outlined
                  @change="inputValue"
                />

                <v-select
                  v-model="recSucDisplayText2Type"
                  :items="recSucDisplayText2TypeOptions"
                  :label="$ml.get('devices.recSucDisplayText2Type')"
                  outlined
                  @change="inputValue"
                />

                <v-text-field
                  v-show="recSucDisplayText2Type === 100"
                  v-model="recSucDisplayText2Content"
                  :label="$ml.get('devices.recSucDisplayText2Content')"
                  outlined
                  @change="inputValue"
                />

                <v-select
                  v-model="comModType"
                  :items="comModTypeOptions"
                  :label="$ml.get('devices.comModType')"
                  outlined
                  @change="inputValue"
                />

                <v-select
                  v-model="recSucWiegandType"
                  :items="recWiegandTypeOptions"
                  :label="$ml.get('devices.recSucWiegandType')"
                  outlined
                  @change="inputValue"
                />

                <v-text-field
                  v-show="recSucWiegandType !== 1"
                  v-model="recSucWiegandContent"
                  :label="$ml.get('devices.recSucWiegandContent')"
                  :hint="$ml.get('devices.recSucWiegandContentHint')"
                  outlined
                  @change="inputValue"
                />

                <v-select
                  v-model="isOpenRelay"
                  :items="relayOptions"
                  :label="$ml.get('devices.isOpenRelay')"
                  outlined
                  @change="inputValue"
                />
              </v-col>

              <v-col cols="12" md="6">
                <h4 class="mb-5">{{ $ml.get("devices.onFailRec") }}</h4>
                <!--v-switch
                  v-model="recStrangerType"
                  :label="$ml.get('devices.recStrangerType')"
                  @change="inputValue"
                /-->

                <v-select
                  v-model="relaySwitch"
                  :items="relayOptions"
                  :label="$ml.get('devices.relaySwitch')"
                  outlined
                  @change="inputValue"
                />

                <v-subheader>{{
                  $ml.get("devices.recStrangerTimesThreshold")
                }}</v-subheader>
                <v-slider
                  v-model="recStrangerTimesThreshold"
                  class="align-center"
                  max="20"
                  min="1"
                  hide-details
                  @change="inputValue"
                >
                  <template v-slot:append>
                    <v-text-field
                      v-model="recStrangerTimesThreshold"
                      hide-details
                      max="20"
                      min="1"
                      step="1"
                      type="number"
                      style="width: 100px"
                      outlined
                      @change="inputValue"
                    />
                  </template>
                </v-slider>
              </v-col>
            </v-row>
          </v-tab-item>

          <!-- Other -->
          <v-tab-item>
            <v-row>
              <v-col>
                <v-switch
                  v-model="showIp"
                  :label="$ml.get('devices.showIp')"
                  @change="inputValue"
                />

                <!-- TODO: вернуть когда будет необходимость -->
                <!-- <v-switch
                  v-model="whitelist"
                  :label="$ml.get('devices.whitelist')"
                  @change="inputValue"
                /> -->

                <v-text-field
                  v-model="companyName"
                  :label="$ml.get('devices.companyName')"
                  outlined
                  @change="inputValue"
                />

                <v-select
                  v-model="wgInputType"
                  :items="wgInputTypeOptions"
                  :label="$ml.get('devices.wgInputType')"
                  outlined
                  @change="inputValue"
                />

                <v-select
                  v-model="serialOutMode"
                  :items="serialOutModeOptions"
                  :label="$ml.get('devices.serialOutMode')"
                  outlined
                  @change="inputValue"
                />

                <v-switch
                  v-model="showDeviceKey"
                  :label="$ml.get('devices.showDeviceKey')"
                  @change="inputValue"
                />

                <v-switch
                  v-model="showPeopleNum"
                  :label="$ml.get('devices.showPeopleNum')"
                  @change="inputValue"
                />

                <v-text-field
                  v-model="applicationName"
                  :label="$ml.get('devices.applicationName')"
                  outlined
                  @change="inputValue"
                />

                <v-select
                  v-model="recModeCardIntf"
                  :items="recModeCardIntfOptions"
                  :label="$ml.get('devices.recModeCardIntf')"
                  outlined
                  @change="inputValue"
                />

                <v-select
                  v-model="recModeCardFaceIntf"
                  :items="recModeCardIntfOptions"
                  :label="$ml.get('devices.recModeCardFaceIntf')"
                  outlined
                  @change="inputValue"
                />

                <v-select
                  v-model="recModeIdcardFaceIntf"
                  :items="recModeCardIntfOptions"
                  :label="$ml.get('devices.recModeIdcardFaceIntf')"
                  outlined
                  @change="inputValue"
                />

                <v-switch
                  v-model="isIDCardPositive"
                  :label="$ml.get('devices.isIDCardPositive')"
                  @change="inputValue"
                />

                <v-slider
                  v-model="recCardFaceValue"
                  max="100"
                  min="0"
                  :label="$ml.get('devices.recCardFaceValue')"
                  thumb-label="always"
                  @change="inputValue"
                />

                <v-select
                  v-model="recNoPerRelayType"
                  :items="relayOptions"
                  :label="$ml.get('devices.recNoPerRelayType')"
                  outlined
                  @change="inputValue"
                />

                <v-select
                  v-model="recFailWiegandType"
                  :items="recWiegandTypeOptions"
                  :label="$ml.get('devices.recFailWiegandType')"
                  outlined
                  @change="inputValue"
                />

                <!-- TODO: вернуть когда будет необходимость -->
                <!-- <v-select
                  v-model="wgInputContentType"
                  :items="wgInputContentTypeOptions"
                  :label="$ml.get('devices.wgInputContentType')"
                  outlined
                  @change="inputValue"
                /> -->

                <v-select
                  v-model="recModeCardHardware"
                  :items="recModeCardHardwareOptions"
                  :label="$ml.get('devices.recModeCardHardware')"
                  outlined
                  @change="inputValue"
                />

                <v-select
                  v-model="recModeCardFaceHardware"
                  :items="recModeCardHardwareOptions"
                  :label="$ml.get('devices.recModeCardFaceHardware')"
                  outlined
                  @change="inputValue"
                />

                <v-select
                  v-model="recNoPerWiegandType"
                  :items="recWiegandTypeOptions"
                  :label="$ml.get('devices.recNoPerWiegandType')"
                  outlined
                  @change="inputValue"
                />

                <v-select
                  v-model="scrDisplayText1Type"
                  :items="scrDisplayText1TypeOptions"
                  :label="$ml.get('devices.scrDisplayText1Type')"
                  outlined
                  @change="inputValue"
                />

                <v-select
                  v-model="scrDisplayText2Type"
                  :items="scrDisplayText2TypeOptions"
                  :label="$ml.get('devices.scrDisplayText2Type')"
                  outlined
                  @change="inputValue"
                />

                <v-select
                  v-model="displayModStrangerType"
                  :items="displayModStrangerTypeOptions"
                  :label="$ml.get('devices.displayModStrangerType')"
                  outlined
                  @change="inputValue"
                />

                <v-switch
                  v-model="recModeCardFaceHardware"
                  :label="$ml.get('devices.recModeCardFaceHardware')"
                  @change="inputValue"
                />

                <v-switch
                  v-model="recModeCardPhoto"
                  :label="$ml.get('devices.recModeCardPhoto')"
                  @change="inputValue"
                />

                <v-switch
                  v-model="uniquenessRegImage"
                  :label="$ml.get('devices.uniquenessRegImage')"
                  @change="inputValue"
                />
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </template>
  </v-row>
</template>

<script>
export default {
  props: {
    value: Object,
    isEdit: {
      type: Boolean,
      reqired: true,
    },
  },

  data() {
    return {
      settingTab: null,

      pass: this.value?.pass || "",

      // Main
      saveIdentifyTime: this.value?.config.saveIdentifyTime || 0,
      recRank: this.value?.config.recRank === 1 ? false : true,
      identifyDistance: this.value?.config.identifyDistance || 2,
      delayTimeForCloseDoor: this.value?.config.delayTimeForCloseDoor || 500,
      touchTone: this.formatToBoolean(this.value?.config.touchTone) || true,
      recDisplayImageMode: this.value?.config.recDisplayImageMode || 1,
      // recFailSaveSpotImage: this.formatToBoolean(
      //   this.value?.config.recFailSaveSpotImage
      // ),
      // recNoPerSaveSpotImage: this.formatToBoolean(
      //   this.value?.config.recNoPerSaveSpotImage
      // ),
      // recSucSaveSpotImage: this.formatToBoolean(
      //   this.value?.config.recSucSaveSpotImage
      // ),

      // Recognition
      recModeFaceEnable: this.formatToBoolean(
        this.value?.config.recModeFaceEnable
      ),
      identifyScores: this.value?.config.identifyScores || 80,
      multiplayerDetection: this.value?.config.multiplayerDetection || 1,
      recModeCardEnable: this.formatToBoolean(
        this.value?.config.recModeCardEnable
      ),
      recModeCardFaceEnable: this.formatToBoolean(
        this.value?.config.recModeCardFaceEnable
      ),
      recModeQREnable: this.value?.config.recModeQREnable || 1,
      recModeQRCodeComparison: this.value?.config.recModeQRCodeComparison || 1,
      isRecognitionOpen: this.formatToBoolean(
        this.value?.config.isRecognitionOpen
      ),

      // Settings
      voiceType: this.value?.config.voiceType || 1,
      displayModType: this.value?.config.displayModType || 1,
      displayModContent: this.value?.config.displayModContent || "",
      recSucDisplayText2Type: this.value?.config.recSucDisplayText2Type || 1,
      recSucDisplayText2Content:
        this.value?.config.recSucDisplayText2Content || "",
      isOpenRelay: this.value?.config.isOpenRelay || 1,
      recSucWiegandType: this.value?.config.recSucWiegandType || 1,
      recSucWiegandContent:
        this.value?.config.recSucWiegandContent || "{idcardNum}",
      comModType: this.value?.config.comModType || 1,
      //recStrangerType: this.formatToBoolean(this.value?.config.recStrangerType),
      recStrangerTimesThreshold:
        this.value?.config.recStrangerTimesThreshold || 3,
      relaySwitch: this.value.config.relaySwitch || 2,

      // Other
      showIp: this.formatToBoolean(this.value?.config.showIp),
      whitelist: this.formatToBoolean(this.value?.config.whitelist),
      companyName: this.value?.config.companyName || "",
      wgInputType: this.value?.config.wgInputType || 1,
      serialOutMode: this.value?.config.serialOutMode || 1,
      showDeviceKey: this.formatToBoolean(this.value?.config.showDeviceKey),
      showPeopleNum: this.formatToBoolean(this.value?.config.showPeopleNum),
      applicationName: this.value?.config.applicationName || "",
      recModeCardIntf: this.value?.config.recModeCardIntf || 1,
      recModeCardFaceIntf: this.value?.config.recModeCardFaceIntf || 1,
      recModeIdcardFaceIntf: this.value?.config.recModeIdcardFaceIntf || 1,
      isIDCardPositive: this.formatToBoolean(
        this.value?.config.isIDCardPositive
      ),
      recCardFaceValue: this.value?.config.recCardFaceValue || 50,
      recModeCardPhoto: this.formatToBoolean(
        this.value?.config.recModeCardPhoto
      ),
      recNoPerRelayType: this.value?.config.recNoPerRelayType || 2,
      recFailWiegandType: this.value?.config.recFailWiegandType || 1,
      uniquenessRegImage: this.formatToBoolean(
        this.value?.config.uniquenessRegImage
      ),
      wgInputContentType: this.value?.config.wgInputContentType || 1,
      recModeCardHardware: this.value?.config.recModeCardHardware || 1,
      recModeCardFaceHardware: this.value?.config.recModeCardFaceHardware || 1,
      recNoPerWiegandType: this.value?.config.recNoPerWiegandType || 1,
      scrDisplayText1Type: this.value?.config.scrDisplayText1Type || 1,
      scrDisplayText2Type: this.value?.config.scrDisplayText2Type || 2,
      displayModStrangerType: this.value?.config.displayModStrangerType || 1,
      recModeIdcardFaceEnable: this.formatToBoolean(
        this.value?.config.recModeIdcardFaceEnable
      ),

      // Options
      // Main
      identifyDistanceOptions: [
        {
          text: this.$ml.get("devices.noLimit"),
          value: 0,
        },
        {
          text: this.$ml.get("devices.within05m"),
          value: 1,
        },
        {
          text: this.$ml.get("devices.within1m"),
          value: 2,
        },
        {
          text: this.$ml.get("devices.within15m"),
          value: 3,
        },
        {
          text: this.$ml.get("devices.within2m"),
          value: 4,
        },
        {
          text: this.$ml.get("devices.within3m"),
          value: 5,
        },
        {
          text: this.$ml.get("devices.within4m"),
          value: 6,
        },
      ],
      recDisplayImageModeOptions: [
        {
          text: this.$ml.get("devices.displayTakePhoto"),
          value: 1,
        },
        {
          text: this.$ml.get("devices.displaySavePhoto"),
          value: 2,
        },
        {
          text: this.$ml.get("devices.notDisplayPhoto"),
          value: 3,
        },
      ],

      // Recognition
      multiplayerDetectionOptions: [
        {
          text: this.$ml.get("devices.multiPeople"),
          value: 1,
        },
        {
          text: this.$ml.get("devices.singlePerson"),
          value: 2,
        },
      ],
      recModeQREnableOptions: [
        {
          text: this.$ml.get("devices.off"),
          value: 1,
        },
        {
          text: this.$ml.get("devices.on"),
          value: 2,
        },
        {
          text: this.$ml.get("devices.faceWithQR"),
          value: 3,
        },
      ],
      recModeQRCodeComparisonOptions: [
        {
          text: this.$ml.get("devices.compareThirdPatyPlatform"),
          value: 1,
        },
        {
          text: this.$ml.get("devices.compareDatabase"),
          value: 2,
        },
      ],

      // Settings
      voiceTypeOptions: [
        {
          text: this.$ml.get("devices.maleVoice"),
          value: 1,
        },
        {
          text: this.$ml.get("devices.femaleVoice"),
          value: 2,
        },
      ],
      displayModTypeOptions: [
        {
          text: this.$ml.get("devices.name"),
          value: 1,
        },
        {
          text: this.$ml.get("devices.notDisplayContent"),
          value: 2,
        },
        {
          text: this.$ml.get("devices.custom"),
          value: 100,
        },
      ],
      recSucDisplayText2TypeOptions: [
        {
          text: this.$ml.get("devices.recognitionSuccess"),
          value: 1,
        },
        {
          text: this.$ml.get("devices.custom"),
          value: 100,
        },
      ],
      recWiegandTypeOptions: [
        {
          text: this.$ml.get("devices.notOutput"),
          value: 1,
        },
        {
          text: "Wiegand 26",
          value: 2,
        },
        {
          text: "Wiegand 34",
          value: 3,
        },
        {
          text: "Wiegand 50",
          value: 4,
        },
        {
          text: "Wiegand 66",
          value: 5,
        },
      ],
      comModTypeOptions: [
        {
          text: this.$ml.get("devices.openTheDoor"),
          value: 1,
        },
        {
          text: this.$ml.get("devices.notOutput"),
          value: 2,
        },
        {
          text: this.$ml.get("devices.outputPersonId"),
          value: 3,
        },
        {
          text: this.$ml.get("devices.outputIdCardNum"),
          value: 4,
        },
        {
          text: this.$ml.get("devices.outputPhone"),
          value: 5,
        },
      ],

      // Other
      relayOptions: [
        {
          text: this.$ml.get("devices.output"),
          value: 1,
        },
        {
          text: this.$ml.get("devices.notOutput"),
          value: 2,
        },
      ],
      wgInputTypeOptions: [
        {
          text: "wg26 format 1",
          value: 1,
        },
        {
          text: "wg26 format 2",
          value: 2,
        },
        {
          text: "wg34",
          value: 3,
        },
        {
          text: "wg50",
          value: 4,
        },
        {
          text: "wg66",
          value: 5,
        },
      ],
      serialOutModeOptions: [
        {
          text: "Open the door",
          value: 1,
        },
        {
          text: "Custom",
          value: 2,
        },
        {
          text: "Close",
          value: 3,
        },
      ],
      recModeCardIntfOptions: [
        {
          text: this.$ml.get("devices.ttlSerialPort"),
          value: 1,
        },
        {
          text: this.$ml.get("devices.serialPort232"),
          value: 2,
        },
        {
          text: this.$ml.get("devices.usbSerialPort"),
          value: 3,
        },
        {
          text: this.$ml.get("devices.i2cSerialPort"),
          value: 4,
        },
      ],
      wgInputContentTypeOptions: [
        {
          text: this.$ml.get("devices.cardNumber"),
          value: 1,
        },
      ],
      recModeCardHardwareOptions: [
        {
          text: "IC",
          value: 1,
        },
        {
          text: "SYNJONES",
          value: 2,
        },
        {
          text: "ROUTON",
          value: 3,
        },
        {
          text: "ZKT",
          value: 4,
        },
      ],
      scrDisplayText1TypeOptions: [
        {
          text: this.$ml.get("devices.notDisplayContent"),
          value: 1,
        },
        {
          text: this.$ml.get("devices.displayAppName"),
          value: 2,
        },
      ],
      scrDisplayText2TypeOptions: [
        {
          text: this.$ml.get("devices.notDisplayContent"),
          value: 1,
        },
        {
          text: this.$ml.get("devices.displayCompanyName"),
          value: 2,
        },
      ],
      displayModStrangerTypeOptions: [
        {
          text: this.$ml.get("devices.recognitionFailed"),
          value: 1,
        },
      ],
    };
  },

  methods: {
    inputValue() {
      const {
        pass,
        // Main
        saveIdentifyTime,
        recRank,
        identifyDistance,
        delayTimeForCloseDoor,
        recDisplayImageMode,
        //recFailSaveSpotImage,
        //recSucSaveSpotImage,
        //recNoPerSaveSpotImage,

        // Recognition
        recModeFaceEnable,
        identifyScores,
        multiplayerDetection,
        recModeCardEnable,
        recModeCardFaceEnable,
        recModeQRCodeComparison,
        isRecognitionOpen,

        // Settings
        voiceType,
        displayModType,
        displayModContent,
        recSucDisplayText2Type,
        recSucDisplayText2Content,
        isOpenRelay,
        recSucWiegandType,
        recSucWiegandContent,
        comModType,
        //recStrangerType,
        recStrangerTimesThreshold,
        relaySwitch,

        // Other
        showIp,
        touchTone,
        whitelist,
        companyName,
        wgInputType,
        serialOutMode,
        showDeviceKey,
        showPeopleNum,
        applicationName,
        recModeCardIntf,
        recModeCardFaceIntf,
        recModeIdcardFaceIntf,
        recModeQREnable,
        isIDCardPositive,
        recCardFaceValue,
        recModeCardPhoto,
        recNoPerRelayType,
        recFailWiegandType,
        uniquenessRegImage,
        wgInputContentType,
        recModeCardHardware,
        recModeCardFaceHardware,
        recNoPerWiegandType,
        scrDisplayText1Type,
        scrDisplayText2Type,
        displayModStrangerType,
        recModeIdcardFaceEnable,
      } = this;

      const data = {
        pass,
        device_type: "OGATEMINI",
        config: {
          // Main
          saveIdentifyTime,
          recRank: this.formatFromNumToBoolean(recRank),
          identifyDistance,
          delayTimeForCloseDoor,
          recDisplayImageMode,
          //recFailSaveSpotImage:
          //  this.formatFromNumToBoolean(recFailSaveSpotImage),
          //recSucSaveSpotImage: this.formatFromNumToBoolean(recSucSaveSpotImage),
          // recNoPerSaveSpotImage: this.formatFromNumToBoolean(
          //   recNoPerSaveSpotImage
          // ),
          touchTone: this.formatFromNumToBoolean(touchTone),

          // Recognition
          recModeFaceEnable: this.formatFromNumToBoolean(recModeFaceEnable),
          identifyScores,
          multiplayerDetection,
          recModeCardEnable: this.formatFromNumToBoolean(recModeCardEnable),
          recModeCardFaceEnable: this.formatFromNumToBoolean(
            recModeCardFaceEnable
          ),
          recModeQRCodeComparison,
          isRecognitionOpen: this.formatFromNumToBoolean(isRecognitionOpen),

          // Settings
          voiceType,
          displayModType,
          displayModContent,
          recSucDisplayText2Type,
          recSucDisplayText2Content,
          isOpenRelay,
          recSucWiegandType,
          recSucWiegandContent,
          comModType,
          recStrangerType: 2,
          recStrangerTimesThreshold,
          relaySwitch,

          // Other
          showIp: this.formatFromNumToBoolean(showIp),
          whitelist: this.formatFromNumToBoolean(whitelist),
          companyName,
          wgInputType,
          serialOutMode,
          showDeviceKey: this.formatFromNumToBoolean(showDeviceKey),
          showPeopleNum: this.formatFromNumToBoolean(showPeopleNum),
          applicationName,
          recModeCardIntf,
          recModeCardFaceIntf,
          recModeIdcardFaceIntf,
          recModeQREnable,
          isIDCardPositive: this.formatFromNumToBoolean(isIDCardPositive),
          recCardFaceValue,
          recModeCardPhoto: this.formatFromNumToBoolean(recModeCardPhoto),
          recNoPerRelayType,
          recFailWiegandType,
          uniquenessRegImage: this.formatFromNumToBoolean(uniquenessRegImage),
          wgInputContentType,
          recModeCardHardware,
          recModeCardFaceHardware,
          recNoPerWiegandType,
          scrDisplayText1Type,
          scrDisplayText2Type,
          displayModStrangerType,
          recModeIdcardFaceEnable: this.formatFromNumToBoolean(
            recModeIdcardFaceEnable
          ),
        },
      };

      this.$emit("input", data);
    },

    formatToBoolean(prop) {
      switch (prop) {
        case 1:
          return false;
        case 2:
          return true;
        default:
          return false;
      }
    },

    formatFromNumToBoolean(prop) {
      switch (prop) {
        case false:
          return 1;
        case true:
          return 2;
        default:
          return 1;
      }
    },
  },
};
</script>
