<template>
  <div class="mt-5">
    <v-alert v-if="errors.information" type="error">
      {{ $ml.get("setting.saveErrorTo") }}
      <br />
      <br />
      <span class role="alert">{{ errors.information }}</span>
    </v-alert>

    <v-row>
      <v-col cols="12">
        <h5>{{ $ml.get("setting.basic") }}</h5>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-row no-gutters>
          <v-col>
            <v-text-field
              v-model.lazy="serverUrl"
              :rules="serverRules"
              :label="`${$ml.get('setting.ServerUrl')} *`"
              outlined
              required
            />
          </v-col>
          <v-col cols="auto">
            <button
              class="btn btn-success btn-url-auto"
              @click.prevent="setDefaultUrl"
            >
              {{ $ml.get("setting.url_auto") }}
            </button>
          </v-col>
        </v-row>
        <v-select
          name="lang"
          v-if="l.show"
          :items="langlist"
          :label="$ml.get('setting.lang')"
          v-model.lazy="lang"
          item-text="name"
          item-value="value"
          outlined
        />

        <v-switch
          v-model="antipassback"
          :label="$ml.get('setting.anti-passback')"
        />
        <v-switch
          v-model="syncDevices"
          :label="$ml.get('setting.syncDevices')"
        />
        <v-switch
          v-if="l.ssl_enable"
          v-model="ssl"
          :label="$ml.get('setting.ssl')"
        />
        <v-switch v-model="settime" :label="$ml.get('setting.settime')" />
        <v-switch v-model="autostart" :label="$ml.get('setting.autostart')" />
      </v-col>

      <v-col cols="12" md="6" lg="4">
        <h5>{{ $ml.get("setting.TemperatureMeasure") }}</h5>
        <v-switch
          v-model="temperatureEnable"
          :label="$ml.get('setting.temperatureEnable')"
        />
        <template v-if="temperatureEnable">
          <v-card flat color="transparent">
            <v-card-text class="pt-10 range">
              <v-row>
                <v-range-slider
                  v-model="temperatureRange"
                  :label="labelRange()"
                  :min="calculateCalcFeel(20)"
                  :max="calculateCalcFeel(39)"
                  step="0.1"
                  thumb-label="always"
                  ticks
                ></v-range-slider>
              </v-row>
            </v-card-text>
          </v-card>
          <v-switch
            v-model="temperatureBlocked"
            :label="$ml.get('setting.temperatureBlocked')"
          />
          <v-switch
            v-model="temperatureRise"
            :label="$ml.get('setting.temperatureRise')"
          />

          <v-select
            v-model="temperatureMeasure"
            :items="measure"
            :label="$ml.get('setting.temperature_units')"
            item-text="name"
            item-value="value"
            outlined
          />
        </template>
        <h5>{{ $ml.get("setting.maskoption") }}</h5>
        <v-switch
          v-model="maskRecognition"
          :label="$ml.get('setting.maskRecognition')"
        />
      </v-col>
    </v-row>

    <hr />

    <v-row>
      <v-col>
        <h4>{{ $ml.get("setting.limits") }}</h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          v-model.lazy="countVisits"
          type="number"
          min="30"
          max="3600"
          :label="`${$ml.get('setting.countVisits')} *`"
          outlined
          required
        />
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          v-model.lazy="countAlarms"
          type="number"
          min="100"
          max="10000000"
          :label="`${$ml.get('setting.countAlarms')} *`"
          outlined
          required
        />
      </v-col>
    </v-row>

    <hr />

    <v-row>
      <v-col>
        <h4>{{ $ml.get("setting.reload") }}</h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn color="error" @click.prevent="reloadTerminal">
          {{ $ml.get("setting.reload_terminal") }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: ["value"],
  name: "Info",
  data() {
    return {
      //setstyle: `<style>span.switcher input:before { content: 'Вкл';} span.switcher input:after { content: 'Выкл';}</style>`,
      errors: {
        information: "",
      },
      serverRules: [
        (v) => !!v || "Address is required",
        //v =>
        //  v.indexOf("://") != -1 || "Address must contain http:// or https://"
      ],
      serverUrl: "",
      antipassback: "",
      antispoofing: "",
      temperatureRange: [34.0, 37.2],
      temperatureBlocked: false,
      ssl: false,
      lock: true,
      lockTime: false,
      settime: false,
      autostart: false,
      maskRecognition: false,
      maskEnabled: false,
      temperatureEnable: false,
      temperatureRise: false,
      syncDevices: true,
      countAlarms: 10000,
      countVisits: 3600,
      trange: [],
      lang: "en",
      langlist: [
        { name: "English", value: "en" },
        { name: "Русский", value: "ru" },
      ],
      l: {
        lang: "en",
        show: false,
        ssl_enable: false,
      },
      measure: [
        { name: this.$ml.get("setting.celsia"), value: "c" },
        { name: this.$ml.get("setting.fahrenheit"), value: "f" },
      ],
      temperatureMeasure: "c",
    };
  },
  mounted() {
    if (localStorage.getItem("ovision-ml")) {
      try {
        this.l = JSON.parse(localStorage.getItem("ovision-ml"));
      } catch (err) {
        console.error(err);
      }
    }
  },
  methods: {
    load() {
      this.lock = true;
      this.serverUrl = this.value["SERVER_URL"];
      this.antipassback = this.value["ANTIPASSBACK"] == "on";
      this.antispoofing = this.value["ANTISPOOFING"] == "on";
      this.temperatureRise = this.value["TEMPERATURE_RISE"] == "on";
      this.settime = this.value["BROWSERTIME"] == "on";
      this.autostart = this.value["AUTOSTART"] == "on";
      this.ssl = this.value["SSL"] == "on";
      this.temperatureEnable = this.value["TEMPERATURE_ENABLE"] == "on";
      this.temperatureBlocked = this.value["TEMPERATURE_BLOCKED"] == "on";
      this.temperatureMeasure = this.value["TEMPERATURE_MEASURE"] || "c";
      this.maskRecognition = this.value["MASK_RECOGNITION"] == "on";
      this.maskEnabled = this.value["MASK_ENABLED"] == "on";
      this.lang = this.value["LANGUAGE"] || "en";
      this.countAlarms = this.value["ALARM_COUNT"] || 10000;
      this.countVisits = this.value["VISIT_COUNT"] || 3600;
      this.syncDevices = this.value["SYNC_DEVICES"] != "off";
      let tm = this.value["TEMPERATURE_RANGE"];
      if (tm) {
        let t = tm.split(",");
        t[0] = t[0] || 34;
        t[1] = t[1] || 37.4;
        this.setTemperatureRange(t);
      }
    },
    setTemperatureRange(range) {
      this.temperatureRange[0] = this.calculateCalcFeel(range[0]);
      this.temperatureRange[1] = this.calculateCalcFeel(range[1]);
    },
    calculateCalcFeel(t) {
      if (this.temperatureMeasure === undefined) {
        return;
      }
      if (this.temperatureMeasure == "f") {
        return Math.round(((t * 9) / 5 + 32) * 10) / 10;
      } else {
        return t;
      }
    },
    calculateFeelCalc(t) {
      if (this.temperatureMeasure === undefined) {
        return;
      }
      if (this.temperatureMeasure == "f") {
        return Math.round((((t - 32) * 5) / 9) * 10) / 10;
      } else {
        return t;
      }
    },
    labelRange() {
      return (
        this.$ml.get("setting.temperatureRange") +
        " " +
        (this.temperatureMeasure == "f" ? "(°F)" : "(°C)")
      );
    },
    store(values) {
      let config = {
        header: {
          "Content-Type": "application/json",
        },
        timeout: 20000,
      };
      axios.post("/settings", values, config).then((response) => {
        if (response.data.status.code == 0) {
          this.$notify({
            group: "info",
            type: "success",
            text: this.$ml.get("message.saveSuccess"),
          });
        } else {
          this.$notify({
            group: "info",
            type: "error",
            text: response.data.status.message,
          });
        }
      });
    },
    setDefaultUrl() {
      axios.get("/setting/getUrl").then((response) => {
        this.serverUrl = response.data.data.url;
      });
    },
    setStorageDef() {
      localStorage.setItem("ovision-ml", JSON.stringify(this.l));
    },
    reloadTerminal() {
      this.$dialog
        .confirm(`<h3>${this.$ml.get("message.reloadTerminal")}</h3>`, {
          loader: false,
          okText: this.$ml.get("button.apply"),
          customText: this.$ml.get("button.show"),
          cancelText: this.$ml.get("button.cancel"),
          backdropClose: true,
        })
        .then(() => {
          $("body").append(
            `<div class="interface-blocker">${this.$ml.get(
              "message.terminalIsReloading"
            )}</div>`
          );
          axios.get("/restart");

          const checkWsConnection = setInterval(() => {
            if (window.$ws.readyState === 1) {
              clearInterval(checkWsConnection);
              location.reload();
            }
          }, 1000);
        });
    },
  },
  watch: {
    value(val) {
      if (localStorage.getItem("ovision-ml")) {
        try {
          this.l = JSON.parse(localStorage.getItem("ovision-ml"));
        } catch (err) {
          console.error(err);
        }
      }
      this.load();
    },
    serverUrl(val) {
      // Запись в базу с задержкой 2 сек.
      let _this = this;
      if (!this.lock && !this.lockTime) {
        this.lockTime = true;
        setTimeout(function () {
          _this.lockTime = false;
          _this.store([{ key: "SERVER_URL", value: _this.serverUrl }]);
        }, 2000);
      }
    },
    antipassback(val) {
      if (!this.lock) {
        this.store([{ key: "ANTIPASSBACK", value: val ? "on" : "off" }]);
      }
    },
    antispoofing(val) {
      if (!this.lock) {
        this.store([{ key: "ANTISPOOFING", value: val ? "on" : "off" }]);
      }
    },
    settime(val) {
      if (!this.lock) {
        this.store([{ key: "BROWSERTIME", value: val ? "on" : "off" }]);
      }
    },
    autostart(val) {
      if (!this.lock) {
        this.store([{ key: "AUTOSTART", value: val ? "on" : "off" }]);
      }
    },
    temperatureRange(val) {
      let _this = this;
      if (val[0] > val[1]) val[0] = val[1] - 0.1;
      this.trange[0] = _this.calculateFeelCalc(val[0]);
      if (this.trange[0] == 20) this.trange[0] = 0;
      this.trange[1] = _this.calculateFeelCalc(val[1]);
      if (!this.lock && !this.lockTime) {
        this.lockTime = true;
        setTimeout(function () {
          _this.l.temperature_range = _this.trange.join(",").toString();
          _this.setStorageDef();
          _this.lockTime = false;
          _this.store([
            {
              key: "TEMPERATURE_RANGE",
              value: _this.trange.join(",").toString(),
            },
          ]);
        }, 2000);
      }
    },
    temperatureEnable(val) {
      if (!this.lock) {
        this.l.temperature_enabled = val;
        this.setStorageDef();
        this.store([{ key: "TEMPERATURE_ENABLE", value: val ? "on" : "off" }]);
      }
    },
    temperatureRise(val) {
      if (!this.lock) {
        this.l.temperature_rise = val;
        this.setStorageDef();
        this.store([{ key: "TEMPERATURE_RISE", value: val ? "on" : "off" }]);
      }
    },
    temperatureBlocked(val) {
      if (!this.lock) {
        this.store([{ key: "TEMPERATURE_BLOCKED", value: val ? "on" : "off" }]);
      }
    },
    temperatureMeasure(val) {
      if (!this.lock) {
        this.l.temp_meas = val;
        this.setStorageDef();
        this.store([{ key: "TEMPERATURE_MEASURE", value: val }]);
        document.location.reload();
      }
    },
    maskRecognition(val) {
      if (!this.lock) {
        this.store([{ key: "MASK_RECOGNITION", value: val ? "on" : "off" }]);
      }
    },
    maskEnabled(val) {
      if (!this.lock) {
        this.store([{ key: "MASK_ENABLED", value: val ? "on" : "off" }]);
      }
    },
    syncDevices(val) {
      if (!this.lock) {
        this.store([{ key: "SYNC_DEVICES", value: val ? "on" : "off" }]);
      }
    },
    ssl(val) {
      if (this.ssl && this.l.ssl_enable) {
        this.serverUrl = this.serverUrl.replace("http://", "https://");
      } else {
        this.serverUrl = this.serverUrl.replace("https://", "http://");
      }
      if (!this.lock) {
        this.store([{ key: "SSL", value: val ? "on" : "off" }]);
      }
    },
    countAlarms(val) {
      if (!this.lock) {
        this.store([{ key: "ALARM_COUNT", value: val }]);
      }
    },
    countVisits(val) {
      if (!this.lock) {
        this.store([{ key: "VISIT_COUNT", value: val }]);
      }
    },
    lang(val) {
      if (!this.lock) {
        this.l.lang = val;
        this.setStorageDef();
        this.store([{ key: "LANGUAGE", value: val }]);
        document.location.reload();
      }
    },
  },
  updated() {
    this.lock = false;
  },
};
</script>

<style scoped>
.btn-url-auto {
  height: 55px;
}
.range {
  margin-bottom: -17px;
}
</style>
