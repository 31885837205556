<template>
  <v-container>
    <v-app>
      <v-card elevation="2">
        <v-card-text>
          <notifications group="info" position="top right" />

          <v-row>
            <v-col cols="12">
              <v-alert v-if="faceValidErrorMessage" type="error">{{
                faceValidErrorMessage
              }}</v-alert>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6" lg="4">
              <v-skeleton-loader v-if="isPending" type="image" />

              <template v-if="isSuccess">
                <ImageBox
                  :devices="devices"
                  :photo="photo"
                  :newphoto="newPhoto"
                  @toggle="togglePhoto"
                />
              </template>
            </v-col>

            <v-col cols="12" md="6" lg="8">
              <v-skeleton-loader v-if="isPending" type="article" />

              <v-form
                v-if="isSuccess"
                ref="form"
                v-model="valid"
                lazy-validation
              >
                <v-alert v-if="isFailure && errorMessage" type="error">{{
                  errorMessage
                }}</v-alert>

                <v-alert
                  v-if="isFailureStore && errorMessageStore"
                  type="error"
                  >{{ errorMessageStore }}</v-alert
                >

                <div class="mb-5">
                  <strong>{{ $ml.get("auth.username") }}: </strong>
                  {{ username }}
                </div>

                <v-divider class="mb-5" />

                <v-text-field
                  v-model="fullName"
                  :rules="[(v) => !!v || $ml.get('message.NameIsRequired')]"
                  :label="`${$ml.get('user.fullName')} *`"
                  outlined
                  required
                />

                <v-text-field
                  v-model="phone"
                  :label="`${$ml.get('user.phone')}`"
                  outlined
                />

                <v-btn
                  v-if="!isSetNewPass"
                  class="mb-5"
                  @click="isSetNewPass = true"
                >
                  {{ $ml.get("guard.changeAuth") }}
                </v-btn>

                <template v-if="isSetNewPass">
                  <v-divider class="mb-5" />

                  <v-row align="center" class="mb-5">
                    <v-col>
                      <h5>
                        {{ $ml.get("guard.changeAuth") }}
                      </h5>
                    </v-col>
                    <v-spacer />
                    <v-col>
                      <v-btn @click="onGeneratePassword">{{
                        $ml.get("guard.generatePassword")
                      }}</v-btn>
                    </v-col>
                  </v-row>

                  <v-text-field
                    :type="isShowPass ? 'text' : 'password'"
                    v-model="pass"
                    :rules="passwordRules"
                    :label="`${$ml.get('auth.newPassword')}`"
                    autocomplete="off"
                    :append-icon="isShowPass ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="isShowPass = !isShowPass"
                    outlined
                  />

                  <v-text-field
                    ref="passwordConfirmation"
                    :type="isShowConfirmPass ? 'text' : 'password'"
                    v-model="confirmPass"
                    :rules="passwordConfirmRules"
                    :label="`${$ml.get('auth.passwordConfirm')}`"
                    autocomplete="off"
                    :append-icon="isShowConfirmPass ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="isShowConfirmPass = !isShowConfirmPass"
                    outlined
                  />
                </template>

                <v-row>
                  <v-col v-if="this.$auth.user().password_upd" cols="auto">
                    <v-btn @click="goBack">{{ $ml.get("button.back") }}</v-btn>
                  </v-col>

                  <v-spacer />

                  <v-col cols="auto">
                    <v-btn color="primary" @click="save">{{
                      $ml.get("button.save")
                    }}</v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-app>
  </v-container>
</template>

<script>
import ImageBox from "@/components/ImageBox";
import passwordGenerator from "generate-password";
import faceValidate from "@/mixins/faceValidate";

export default {
  components: { ImageBox },

  mixins: [faceValidate],

  data() {
    return {
      defaultData: null,

      isPending: false,
      isSuccess: false,
      isFailure: false,
      errorMessage: "",

      isPendingStore: false,
      isSuccessStore: false,
      isFailureStore: false,
      errorMessageStore: "",

      valid: true,
      id: this.$route.params.id,
      username: "",
      fullName: "",
      phone: "",
      photo: "",
      newPhoto: "",
      photoErrorMessage: "",
      devices: [],

      isSetNewPass: !this.$auth.user().password_upd ? true : false,
      pass: "",
      confirmPass: "",
      isShowPass: false,
      isShowConfirmPass: false,

      passwordRules: [
        (v) => {
          const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/;
          return pattern.test(v) || this.$ml.get("auth.strongPasswordRules");
        },
      ],
      passwordConfirmRules: [
        (v) =>
          !!v ||
          `${this.$ml.get("auth.password")} ${this.$ml.get(
            "message.IsRequired"
          )}`,
        (v) => v === this.pass || this.$ml.get("auth.passwords_must_match"),
      ],
    };
  },

  watch: {
    async password() {
      await this.$nextTick();
      this.$refs.passwordConfirmation.validate();
    },
  },

  methods: {
    getUser() {
      this.isPending = true;
      this.isSuccess = false;
      this.isFailure = false;
      this.errorMessage = "";

      if (this.id) {
        this.axios(this.url)
          .then((response) => {
            this.isPending = false;
            this.isSuccess = true;
            this.isFailure = false;
            this.errorMessage = "";

            const {
              data: { data },
            } = response;

            this.defaultData = data;

            this.fullName =
              `${data.last_name} ${data.first_name} ${data.middle_name}`.trim();
            this.phone = data.phone;
            this.photo = data.photo;
            this.username = data.username || data.permission.username;
          })
          .catch((error) => {
            this.isPending = false;
            this.isSuccess = false;
            this.isFailure = true;
            this.errorMessage = error.response.data.status.message;
          });
      } else {
        this.isPending = false;
        this.isSuccess = false;
        this.isFailure = true;
        this.errorMessage = this.$ml.get("message.errorLoading");
      }
    },

    getDevices() {
      this.axios("devices").then((response) => {
        this.devices = response.data ? response.data : [];
      });
    },

    togglePhoto(val) {
      this.newPhoto = val;

      this.validateFace(val);
    },

    goBack() {
      this.$router.go(-1);
    },

    save() {
      if (this.$refs.form.validate()) {
        this.isPendingStore = true;
        this.isSuccessStore = false;
        this.isFailureStore = false;
        this.errorMessageStore = "";

        this.axios
          .post(this.url, this.data)
          .then(() => {
            this.isPendingStore = false;
            this.isSuccessStore = true;
            this.isFailureStore = false;
            this.errorMessageStore = "";

            this.$auth.user({
              ...this.$auth.user(),
              ...this.splitName,
              photo: this.photo,
              photo_encrypted: this.newPhoto,
              password_upd: true,
            });

            this.$router.push({ path: "/" });
          })
          .catch((error) => {
            this.isPendingStore = false;
            this.isSuccessStore = false;
            this.isFailureStore = true;
            this.errorMessageStore = error.response.data.status.message;
          });
      }
    },

    getPassword() {
      return passwordGenerator.generate({
        length: 8,
        numbers: true,
        excludeSimilarCharacters: true,
        strict: true,
      });
    },

    onGeneratePassword() {
      this.$notify({
        group: "info",
        type: "success",
        text: this.$ml.get("guard.passwordGeneratedMessage"),
      });

      const generatedPassword = this.getPassword();
      this.pass = generatedPassword;
      this.confirmPass = generatedPassword;
    },
  },

  computed: {
    url() {
      let url = "";

      switch (this.$auth.user().type_user) {
        case "admin":
        case "guard":
          url = `guard/${this.id}`;
          break;
        default:
          url = `user/${this.id}`;
          break;
      }

      return url;
    },

    data() {
      const data = {
        ...this.defaultData,
        ...this.splitName,
        phone: this.phone,
        photo_encrypted: this.newPhoto,
        photo: this.photo,
      };

      switch (this.$auth.user().type_user) {
        case "admin":
        case "guard":
          data.password = this.pass;
          break;
        default:
          data.permission = {
            ...this.defaultData.permission,
            password: this.pass,
          };
          break;
      }

      return data;
    },

    splitName() {
      const splitName = {};

      const name = this.fullName.trim().split(" ");

      if (name) {
        if (name.length === 1) {
          splitName.first_name = name[0];
        } else {
          if (name[0]) {
            splitName.last_name = name[0];
          }

          if (name[1]) {
            splitName.first_name = name[1];
          }

          if (name[2]) {
            splitName.middle_name = name
              .filter((item, index) => index !== 0 && index !== 1)
              .join(" ");
          }
        }
      }

      return splitName;
    },
  },

  created() {
    this.getUser();
    this.getDevices();
  },
};
</script>
