<template>
  <v-app>
    <notifications group="info" position="top right" />
    <div class="row">
      <div class="col-md-4 col-sm-12" style="float: left; min-width: 200px">
        <div v-if="isZoneEdit()" class="panel">
          <button
            class="btn btn-primary v-icon mdi mdi-shape-circle-plus mr-1"
            @click="addZone"
            :title="$ml.get('zone.addNewDeviceZone')"
          ></button>
          <button
            v-if="zoneActive.id !== undefined && zoneActive.id !== null"
            class="btn btn-primary v-icon mdi mdi-circle-edit-outline mr-1"
            @click="editZone"
            :title="$ml.get('zone.makeChanges')"
          ></button>
          <button
            v-if="zoneActive.id !== undefined && zoneActive.id !== null"
            class="btn btn-danger v-icon mdi mdi-delete-circle-outline"
            @click="deleteZone"
            :title="$ml.get('zone.deleteSelectedZone')"
          ></button>
        </div>
        <v-card class="mx-auto">
          <v-list>
            <v-treeview hoverable :items="tree" return-object activatable>
              <template slot="label" slot-scope="{ item }">
                <a
                  :class="'treeitem ' + setTreeActiveClass(item)"
                  @click="selectZone(item, $event)"
                  @dblclick="editZone"
                  style="height: 100%; width: 100%; display: block; float: left"
                  >{{ item.name }}</a
                >
              </template>
            </v-treeview>
          </v-list>
        </v-card>
      </div>
      <div class="col-md-8 col-sm-12" style="float: left">
        <div class="top-button-box" v-if="isDeviceEdit()">
          <span class="col-sm-6">
            <div
              v-if="getSelectCount() > 0"
              class="dropdown"
              style="display: inline-block"
            >
              <button
                style="margin-left: -20px"
                class="btn btn-primary dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                type="button"
                aria-expanded="true"
              >
                {{ $ml.get("menu.actions") }}&nbsp;
                <span class="caret"></span>
              </button>
              <div class="dropdown-menu">
                <a class="dropdown-item" href="#" @click="emergency('open')">{{
                  $ml.get("devices.emergency.open")
                }}</a>
                <a class="dropdown-item" href="#" @click="emergency('close')">{{
                  $ml.get("devices.emergency.close")
                }}</a>
                <a
                  class="dropdown-item"
                  href="#"
                  @click="emergency('default')"
                  >{{ $ml.get("devices.emergency.default") }}</a
                >
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="#" @click="ogateStatus('off')">{{
                  $ml.get("devices.ogatestate.off")
                }}</a>
                <a class="dropdown-item" href="#" @click="ogateStatus('on')">{{
                  $ml.get("devices.ogatestate.on")
                }}</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="#" @click="ogateReboot()">{{
                  $ml.get("devices.reboot")
                }}</a>
              </div>
            </div>
          </span>
          <span class="col-sm-6 text-right">
            <div
              v-if="isDeviceEdit()"
              class="dropdown"
              style="display: inline-block"
            >
              <button
                style="margin-left: -20px"
                class="btn btn-primary dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                type="button"
                aria-expanded="true"
              >
                {{ $ml.get("devices.addDevice") }}&nbsp;
                <span class="caret"></span>
              </button>
              <div class="dropdown-menu">
                <router-link
                  :to="{ name: 'addDevice', query: { device: 'OGATE' } }"
                  class="dropdown-item"
                  :title="$ml.get('devices.addNewDeviceOgate')"
                  >{{ $ml.get("devices.addNewDeviceOgate") }}</router-link
                >
                <router-link
                  :to="{ name: 'addDevice', query: { device: 'UFACE' } }"
                  class="dropdown-item"
                  :title="$ml.get('devices.addNewDeviceUface')"
                  >{{ $ml.get("devices.addNewDeviceUface") }}</router-link
                >
              </div>
            </div>
          </span>
        </div>
        <datatable
          :fields="fields"
          :rows="devices.data"
          :rowstyle="styleRow"
          :dblclick="edit"
          :select="true"
          >{{ $ml.get("devices.notDevices") }}
          <template v-slot:footer>
            <vue-pagination
              :pagination="devices.pagination"
              @paginate="getDevices()"
              :offset="offset"
            ></vue-pagination>
            <template v-if="$auth.user().type_user == 'admin'">
              <v-btn
                :loading="loading"
                :disabled="loading"
                color="primary"
                class="ma-2"
                @click="scan"
                >{{ $ml.get("devices.scan") }}
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
              <v-btn
                v-if="getSelectDev()"
                :loading="loading"
                :disabled="loading"
                color="primary"
                class="ma-2"
                @click="addSelectDev"
                >{{ $ml.get("devices.addscandevices") }}
              </v-btn>

              <template v-if="addresses.length">
                <hr />
                <div class="scaner v-input__slot">
                  <div class="v-select__slot">
                    <div class="v-select__selections">
                      <span
                        v-for="ip in addresses"
                        :key="ip"
                        class="
                          v-chip--select
                          v-chip v-chip--no-color
                          theme--light
                          v-size--default
                        "
                        ><span class="v-chip__content">
                          <v-checkbox
                            v-if="!checkDev(ip)"
                            v-model="selectAddDev[ip]"
                            hide-details
                            :ripple="false"
                            class="p-0 m-0"
                          />
                          <span @click="showIp(ip)">
                            {{ ip }}
                          </span></span
                        ></span
                      >
                    </div>
                  </div>

                  <!-- <v-select
                  @click="console.log('close')"
                  multiple
                  readonly
                  chips
                  :items="addresses"
                  v-model="addresses"
                ></v-select> -->
                  <CameraBox
                    v-if="isCameraShow"
                    @toggle="isCameraShow = $event"
                    :devices="netips"
                    :showing="isCameraShow"
                    :showcontrol="false"
                  />
                </div>
              </template>
            </template>
          </template>
        </datatable>
      </div>
      <v-app v-if="zonedialog">
        <ModalZoneEdit
          @toggle="zoneDialogClose($event)"
          v-model="editZoneItem"
          :zones="zones"
          :parent="zoneActive"
        />
      </v-app>
    </div>
  </v-app>
</template>
<script>
import ModalZoneEdit from "./ModalZoneEdit";
import datatable from "../../components/datatable";
import CameraBox from "../../components/CameraBoxOld";
import VuePagination from "../../components/pagination";
import axios from "axios";
import router from "../../router";
import moment from "moment-timezone";
var stat = {};
export default {
  name: "DeviceList",
  components: { datatable, ModalZoneEdit, CameraBox, VuePagination },
  data() {
    return {
      devlist: ["OGATEV8", "OGATEV9", "OGATES", "OGATEMINI"],
      loading: false,
      selectAddDev: [],
      zoneActive: {},
      editZoneItem: {},
      selectDevices: [],
      fields: [
        {
          name: "_select",
          type: "checkbox",
          class: "check-select",
          style: "width: 50px;",
          click: this.select,
          header: {
            class: "check-select",
            type: "checkbox",
            click: this.headerSelect,
            value: "",
          },
        },
        {
          name: "deviceIcon",
          style: "width: 80px; margin-right: 10px;",
          type: "img",
          title: "deviceType",
          header: { style: "width: 100px" },
        },
        // {
        //   name: "deviceType",
        //   header: { style: "width: 120px" },
        // },
        {
          group: [
            { name: "name", style: "font-size: 12px; font-weight: bold;" },
            {
              name: "zone_name",
              style: "display: block; font-size: 10px; margin-right: 3px;",
            },
            { name: "deviceType", style: "font-size: 12px;" },
          ],
          header: { text: this.$ml.get("devices.nameDevice") },
        },
        {
          group: [
            {
              name: "gate_command",
              style:
                "font-size: 12px; float: left; margin-right: 3px; padding-left:20px;",
            },
            {
              name: "system_state",
              style:
                "font-size: 12px; float: left; margin-right: 3px; padding-left:20px;",
            },
          ],
          header: {
            text: this.$ml.get("devices.gateCommand"),
            style: "width: 80px; padding-left:10px;",
          },
        },
        {
          group: [
            { name: "purposeText", style: "padding-left:10px;" },
            {
              name: "controlMode",
              style:
                "font-size: 10px; float: left; margin-right: 3px; padding-left:10px;",
            },
          ],
          header: { text: this.$ml.get("devices.purpose") },
          style: "width: 100px; padding-left:10px;",
        },
        {
          group: [
            {
              name: "ip_address",
              style: "width: 120px; padding-left:8px;",
            },
            {
              name: "pipeline_time",
              text: this.$ml.get("devices.timeDevice"),
              title: this.$ml.get("devices.timeDevice"),
              style:
                "font-size: 10px; float: left; margin-right: 3px; padding-left:8px;",
            },
          ],
          header: { text: this.$ml.get("devices.ipDevice") },
          style: "width: 140px; padding-left:8px;",
        },
        {
          name: "person_num",
          style: "margin-right: 10px;",
          header: {
            text: " ",
            style: "width: 40px;",
            class: "user_num",
          },
        },
        {
          type:
            this.$auth.user().type_user == "admin" ||
            this.$auth.user().roles.includes("deviceedit")
              ? "button"
              : "none",
          title: this.$ml.get("devices.configureDevice"),
          class: "btn btn-primary v-icon mdi mdi-cog",
          click: this.edit,
          style: "width: 40px; margin-right: 2px;",
        },
        {
          type: "button",
          title: this.$ml.get("devices.syncroDevice"),
          class: "btn btn-default v-icon mdi mdi-reload",
          click: this.syncro,
          style: "width: 40px; margin-left: 2px;",
        },
      ],
      sync: {},
      zonedialog: false,
      devices: {
        pagination: {
          total: 0,
          per_page: 2,
          from: 1,
          to: 10,
          current_page: 1,
        },
        data: [],
      },
      alldevices: [],
      zones: [],
      zons: [],
      stat: {},
      tree: [],
      addresses: [],
      isCameraShow: false,
      netips: { data: [{ ip_address: "", purpose: "all", name: "", id: 1 }] },
    };
  },
  methods: {
    headerSelect(val, e, items) {
      //val = e.target.checked;
      items = items || [];
      items.forEach((item) => {
        item["_select"] = e;
      });
    },
    select(item, e) {
      item["_select"] = !item["_select"];
      e.target.checked = item["_select"];
    },
    deselect() {
      this.devices.data.forEach((item) => {
        item["_select"] = false;
      });
    },
    getSelectCount() {
      let count = 0;
      let selectDevices = [];
      this.devices.data.forEach((item) => {
        if (item["_select"]) {
          console.log(item.id);
          selectDevices.push(item.id);
          count++;
        }
      });
      this.fields[0].header.type = this.devices.data.length ? "checkbox" : null;
      this.fields[0].header.value = this.devices.data.length == count;
      this.selectDevices = selectDevices;
      return count;
    },
    emergency(status) {
      if (this.selectDevices.length) {
        let form = {
          command: status,
        };
        let config = {
          header: {
            "Content-Type": "application/json",
          },
          timeout: 5000,
        };
        this.selectDevices.forEach((id) => {
          console.log(form, id);
          axios
            .put("/device/" + id + "/control", form, config)
            .then((response) => {
              if (response.data.status.code == 0) {
                this.devices.data.forEach((item) => {
                  console.log(item);
                  if (item.id == id) {
                    item.gate_command = status;
                    item._select = false;
                    this.$notify({
                      group: "info",
                      type: "success",
                      text:
                        this.$ml.get("devices.setNewContolMode") +
                        " " +
                        item.name,
                    });
                  }
                });
              }
            })
            .catch((error) => {
              this.devices.data.forEach((item) => {
                if (item.id == id) {
                  item._select = false;
                  this.$notify({
                    group: "info",
                    type: "error",
                    text:
                      error.response.data.status.message + " - " + item.name,
                  });
                }
              });
            });
        });
      }
    },
    ogateReboot() {
      if (this.selectDevices.length) {
        let config = {
          header: {
            "Content-Type": "application/json",
          },
          timeout: 5000,
        };
        this.selectDevices.forEach((id) => {
          axios
            .put("/device/" + id + "/reboot", {}, config)
            .then((response) => {
              if (response.data.status.code == 0) {
                this.devices.data.forEach((item) => {
                  console.log(item);
                  if (item.id == id) {
                    item.system_state = status;
                    item._select = false;
                    this.$notify({
                      group: "info",
                      type: "success",
                      text:
                        this.$ml.get("devices.setNewOgateReboot") +
                        " " +
                        item.name,
                    });
                  }
                });
              }
            })
            .catch((error) => {
              this.devices.data.forEach((item) => {
                if (item.id == id) {
                  item._select = false;
                  this.$notify({
                    group: "info",
                    type: "error",
                    text:
                      error.response.data.status.message + " - " + item.name,
                  });
                }
              });
            });
        });
      }
    },
    ogateStatus(status) {
      if (this.selectDevices.length) {
        let form = {
          state: status,
        };
        let config = {
          header: {
            "Content-Type": "application/json",
          },
          timeout: 5000,
        };
        this.selectDevices.forEach((id) => {
          console.log(form, id);
          axios
            .put("/device/" + id + "/state", form, config)
            .then((response) => {
              if (response.data.status.code == 0) {
                this.devices.data.forEach((item) => {
                  console.log(item);
                  if (item.id == id) {
                    item.system_state = status;
                    item._select = false;
                    this.$notify({
                      group: "info",
                      type: "success",
                      text:
                        this.$ml.get("devices.setNewOgateStatus") +
                        " " +
                        item.name,
                    });
                  }
                });
              }
            })
            .catch((error) => {
              this.devices.data.forEach((item) => {
                if (item.id == id) {
                  item._select = false;
                  this.$notify({
                    group: "info",
                    type: "error",
                    text:
                      error.response.data.status.message + " - " + item.name,
                  });
                }
              });
            });
        });
      }
    },
    syncdevice: function (val) {
      //console.log("syncdevice:", val);
      this.setStatus(val);
      let devs = this.devices.data;
      this.devices.data = [];
      devs.forEach((item) => {
        if (val.id == item.id) {
          if (val.pipeline_time) {
            item.pipeline_time = moment(val.pipeline_time).format(
              "DD.MM.YY HH:mm:ss"
            );
          }
          if (val.person_num) {
            item.person_num = val.person_num;
          }
          if (val.gate_command) {
            item.gate_command = val.gate_command;
          }
          if (val.system_state) {
            item.system_state = val.system_state;
          }
        }
        this.devices.data.push(item);
      });
    },
    loadfeatures: function (val) {
      console.log("Load Features:", val);
      this.setProgress(val);
      let devs = this.devices.data;
      this.devices.data = [];
      devs.forEach((item) => {
        this.devices.data.push(item);
      });
    },

    // Добавить устройства из списка
    addSelectDev() {
      const ips = [];
      for (const key in this.selectAddDev) {
        if (this.selectAddDev[key]) {
          ips.push(key);
        }
      }

      ips.map((ip) => this.addOneDevice(ip));

      Promise.all(ips).then(() => {
        setTimeout(() => {
          this.getDevices();
        }, 500);
      });
      this.selectAddDev = [];
    },
    addOneDevice(ip) {
      let form = {
        timestamp: moment().unix(),
        name: ip,
        purpose: "entrance",
        ip_address: ip,
        enabled: true,
        //zones_id: this.zonesId,
        data:
          `{ "temperature_enable": "off", ` +
          `"recognition_range": 2, ` +
          `"control_mode": "only_led",` +
          `"card_type": "",` +
          `"pass_timeout": 2,` +
          `"system_mode": "default", ` +
          `"recognition_face": 0.7, ` +
          `"recognition_mask": 0.62, ` +
          `"mask_enabled": false, ` +
          `"show_name": "short",` +
          `"show_themp_info": false,` +
          `"show_access": false,` +
          `"antispoofing_mode": "print",` +
          `"display_mode": "static",` +
          `"autoregistration_mode": false }`,
      };
      if (this.zoneActive.id) {
        form.zones_id = this.zoneActive.id;
      }
      let config = {
        header: {
          "Content-Type": "application/json",
        },
        timeout: 20000,
      };

      return axios
        .post("/device/add", form, config)
        .then((response) => {
          if (response.data.success == false) {
            this.errorMessage = response.data.message;
          }
        })
        .catch((error) => {
          this.errorMessage = this.$ml.get("devices.deviceExist");
        });
    },
    checkDev(ip) {
      let check = false;
      this.alldevices.forEach((d) => {
        if (ip == d) {
          check = true;
          return;
        }
      });
      return check;
    },
    getSelectDev() {
      let cnt = 0;
      this.addresses.forEach((ip) => {
        if (this.selectAddDev[ip]) {
          cnt++;
        }
      });
      return cnt;
    },
    scan() {
      this.addresses = [];
      this.loading = true;
      axios.get("/device/scan").then((response) => {
        console.log(response);
        if (response.status == 200) {
          this.addresses = response.data.data ? response.data.data : [];
          localStorage.setItem(
            "terminal.devices.addr",
            JSON.stringify(this.addresses)
          );
        }
        this.loading = false;
      });
    },
    showIp(ip) {
      this.netips.data[0].ip_address = ip;
      this.netips.data[0].name = ip;
      this.isCameraShow = true;
    },
    isZoneEdit() {
      return (
        this.$auth.user().type_user == "admin" ||
        this.$auth.user().roles.includes("zoneedit")
      );
    },
    isDeviceEdit() {
      return (
        this.$auth.user().type_user == "admin" ||
        this.$auth.user().roles.includes("deviceedit")
      );
    },
    zoneDialogClose(val) {
      this.zonedialog = val;
      this.getZones();
    },
    selectZone(item, e) {
      $(".v-treeview-node__root").css("background-color", "#fff");
      let el = $(e.target).closest(".v-treeview-node__root");
      el.css("background-color", "#e2e2e2");
      // let el2 = $(e.target).closest(".v-treeview-node__toggle");
      // el2.classList.remove("mdi-menu-down");

      this.zoneActive = item;
      localStorage.setItem("ovision-select-zone", JSON.stringify(item));
      this.getDevices();
    },
    addZone() {
      if (!this.isZoneEdit()) return;
      this.editZoneItem = {
        id: 0,
        parent_id: this.zoneActive.id || null,
        name: "",
      };
      this.zonedialog = true;
    },
    editZone() {
      if (!this.isZoneEdit() || this.zoneActive.id === null) return;
      this.editZoneItem = this.zoneActive;
      this.zonedialog = true;
    },
    deleteZone() {
      if (!this.isZoneEdit()) return;
      let options = {
        loader: false,
        okText: this.$ml.get("button.delete"),
        cancelText: this.$ml.get("button.cancel"),
        backdropClose: true,
      };
      let _this = this;
      this.$dialog
        .confirm(
          `<h3>${this.$ml.get("message.Delete")}</h3>` +
            this.$ml.get("message.confirmDelete"),
          {
            loader: false,
            okText: this.$ml.get("button.delete"),
            cancelText: this.$ml.get("button.cancel"),
            backdropClose: true,
          }
        )
        .then(function () {
          axios.delete("/zone/" + _this.zoneActive.id).then((response) => {
            if (response.status == 200) {
              if (response.data.success == false) {
                _this.errorMessage = response.data.message;
              } else {
                _this.getZones();
              }
            }
          });
        });
    },
    setStatus(val) {
      //console.log(val);
      this.devices.data.forEach((el) => {
        if (el.id == val.id) el.progress = val.status * 100;
      });
    },
    setProgress(val) {
      this.devices.data.forEach((el) => {
        if (el.id == val.id) {
          if (this.sync[el.id] === undefined) {
            this.sync[el.id] = Math.round(val.proc * val.total);
          }
          if (val.person_num) {
            el.person_num = val.person_num;
          }

          this.sync[el.id]++;
          //if (this.sync[el.id] > val.total) this.sync[el.id] = val.total;
          el.progress = Math.round((this.sync[el.id] / val.total) * 100);
          if (el.progress > 100) el.progress = 100;
          // console.log("IT:", this.sync[el.id], "proc:", el.progress);
        }
      });
    },
    syncro(item, event) {
      if (item["purpose"] == "off") return;
      let data = {
        timestamp: moment().unix(),
      };
      $(event.target).attr("disabled", true);
      this.sync[item.id] = 0;

      let config = {
        header: {
          "Content-Type": "application/json",
        },
        timeout: 20000,
      };

      axios
        .post(`/device/${item.id}/sync`, data, config)
        .then((response) => {
          if (response.status == 200) {
            $(event.target).attr(
              "class",
              "btn btn-success v-icon mdi mdi-reload"
            );
            $(event.target).attr("disabled", false);
          }
        })
        .catch((err) => {
          item.status = false;
          $(event.target).attr("disabled", false);
        });
    },
    styleRow(item) {
      if (item["purpose"] == "off") {
        return "background: #EFEFEF; color: #989898;";
      }
      if (item.progress) {
        return (
          "background: linear-gradient(90deg, rgba(166, 234, 166,1) " +
          item.progress +
          "%, rgba(166, 234, 166,1) 0%, rgba(225,225,225,0.2) " +
          item.progress +
          "%);"
        );
        //return "background: #a6eaa6; color: #000000;";
      }
      if (item.status) {
        //return "background: linear-gradient(90deg, rgba(80,100,121,1) 0%, rgba(80,100,121,1) 10%, rgba(225,225,225,0.2) 10%);";
        return "background: #a6eaa6; color: #000000;";
      }
    },
    edit(item) {
      if (!this.isDeviceEdit()) return;
      router.push({ path: `/device/${item.id}` });
    },
    getAllDevices() {
      this.alldevices = [];
      axios.get("/devices/ip").then((response) => {
        if (response.status == 200) {
          this.alldevices = response.data.data || [];
        }
      });
    },
    getDevices() {
      let values = {
        page: this.devices.pagination.current_page,
        limit: 20,
      };
      if (this.zoneActive) {
        values.zone = this.zoneActive.id;
      }

      this.devices.data = [];

      axios.get("/devices", { params: values }).then((response) => {
        console.log(response);
        if (response.status == 200) {
          let devices = response.data.data ? response.data.data : [];
          this.devices.pagination = response.data.meta.pagination;
          devices.forEach((item) => {
            //this.alldevices.push(item.ip_address);
            //console.log(this.alldevices);
            //if (!this.zoneActive.id || item.zones_id == this.zoneActive.id) {
            item._select = false;
            item.zone_name = this.zons[item.zones_id];
            item.purposeText = this.$ml.get("button." + item.purpose);
            item.controlMode = "";
            item.progress = 0;
            item.gate_command = "";
            if (item.data) {
              let data = JSON.parse(item.data);
              item.controlMode = data["control_mode"] || "no";
              item.deviceType = data["device_type"].toUpperCase();
              let dt = item.deviceType.split(".");
              let deviceType = dt[0] || "OGATE8";

              item.temperatureEnable = data["temperature_enable"] == "on";
              if (!deviceType || !this.devlist.includes(deviceType)) {
                //console.log(item.deviceType);
                item.deviceIcon = require("@/assets/img/devices/unknown.png");
              } else if (
                (deviceType == "OGATEV8" || deviceType == "OGATEV9") &&
                item.temperatureEnable
              ) {
                item.deviceIcon = require("@/assets/img/devices/" +
                  deviceType.toLowerCase() +
                  "-t.png");
                item.deviceType += " Thermo";
              } else {
                item.deviceIcon = require("@/assets/img/devices/" +
                  deviceType.toLowerCase() +
                  ".png");
              }
            }
            this.devices.data.push(item);
            //}
          });
        }
      });
    },
    getIcon() {
      return '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1em" height="1em" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32"><path d="M16 8a5 5 0 1 0 5 5a5 5 0 0 0-5-5zm0 8a3 3 0 1 1 3-3a3.003 3.003 0 0 1-3 3z" fill="#626262"/><path d="M16 2a14 14 0 1 0 14 14A14.016 14.016 0 0 0 16 2zm-6 24.377V25a3.003 3.003 0 0 1 3-3h6a3.003 3.003 0 0 1 3 3v1.377a11.899 11.899 0 0 1-12 0zm13.992-1.451A5.002 5.002 0 0 0 19 20h-6a5.002 5.002 0 0 0-4.992 4.926a12 12 0 1 1 15.985 0z" fill="#626262"/></svg>';
    },
    getTree(items, parent) {
      let result = [];
      items.forEach((el) => {
        if (parent == el.parent_id) {
          let item = {
            id: el.id,
            name: el.name,
            parent_id: el.parent_id,
            data: el.data,
          };
          let children = this.getTree(items, el.id);
          if (children) {
            item.children = children;
          }
          result.push(item);
        }
      });
      return result;
    },
    getZones() {
      axios.get("/zones").then((response) => {
        if (response.status == 200) {
          let parents = [];
          this.zones = response.data.data;
          this.zones.forEach((el) => {
            if (!el.name) el.name = "empty";
            this.zons[el.id] = el.name;
            parents[el.id] = el.parent_id;
          });
          this.tree = this.getTree(this.zones, null);
          this.zoneActive = {
            id: null,
            parent_id: null,
            name: this.$ml.get("devices.all_zones"),
            data: null,
          };

          this.tree.splice(0, 0, this.zoneActive);
          this.devices.pagination.current_page = 1;
        }
      });
    },
    setActiveStyle() {
      let e = $(".v-treeview-node__root a.active");
      if (e !== null) {
        $(".v-treeview-node__root").css("background-color", "#fff");
        let el = $(e).closest(".v-treeview-node__root");
        el.css("background-color", "#e2e2e2");
      }
    },
    setTreeActiveClass(item) {
      if (item.id == this.zoneActive.id) {
        return "active";
      }
    },
  },
  watch: {
    zoneActive(item) {
      console.log(item);
    },
    stat(val) {
      this.stat = val;
      console.log("status:", this.stat);
    },
  },
  mounted() {
    this.getZones();
    this.getAllDevices();
    this.getDevices();
    let addr = localStorage.getItem("terminal.devices.addr") || "[]";
    this.addresses = JSON.parse(addr);
    let self = this;
    this.$root.$off("syncdevice");
    this.$root.$on("syncdevice", function (item) {
      self.syncdevice(item);
    });
    this.$root.$off("loadfeatures");
    this.$root.$on("loadfeatures", function (item) {
      self.loadfeatures(item);
    });
  },
  updated() {
    this.setActiveStyle();
  },
};
</script>
<style scoped>
button.hidden {
  display: none !important;
}
.top-button-box {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
}
.column {
  float: left;
}
.panel {
  height: 46px;
}
.theme--light.v-application {
  background: none;
}
.btn.btn-primary {
  color: #fff;
}
</style>
<style>
.scaner .v-chip {
  margin: 2px;
}
/* .v-treeview-node__root {
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
} */
.v-treeview-node__root a {
  cursor: pointer;
  user-select: none;
}
.user_num {
  background: url("~@/assets/img/user.svg");
  width: 16px !important;
  height: 16px;
  display: block;
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
