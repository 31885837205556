<template>
  <v-app>
    <div class="row">
      <div class="col-md-4" style="float: left; min-width: 200px">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a
              :class="[
                'nav-link',
                { active: leftSideBarTabSelected === 'subdivisions' },
              ]"
              href="#subdivisions"
              data-toggle="tab"
              @click="leftSideBarTabSelected = 'subdivisions'"
              >{{ $ml.get("users.subdivision") }}</a
            >
          </li>
          <li class="nav-item">
            <a
              :class="[
                'nav-link',
                { active: leftSideBarTabSelected === 'departments' },
              ]"
              href="#departments"
              data-toggle="tab"
              @click="leftSideBarTabSelected = 'departments'"
              >{{ $ml.get("users.departments") }}</a
            >
          </li>
        </ul>

        <div class="tab-content">
          <div
            :class="[
              'tab-pane',
              'add-new-visitor-form-container',
              { active: leftSideBarTabSelected === 'subdivisions' },
            ]"
            id="subdivisions"
          >
            <v-card class="mx-auto">
              <v-list v-if="treeOpen">
                <v-treeview open-all hoverable :items="tree" return-object>
                  <template
                    slot="label"
                    item-key="id"
                    item-text="name"
                    slot-scope="{ item }"
                  >
                    <a
                      :class="'treeitem ' + setTreeActiveClass(item)"
                      @click="selectSdv(item, $event)"
                      style="height: 100%; width: 100%; display: block"
                      >{{ item.name }}</a
                    >
                  </template>
                </v-treeview>
              </v-list>
            </v-card>
          </div>
          <div
            :class="[
              'tab-pane',
              'add-new-visitor-form-container',
              { active: leftSideBarTabSelected === 'departments' },
            ]"
            id="departments"
          >
            <v-card class="mx-auto">
              <v-list>
                <v-treeview
                  return-object
                  open-all
                  hoverable
                  activatable
                  :items="departmentsTree"
                  @update:active="onSelectDepartment"
                />
              </v-list>
            </v-card>
          </div>
        </div>
      </div>
      <div class="viewlog col-md-8">
        <datatable
          :fields="fields"
          :rows="visitors.data"
          :searchfn="searchfn"
          :click="showUser"
          :rowstyle="styleRow"
        >
          {{ $ml.get("advanced.nopass") }}
          <template v-slot:header>
            <div class="select-block">
              <dtPicker v-model="datetime" />
              <div
                class="no-label document-filter select-report"
                style="float: left"
                @mouseover="selOpen"
                @mouseout="selClose"
              >
                <label class="has-float-label">
                  <div class="select-value-container">
                    {{ $ml.get("advanced.select_report") }}
                  </div>
                  <span
                    ref="stick"
                    class="select-tick select-tick-closed"
                  ></span>
                </label>

                <div ref="scontainer" class="select-container">
                  <ul>
                    <li v-for="rep in reports" :key="rep.val" class="top">
                      <a
                        href="#"
                        :name="rep.val + rep.filter"
                        @click="selReport(rep)"
                        :title="rep.name"
                        >{{ rep.name }}</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:footer>
            <vue-pagination
              :pagination="visitors.pagination"
              @paginate="getVisitLog()"
              :offset="offset"
            ></vue-pagination>
          </template>
        </datatable>
      </div>
    </div>
  </v-app>
</template>

<script>
import { eventEmmitter } from "../main.js";
import Datatable from "../components/datatable";
import VuePagination from "../components/pagination";
import "daterangepicker/daterangepicker";
import "daterangepicker/daterangepicker.css";
//import DateRangePicker from 'vue2-daterange-picker'
import dtPicker from "../components/datetimerangepicker";
//import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import axios from "axios";
import moment from "moment-timezone";

export default {
  name: "ViewLog",
  data() {
    return {
      errorDateFormat: "",
      treeOpen: false,
      fields: [
        { name: "avatar", title: "", style: "width: 50px;", type: "img" },
        {
          group: [
            {
              name: "full_name",
              title: this.$ml.get("users.fullname"),
              btag: "",
              atag: "",
            },
            {
              name: "company",
              title: this.$ml.get("user.organization"),
              style: "font-size: 10px; float: left; margin-right: 3px;",
            },
            {
              name: "utype",
              title: this.$ml.get("users.status"),
              btag: " /",
              style: "font-size: 10px; float: left;",
            },
            {
              name: "temperature",
              title: this.$ml.get("users.temperature"),
              btag: "",
              style: "font-size: 10px; float: left; margin-left: 3px;",
            },
          ],
          header: { text: this.$ml.get("users.visitor") },
        },
        {
          group: [
            {
              name: "purpose",
              title: this.$ml.get("devices.purpose"),
            },
            {
              name: "zone",
              title: this.$ml.get("devices.zoneDevice"),
              style: "font-size: 10px;",
            },
          ],
          header: { text: this.$ml.get("devices.purpose") },
          style: "width: 120px;",
        },
        {
          name: "visits",
          header: { text: this.$ml.get("users.visit_time") },
          style: "width: 50px;",
        },
      ],
      reports: [
        {
          name: this.$ml.get("advanced.worktime"),
          val: "worktime",
          filter: "",
        },
        {
          name: this.$ml.get("advanced.iotime"),
          val: "iotime",
          filter: "",
        },
        { name: this.$ml.get("advanced.passed"), val: "passed", filter: "" },
        {
          name: this.$ml.get("advanced.passedTemp"),
          val: "passed",
          filter: "temp",
        },
      ],
      datetime: [new Date(moment().year(), moment().month(), 1), new Date()],
      startDate: moment().format("YYYY-MM-01 00:00:00"),
      endDate: moment().format("YYYY-MM-DD 23:59:59"),
      visitors: {
        pagination: {
          total: 0,
          per_page: 2,
          from: 1,
          to: 0,
          current_page: 1,
        },
        data: [],
      },
      leftSideBarTabSelected: "subdivisions",
      selectedDepartment: null,
      sdvActive: {},
      tree: [],
      sdv: [],
      subdivisions: [],
      departments: [],
      treeSelect: [],
      offset: 4,
      search: "",
      l: { lang: "en", show: false, temp_meas: "c" },
    };
  },
  components: { Datatable, VuePagination, dtPicker },
  methods: {
    setTreeActiveClass(item) {
      //console.log(item.id, this.sdvActive.id);
      if (item.id == this.sdvActive.id || this.treeSelect[item.id]) {
        return "active";
      }
    },
    selectSdv(item, e) {
      this.sdvActive = item;
      if (e.shiftKey) {
        if (!this.treeSelect[item.id]) {
          this.treeSelect[item.id] = true;
        }
        return;
      } else {
        this.treeSelect = [];
      }
      this.visitors.pagination.current_page = 1;
      //this.companyActive = item;
      if (!this.treeSelect[item.id]) {
        this.treeSelect[item.id] = true;
      }
      localStorage.setItem("terminal.viewlog.subdiv", JSON.stringify(item));
      this.getVisitLog();
    },
    onSelectDepartment([department]) {
      this.selectedDepartment = department;
      console.log(this.selectedDepartment);
      this.getVisitLog();
    },
    getTree(items, parent) {
      let result = [];
      items.forEach((el) => {
        if (parent == el.parent_id) {
          let item = {
            id: el.id,
            name: el.name,
            parent_id: el.parent_id,
            zones_id: el.zones_id,
            access: el.access,
          };
          let children = this.getTree(items, el.id);
          if (children) {
            item.children = children;
          }
          result.push(item);
        }
      });
      return result;
    },
    getSubdivisions() {
      axios.get("/subdivisions").then((response) => {
        if (response.status == 200) {
          let parents = [];
          this.subdivisions = response.data.data;
          this.subdivisions.forEach((el) => {
            el.__select = false;
            this.sdv[el.id] = el.name;
            parents[el.id] = el.parent_id;
          });
          this.tree = this.getTree(this.subdivisions, null);
          this.sdvActive = {
            id: null,
            parent_id: null,
            name: this.$ml.get("users.all_subdivisions"),
          };
          this.tree.splice(0, 0, this.sdvActive);
          let subdiv = localStorage.getItem("terminal.viewlog.subdiv");
          if (subdiv) this.sdvActive = JSON.parse(subdiv);
          let npage = localStorage.getItem("terminal.viewlog.current_page");
          if (npage == null) npage = 1;
          this.visitors.pagination.current_page = parseInt(npage);
          this.treeOpen = true;
          this.getVisitLog();
        }
      });
    },
    getDepartments() {
      axios("/departments").then((response) => {
        this.departments = response.data.data;
      });
    },

    selOpen() {
      $(this.$refs.stick).prop("class", "select-tick select-tick-open");
      $(this.$refs.scontainer).show();
      $(this.$refs.scontainer).focus();
    },
    selClose() {
      $(this.$refs.stick).prop("class", "select-tick select-tick-closed");
      $(this.$refs.scontainer).hide();
    },
    selReport(item) {
      //this.report = item;
      $(this.$refs.stick).prop("class", "select-tick select-tick-closed");
      //.addClass("select-tick-open");
      $(this.$refs.scontainer).hide();
      $(this.$refs.stick).focus();
      // let startDate = moment(this.datetime[0]).format("YYYY-MM-DD HH:mm")
      // let endDate = moment(this.datetime[1]).format("YYYY-MM-DD HH:mm")

      let params = {
        startDate: this.startDate,
        endDate: this.endDate,
        search: this.search,
        page: this.visitors.current_page,
        lang: this.l.lang,
        istemp: false,
        subdivision: this.getSubvisionsIds(),
      };
      if (item.filter == "temp") {
        params.istemp = true;
      }

      let requestParams = {
        method: "get",
        params,
        url: "/report/" + item["val"],
        responseType: "blob",
      };

      axios.request(requestParams).then((response) => {
        if (response.status == 200) {
          let filename =
            item["val"] + "-" + moment().format("YYYYMMDDHHmmss") + ".xlsx";
          let url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: "application/xlsx",
            })
          );
          let link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
          link.remove();
        } else {
          this.$dialog
            .alert(this.$ml.get("advanced.nopass"), {
              loader: false,
              okText: "OK",
              backdropClose: true,
            })
            .then(function (dialog) {});
        }
      });
    },
    styleRow(item) {
      if (item.blacklisted) {
        return "background: #000; color: #fff;";
      }
    },
    showUser(item) {
      console.log(item);
      this.$router.push("/user/" + item.user_id);
    },
    daterange(ev) {
      console.log(ev.target);
      $(ev.target).daterangepicker({});
    },

    download() {
      //download?startDate={{$startDate->format('d/m/Y')}}&endDate={{$endDate->format('d/m/Y')}}
    },
    searchfn(value) {
      this.search = value;
      this.visitors.current_page = 1;
      this.getVisitLog();
    },
    getVisitLog() {
      let params = {
        startDate: this.startDate,
        endDate: this.endDate,
        search: this.search,
        page: this.visitors.pagination.current_page,
        subdivision: this.getSubvisionsIds(),
        department: this.getDepartmentIds(),
      };
      if (this.leftSideBarTabSelected != "subdivisions") {
        params.subdivision = null;
      } else {
        params.department = null;
      }
      axios.get("/visit/log", { params }).then((response) => {
        this.visitors.data = response.data.data ? response.data.data : [];
        this.visitors.pagination = response.data.meta.pagination || {};
        this.settings = response.data.settings || {};
        if (this.settings.temperature_range != undefined) {
          let range = this.settings.temperature_range.split(",");
          if (range.length > 1) this.tAlarm = range[1];
        }
        this.visitors.data.forEach((item) => {
          this.correctVisits(item);
        });
      });
    },
    getParents(id, arrays = []) {
      let out = [];
      if (arrays) {
        arrays.forEach((item) => {
          if (item.parent_id == id) {
            out.push(item.id);
            let ids = this.getParents(item.id, arrays);
            if (ids.length) out.push(ids);
          }
        });
      }
      return out;
    },
    getDepartmentIds() {
      if (!this.selectedDepartment) return;
      let out = [];
      out.push(this.selectedDepartment.id);
      let ids = this.getParents(this.selectedDepartment.id, this.departments);
      if (ids.length) out.push(ids);
      if (out.length) {
        return out.join(",");
      }
    },

    getSubvisionsIds() {
      if (!this.sdvActive.id) return;
      let out = [];
      out.push(this.sdvActive.id);
      let ids = this.getParents(this.sdvActive.id, this.subdivisions);
      if (ids.length) out.push(ids);
      if (out.length) {
        return out.join(",");
      }
    },
    // getParents(id) {
    //   if (this.subdivisions) {
    //     this.subdivisions.forEach((item) => {
    //       if (item.parent_id == id) {
    //         this.sdivSelect.push(item.id);
    //         this.getParents(item.id);
    //       }
    //     });
    //   }
    // },
    // getSubvisionsIds() {
    //   this.sdivSelect = [];
    //   if (!this.sdvActive.id) return;
    //   this.sdivSelect.push(this.sdvActive.id);
    //   this.getParents(this.sdvActive.id);
    //   if (this.sdivSelect.length) {
    //     return this.sdivSelect.join(",");
    //   }
    // },
    calculateCalcFeel(t) {
      if (this.l.temp_meas == "f") {
        return Math.round(((t * 9) / 5 + 32) * 10) / 10;
      } else {
        return t;
      }
    },

    correctVisits(item) {
      //.tz(String(item.CreatedAt), "Europe/Moscow")
      item.visits = moment(String(item.created_at)).format(
        "DD.MM.YYYY HH:mm:ss"
      );

      if (!item.full_name) {
        item.full_name = this.$ml.get("users.guest");
      }
      if (!item.type_user) {
        item.type_user = "guest";
      }
      item.company = "";
      let cmpname = [];
      if (item.organization) {
        item.organization.forEach((el) => {
          cmpname.push(el.name);
        });
      }
      item.company = cmpname.join(", ");
      item.zone = "";
      let data = {};
      if (item.data) {
        data = JSON.parse(item.data);
        item.zone = data.zone || "";
      }
      if (parseFloat(data.temperature) && !this.l.temperature_rise) {
        item.temperatureValue = data.temperature;

        item.temperature =
          "/ " +
          this.calculateCalcFeel(data.temperature) +
          "°" +
          this.l.temp_meas.toUpperCase();
      }

      if (data.image_encode) {
        item.photo = "data:image/png;base64," + data.image_encode;
      } else {
        item.photo = item.photo ? item.photo : "avatar.png";
        item.avatar = "/avatar/small/" + item.photo;
        item.photo = "/avatar/profile/" + item.photo;
      }
      item.utype = this.$ml.get("users." + item.type_user);
      if (!item.purpose) item.purpose = "exit";
      item.purpose = this.$ml.get("device.purpose_" + item.purpose.trim());
    },
    setActiveStyle() {
      let e = $(".v-treeview-node__root a.active");
      if (e !== null) {
        $(".v-treeview-node__root").css("background-color", "#fff");
        let el = $(e).closest(".v-treeview-node__root");
        el.css("background-color", "#e2e2e2");
      }
    },
  },
  computed: {
    departmentsTree() {
      const getTree = (items, parent = null) => {
        let result = [];
        items.forEach((el) => {
          if (parent == el.parent_id) {
            let item = {
              id: el.id,
              name: el.name,
              parentId: el.parent_id,
            };
            let children = getTree(items, el.id);
            if (children) {
              item.children = children;
            }
            result.push(item);
          }
        });
        return result;
      };

      return getTree(this.departments);
    },
  },
  mounted() {
    localStorage.setItem("terminal.target.page", "/journal");
    if (localStorage.getItem("ovision-ml")) {
      try {
        this.l = JSON.parse(localStorage.getItem("ovision-ml"));
      } catch (err) {
        console.error(err);
      }
    }
    //this.getVisitLog();
    this.getSubdivisions();
    this.getDepartments();

    //$('input[name="dates"]').daterangepicker();
  },
  updated() {
    let _this = this;
    this.setActiveStyle();
  },
  watch: {
    datetime(val) {
      this.startDate = moment(val[0]).format("YYYY-MM-DD HH:mm:00");
      this.endDate = moment(val[1]).format("YYYY-MM-DD HH:mm:59");
      this.getVisitLog();
    },
  },
};
</script>

<style>
thead tr th {
  background: #e2e2e2 !important;
}
.daterangepicker .calendar-table th,
.daterangepicker .calendar-table td {
  border-radius: 0 !important;
}
</style>

<style scoped>
.block-filter {
  width: 172px;
  flex: 0 0 172px;
  margin-right: 10px;
}

.select-container {
  width: 261px;
  background: #ffffff;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  position: absolute;
  z-index: 1;
  top: 54px;
  right: 0;
  margin: 0 auto;
  left: 15px;
  display: none;
}
.select-container ul li.top {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.v-treeview-node__root a {
  cursor: pointer;
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}
</style>
