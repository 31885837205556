<template>
  <v-app class="d-flex add-new-visitor-form-container">
    <div class="container add-new-visitor-form col-sm-12">
      <notifications group="info" position="top right" />
      <div class="flash-message" style="display: none"></div>
      <div class="row">
        <div class="column col-sm-4 image-box">
          <ImageBox
            v-on:toggle="togglePhoto($event)"
            :devices="devices"
            :photo="photo"
            :newphoto="newphoto"
            :feature="feature"
          />
          <CardsAccess v-model="cards" @result="cards = $event" />
        </div>
        <div class="column form-column col-sm-8">
          <div v-if="errorMessage" class="alert alert-danger" role="alert">
            <router-link v-if="messageClick" :to="messageClick">{{
              errorMessage
            }}</router-link>
            <span v-else>
              {{ errorMessage }}
            </span>
          </div>
          <div v-if="photoErrorMessage" class="alert alert-danger" role="alert">
            {{ photoErrorMessage }}
          </div>
          <v-tabs v-model="tab">
            <v-tab>{{ $ml.get("user.basicData") }}</v-tab>
            <v-tab v-if="typeUser == 'manager' && isAdmin()">
              {{ $ml.get("user.accessControl") }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" style="overflow: initial">
            <v-tab-item :transition="false" :reverse-transition="false">
              <div style="padding-top: 15px">
                <v-alert v-if="isTwin" type="info" class="mb-5">{{
                  $ml.get("user.isTwin")
                }}</v-alert>

                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      name="fullName"
                      v-model="full_name"
                      :rules="nameRules"
                      :label="`${$ml.get('user.fullName')} *`"
                      outlined
                      required
                    ></v-text-field>
                    <v-text-field
                      v-model="phone"
                      :label="phoneLabel"
                      :required="phoneRequired"
                      :rules="phoneRules"
                      outlined
                    ></v-text-field>

                    <treeselect
                      v-model="department"
                      :multiple="false"
                      :options="departments"
                      :placeholder="$ml.get('user.departments')"
                      :searchable="true"
                      :show-count="true"
                      class="mb-5"
                    />

                    <AccessList
                      :items="companies"
                      :values="accesslist"
                      @values="accesslist = $event"
                      class="mb-5"
                    />
                  </v-col>
                </v-row>
                <h6>{{ $ml.get("user.status") }}:</h6>
                <div class="clearfix radio-groups-container">
                  <div v-for="(type, i) in types" :key="i" class="radio-group">
                    <input
                      v-if="
                        type.name != 'manager' ||
                        typeUser == 'manager' ||
                        isAdmin()
                      "
                      name="typeUser"
                      type="radio"
                      :disabled="typeUser == 'manager' && !isAdmin()"
                      :id="type.name"
                      :value="type.name"
                      v-model="typeUser"
                    />
                    <label
                      :for="type.name"
                      v-if="
                        type.name != 'manager' ||
                        typeUser == 'manager' ||
                        isAdmin()
                      "
                      >{{ type.title }}</label
                    >
                  </div>
                </div>

                <!-- Настраиваем вывод правил для конкретного типа -->

                <v-text-field
                  v-if="typeUser == 'guest'"
                  name="purpose"
                  v-model="visit_purpose"
                  :label="$ml.get('user.visit_purpose')"
                  outlined
                ></v-text-field>
                <v-text-field
                  v-else
                  v-model="post"
                  name="post"
                  :label="$ml.get('user.post')"
                  outlined
                ></v-text-field>

                <template v-if="typeUser == 'guest'">
                  <h6>{{ $ml.get("user.type_of_pass") }}:</h6>
                  <div class="clearfix radio-groups-container">
                    <div
                      v-for="(type, i) in passtypes"
                      :key="i"
                      class="radio-group"
                    >
                      <input
                        name="onepass"
                        type="radio"
                        :id="type.name"
                        :value="type.val"
                        v-model="onepass"
                      />
                      <label :for="type.name">{{ type.title }}</label>
                    </div>
                  </div>

                  <div style="max-width: 500px">
                    <v-row class="mb-5">
                      <v-col cols="5">
                        <v-menu
                          v-model="dateExpireMenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="dateExpire"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              outlined
                            />
                          </template>
                          <v-date-picker v-model="dateExpire" />
                        </v-menu>
                      </v-col>

                      <v-col cols="4">
                        <div
                          class="
                            v-input v-input--is-disabled v-input--is-readonly
                            theme--light
                            v-text-field
                            v-text-field--is-booted
                            v-text-field--enclosed
                            v-text-field--outlined
                            v-text-field--placeholder
                          "
                        >
                          <div class="v-input__control">
                            <div class="v-input__slot">
                              <fieldset
                                aria-hidden="true"
                                style="margin-top: 5px"
                              >
                                <legend style="width: 0px"></legend>
                              </fieldset>

                              <div class="v-text-field__slot">
                                <input
                                  type="time"
                                  v-model="timeExpire"
                                  :disabled="!dateExpire"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </template>

                <h6>{{ $ml.get("user.document") }}:</h6>

                <div class="clearfix radio-groups-container">
                  <div class="radio-group">
                    <input
                      name="passport"
                      type="radio"
                      id="passport"
                      value="passport"
                      v-model="document_type"
                      checked
                    />
                    <label for="passport">{{ $ml.get("user.passport") }}</label>
                  </div>
                  <div class="radio-group">
                    <input
                      name="driverLicense"
                      type="radio"
                      id="driver_license"
                      value="license"
                      v-model="document_type"
                    />
                    <label for="driver_license">{{
                      $ml.get("user.drivers_license")
                    }}</label>
                  </div>
                </div>

                <v-text-field
                  v-if="document_type"
                  name="documentNumber"
                  v-model="document_number"
                  :label="$ml.get('user.document_number')"
                  outlined
                ></v-text-field>
              </div>
            </v-tab-item>

            <v-tab-item v-if="typeUser == 'manager' && isAdmin()">
              <div style="margin-top: 15px; margin-bottom: 25px">
                <h6>{{ $ml.get("auth.cabinetLogin") }}</h6>
                <template v-if="!id || isAuth">
                  <div style="max-width: 300px">
                    <v-text-field
                      :disabled="permission"
                      v-model="username"
                      :rules="emailRules"
                      :label="`${$ml.get('auth.email')} *`"
                      outlined
                      :readonly="id > 0"
                      autocomplete="off"
                      required
                    />

                    <v-row v-show="!isChangePass">
                      <v-col>
                        <v-btn @click="isChangePass = true">
                          {{ $ml.get("guard.changeAuth") }}
                        </v-btn>
                      </v-col>
                    </v-row>

                    <template v-if="isChangePass">
                      <v-text-field
                        v-model="password"
                        :rules="passwordRules"
                        :label="`${$ml.get('auth.password')} *`"
                        type="password"
                        outlined
                        autocomplete="off"
                        required
                      />
                      <v-text-field
                        v-if="password"
                        ref="passwordConfirmation"
                        class="password-guard"
                        v-model="password_confirmation"
                        :rules="passwordConfirmRules"
                        :label="`${$ml.get('auth.passwordConfirm')} *`"
                        type="password"
                        outlined
                        autocomplete="off"
                        required
                      />
                    </template>
                  </div>
                </template>
                <button
                  v-if="!isAuth && id"
                  class="btn btn-primary change-password-btn"
                  @click="isAuth = true"
                >
                  {{ $ml.get("guard.changeAuth") }}
                </button>
                <hr />
                <h4>{{ $ml.get("user.groups_management") }}</h4>
                <h6>{{ $ml.get("user.departments") }}:</h6>
                <treeselect
                  v-model="departmentperm"
                  :multiple="true"
                  :options="departmentList"
                  :placeholder="$ml.get('user.departments')"
                  :disable-branch-nodes="true"
                  :searchable="true"
                  :show-count="true"
                />
                <h6 style="margin-top: 20px">
                  {{ $ml.get("user.groupaccess") }}:
                </h6>
                <treeselect
                  v-model="subdivisionperm"
                  :multiple="true"
                  :options="accessGroupList"
                  :placeholder="$ml.get('user.groupaccess')"
                  :disable-branch-nodes="true"
                  :searchable="true"
                  :show-count="true"
                />
              </div>
              <h4>{{ $ml.get("user.rules") }}:</h4>
              <v-switch
                v-model="requestСonfirmRule"
                :label="$ml.get('user.request_confirm')"
              ></v-switch>
              <v-switch
                v-model="editUsersRule"
                :label="$ml.get('user.edit_users')"
              ></v-switch>
              <v-switch
                v-model="editDepartmentRule"
                :label="$ml.get('user.edit_department')"
              ></v-switch>
            </v-tab-item>
          </v-tabs-items>

          <v-checkbox
            v-model="dataProcessing"
            :label="$ml.get('message.processingData')"
            class="mt-0"
          />

          <v-alert
            v-if="validFormMessage"
            type="error"
            v-html="validFormMessage"
          />

          <v-row justify="end">
            <v-col cols="auto">
              <v-btn @click="goCancel">
                {{ $ml.get("button.back") }}
              </v-btn>
            </v-col>
            <v-spacer />
            <template v-if="userId">
              <v-col v-if="deleted && isDelUser()" cols="auto">
                <v-btn color="success" @click.prevent="restoreUser">
                  {{ $ml.get("button.restore") }}
                </v-btn>
              </v-col>
              <v-col v-if="deleted && isDelUser()" cols="auto">
                <v-btn color="error" @click.prevent="clearUser">
                  {{ $ml.get("button.clear") }}
                </v-btn>
              </v-col>
              <v-col
                v-if="!deleted && typeUser != '' && isDelUser()"
                cols="auto"
              >
                <v-btn color="error" @click.prevent="deleteUser">
                  {{ $ml.get("button.delete") }}
                </v-btn>
              </v-col>
            </template>
            <v-col cols="auto">
              <v-btn
                color="primary"
                @click.prevent="onClickSavebtn"
                :disabled="lookBtn"
              >
                {{ $ml.get("button.save") }}
              </v-btn>
            </v-col>
            <!-- <button
            v-if="!deleted && userId &&
              (isEditUser() || isAddUser())
              "
            class="green-transparent-link-button refeature"
            style="margin-left: 15px;"
            type="button"
            @click.prevent="refeature"
          >
            {{ $ml.get("button.refeature") }}
          </button> -->
          </v-row>
        </div>
        <span class="invalid-feedback" role="alert" id="image-error"></span>
      </div>
    </div>
    <div class="filler"></div>
  </v-app>
</template>

<script>
import ImageBox from "../../components/ImageBox";
import CardsAccess from "../../components/CardsAccess";
import AccessList from "../../components/AccessList";
import Treeselect from "@riophae/vue-treeselect";

import axios from "axios";
import vuetify from "../../plugins/vuetify.js";
import router from "../../router";
const dateFormat = "YYYY-MM-DD";
const timeFormat = "HH:mm";

export default {
  props: {
    userId: {
      default: 0,
    },
  },
  name: "UserEdit",
  components: { ImageBox, CardsAccess, AccessList, Treeselect },
  vuetify,

  data() {
    return {
      types: [
        { name: "guest", title: this.$ml.get("users.guest") },
        { name: "employee", title: this.$ml.get("users.employee") },
        { name: "manager", title: this.$ml.get("users.manager") },
      ],
      passtypes: [
        { name: "pass_one", val: true, title: this.$ml.get("user.pass_one") },
        { name: "pass_all", val: false, title: this.$ml.get("user.pass_all") },
      ],
      tab: 0,
      cards: [],
      deleted: false,
      typeUser: "employee",
      newphoto: "",
      feature: "",
      photo: "",
      phone: "",
      post: "",
      dateExpire: "",
      dateExpireMenu: false,
      timeExpire: "",
      full_name: "",
      visit_purpose: "",
      document_type: "",
      document_number: "",
      company: [],
      blocked: false,
      blacklisted: false,
      companies: [],
      departments: [],
      departmentperm: null,
      subdivisionperm: null,
      department: null,
      devices: [],
      dataProcessing: this.userId > 0,
      requestСonfirmRule: false,
      editUsersRule: false,
      editDepartmentRule: false,
      nameRules: [
        (v) => !!v || this.$ml.get("message.NameIsRequired"),
        //v => v.length <= 10 || 'Name must be less than 10 characters',
      ],
      emailRules: [
        (v) => this.validateEmail(v) || this.$ml.get("user.email_char_valid"),
      ],
      usernameRules: [
        (v) =>
          this.validateEmail(v) || this.$ml.get("auth.username_char_valid"),
        (v) => v.length >= 6 || this.$ml.get("auth.lenghtn_username_8"),

        //v => v.length <= 10 || 'Name must be less than 10 characters',
      ],
      passwordRules: [
        (v) => v.length >= 6 || this.$ml.get("auth.lenghtn_password"),
      ],
      passwordConfirmRules: [
        (v) =>
          !!v ||
          this.$ml.get("auth.password") +
            " " +
            this.$ml.get("message.IsRequired"),
        (v) => v == this.password || this.$ml.get("auth.passwords_must_match"),
      ],
      errorMessage: "",
      photoErrorMessage: "",
      twin_id: 0,
      isTwin: false,
      onepass: false,
      lookBtn: false,
      accesslist: [],
      messageClick: "",
      NoCheckPhoto: true,
      password_confirmation: "",
      username: "",
      password: "",
      permission: null,
      isChangePass: true,
      validFormMessage: "",
    };
  },
  computed: {
    cardsAccess() {
      return this.cards;
    },
    departmentList() {
      let result = [];
      this.departments.forEach((d) => {
        if (d.parent_id == null) {
          result.push({
            id: d.id,
            label: d.label,
          });
        }
      });
      return result;
    },
    accessGroupList() {
      let result = [];
      this.companies.forEach((d) => {
        //if (d.parent_id == null) {
        result.push({
          id: d.id,
          label: d.label,
        });

        //}
      });
      return result;
    },

    phoneLabel() {
      let label = this.$ml.get("user.phone");

      if (this.typeUser === "manager") {
        label += " *";
      }

      return label;
    },

    phoneRequired() {
      let required = false;

      if (this.typeUser === "manager") {
        required = true;
      }

      return required;
    },

    phoneRules() {
      let rules = [];

      if (this.typeUser === "manager") {
        rules = [(v) => !!v || this.$ml.get("message.NameIsRequired")];
      }

      return rules;
    },
  },
  methods: {
    validateEmail(v) {
      var re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;
      return re.test(v);
    },
    validUserName(v) {
      var re = /^[a-zA-Z0-9\\-]+$/;
      return re.test(v);
    },
    validUserConfirmPassword(v) {
      return (
        v == this.password_confirmation || this.password_confirmation == ""
      );
    },
    validatePermission() {
      if (
        this.typeUser != "manager" ||
        (this.typeUser == "manager" &&
          this.permission &&
          (!this.password ||
            (this.password && this.password == this.password_confirmation))) ||
        (this.typeUser == "manager" &&
          !this.permission &&
          this.username &&
          this.password &&
          this.password == this.password_confirmation)
      )
        return true;
    },
    goCancel() {
      if (this.userId) {
        router.push({ path: `/user/${this.userId}` });
      } else {
        let linkBack = "/users";
        if (localStorage.getItem("terminal.target.page") !== null) {
          linkBack = localStorage.getItem("terminal.target.page");
        }
        router.push({ path: linkBack });
      }

      this.$notify({
        group: "info",
        type: "success",
        text: this.$ml.get("message.userBlockSuccess"),
      });
    },
    togglePhoto(val) {
      this.photoErrorMessage = "";
      this.newphoto = val;

      axios
        .post("/validate/face", {
          photo_encrypted: val,
        })
        .catch((error) => {
          this.photoErrorMessage = this.getLangMessage(
            error.response.data.message || error.response.data.status.message
          );
        });
    },
    getCompanies() {
      axios.get("/subdivisions/tree").then((response) => {
        if (response.status == 200) {
          this.companies = response.data.data ? response.data.data : [];
        }
      });
    },
    getDepartments() {
      axios.get("/departments/tree").then((response) => {
        if (response.status == 200) {
          this.departments = response.data.data ? response.data.data : [];
        }
      });
    },
    getDevices() {
      axios.get("/devices").then((response) => {
        this.devices = response.data ? response.data : [];
      });
    },
    restoreUser() {
      axios
        .put("/user/restore", { ids: [parseInt(this.userId)] })
        .then((response) => {
          if (response.status == 200) {
            this.deleted = false;
          }
        });
    },
    clearUser() {
      if (this.userId) {
        let _this = this;
        this.$dialog
          .confirm(
            `<h3>${this.$ml.get("message.Delete")}</h3>` +
              this.$ml.get("message.confirmDelete"),
            {
              loader: false,
              okText: this.$ml.get("button.delete"),
              cancelText: this.$ml.get("button.cancel"),
              backdropClose: true,
            }
          )
          .then(() => {
            axios
              .put("/user/clear", { ids: [parseInt(_this.userId)] })
              .then((response) => {
                if (response.status == 200) {
                  router.push({ path: `/users` });

                  this.$notify({
                    group: "info",
                    type: "success",
                    text: this.$ml.get("message.userClearSuccess"),
                  });
                }
              });
          });
      }
    },
    deleteUser() {
      if (this.userId) {
        let _this = this;
        this.$dialog
          .confirm(
            `<h3>${this.$ml.get("message.Delete")}</h3>` +
              this.$ml.get("message.confirmDelete"),
            {
              loader: false,
              okText: this.$ml.get("button.delete"),
              cancelText: this.$ml.get("button.cancel"),
              backdropClose: true,
            }
          )
          .then(() => {
            axios
              .put("/user/delete", { ids: [parseInt(_this.userId)] })
              .then((response) => {
                if (response.status == 200) {
                  _this.deleted = true;

                  this.$notify({
                    group: "info",
                    type: "success",
                    text: this.$ml.get("message.userDeleteSuccess"),
                  });
                }
              });
          });
      }
    },
    isAdmin() {
      return this.$auth.user().type_user == "admin";
    },
    isAddUser() {
      let roles = this.$auth.user().roles;
      return (
        this.$auth.user().type_user == "admin" ||
        (roles != null && roles.includes("useradd") && this.id) ||
        (roles != null && roles.includes("useredit")) ||
        (roles != null && roles.includes("userdel"))
      );
    },
    isEditUser() {
      let roles = this.$auth.user().roles;
      return (
        this.$auth.user().type_user == "admin" ||
        (roles != null && roles.includes("useredit")) ||
        (roles != null && roles.includes("userdel"))
      );
    },
    isDelUser() {
      let roles = this.$auth.user().roles;
      return (
        this.$auth.user().type_user == "admin" ||
        (roles != null &&
          roles.includes("userdel") &&
          this.typeUser != "manager")
      );
    },
    getuser() {
      if (this.userId) {
        axios.get("/user/" + this.userId).then((response) => {
          if (response.status == 200) {
            let item = response.data.data;
            this.full_name = (
              item.last_name +
              " " +
              item.first_name +
              " " +
              item.middle_name
            ).trim();
            this.permission = item.permission || null;
            this.isChangePass = item.permission ? false : true;
            this.cards = item.cards;
            this.typeUser = item.type_user;
            this.photo = item.photo;
            this.phone = item.phone;
            this.blocked = item.blocked;
            this.blacklisted = item.blacklisted;
            this.isTwin = item.is_twin;
            if (item.department) this.department = item.department.id || 0;

            if (this.permission) {
              this.username = this.permission.username;
              this.subdivisionperm = this.permission.groupaccess;
              this.departmentperm = this.permission.departments;
              if (this.permission.rules) {
                this.requestСonfirmRule =
                  this.permission.rules.request_confirm || false;
                this.editUsersRule = this.permission.rules.edit_users || false;
                this.editDepartmentRule =
                  this.permission.rules.edit_department || false;
              }
            }

            this.company = [];
            let self = this;
            if (item.access_groups.length) {
              this.accesslist = item.access_groups;
              item.access_groups.forEach((it) => {
                self.company.push(it.id);
              });
            }
            this.deleted = item.deleted;
            let data = {};
            if (item.data) {
              data = JSON.parse(item.data);
              this.visit_purpose = data.visit_purpose || "";
              this.document_type = data.document_type || "";
              this.document_number = data.document_number || "";
              if (this.document_number == "") {
                this.document_type = "";
              }
              if (data.expire_date) {
                this.dateExpire = this.$moment(data.expire_date).format(
                  dateFormat
                );
                this.timeExpire = this.$moment(data.expire_date).format(
                  timeFormat
                );
              }
              this.post = data.post || "";
              this.onepass = data.onepass || false;
            }
          }
          //this.full_name = response
        });
      }
    },
    onClickSavebtn() {
      if (!this.validateForm()) return;

      if (this.photoErrorMessage) {
        this.$dialog
          .confirm(this.$ml.get("message.notValidateFace"), {
            loader: false,
            okText: this.$ml.get("button.save"),
            cancelText: this.$ml.get("button.cancel"),
            backdropClose: true,
          })
          .then((dialog) => {
            this.storeUser();
            // dialog.close();
          });
      } else {
        this.storeUser();
      }
    },

    validateForm() {
      this.validFormMessage = "";

      let isValid = true;

      if (!this.full_name) {
        this.validFormMessage += `- ${this.$ml.get("user.notValidName")}<br/>`;
        isValid = false;
      }

      if (!this.accesslist.length) {
        this.validFormMessage += `- ${this.$ml.get(
          "user.notValidAccess"
        )}<br/>`;
        isValid = false;
      }

      if (!this.validatePermission()) {
        this.validFormMessage += `- ${this.$ml.get(
          "user.notValidPermission"
        )}<br/>`;
        isValid = false;
      }

      if (!this.dataProcessing) {
        this.validFormMessage += `- ${this.$ml.get(
          "user.notValidDataProcessing"
        )}<br/>`;
        isValid = false;
      }

      if (this.typeUser === "manager" && !this.phone) {
        this.validFormMessage += `- ${this.$ml.get("user.notValidPhone")}<br/>`;
        isValid = false;
      }

      if (
        this.typeUser !== "manager" &&
        !this.cards.length &&
        !this.photo &&
        !this.newphoto
      ) {
        this.validFormMessage += `- ${this.$ml.get(
          "user.notValidIdentify"
        )}<br/>`;
        isValid = false;
      }

      return isValid;
    },

    isValidPhone(p) {
      var phoneRe = /^[+]?[\s./0-9][2-9]\d{2}[2-9]\d{2}\d{4}$/;
      var digits = p.replace(/\D/g, "");
      return phoneRe.test(digits);
    },
    storeUser() {
      this.errorMessage = "";
      if (!this.full_name) {
        return;
      }
      if (
        this.typeUser == "manager" &&
        this.password &&
        this.password !== this.password_confirmation
      ) {
        return;
      }
      if (
        this.typeUser == "manager" &&
        (!this.phone || !this.isValidPhone(this.phone))
      ) {
        this.errorMessage = this.$ml.get("user.notPhone");
        return;
      }
      if (this.phone) {
        if (this.phone.substr(0, 1) == "8")
          this.phone = "7" + this.phone.substr(1, 10);
        this.phone = this.phone.replace(/\D/g, "");
      }

      if (!this.userId && !this.newphoto && !this.NoCheckPhoto) {
        this.errorMessage = this.$ml.get("user.notPhoto");
        return;
      }
      let f = this.full_name.trim().split(" ");
      if (f.length == 0) return;
      if (this.typeUser != "guest") {
        this.onepass = false;
      }
      if (this.document_number == "") {
        this.document_type = "";
      }
      this.lookBtn = true;

      let form = {
        no_check_photo: this.NoCheckPhoto,
        first_name: "",
        last_name: "",
        middle_name: "",
        type_user: this.typeUser,
        phone: this.phone,
        photo_encrypted: this.newphoto,
        photo: this.photo,
        blocked: this.blocked,
        blacklisted: this.blacklisted,
        twin_id: this.twin_id,
        cards: this.cards,
        data: JSON.stringify({
          visit_purpose: this.visit_purpose,
          document_type: this.document_type,
          document_number: this.document_number,
          post: this.post,
          onepass: this.onepass,
          expire_date: this.dateExpire
            ? this.$moment(
                `${this.dateExpire} ${this.timeExpire}`
              ).toISOString()
            : null,
        }),
        subdivisions: this.company,
        access_groups: this.accesslist,
        departments_id: this.department,
        is_twin: this.isTwin,
      };

      if (this.typeUser == "manager") {
        form.permission = {
          username: this.username,
          email: this.username,
          password: this.password,
          groupaccess: this.subdivisionperm,
          departments: this.departmentperm,
          rules: {
            request_confirm: this.requestСonfirmRule,
            edit_users: this.editUsersRule,
            edit_department: this.editDepartmentRule,
          },
        };
      }

      if (f.length === 1) {
        form.first_name = f[0];
      } else {
        if (f[0]) {
          form.last_name = f[0];
        }

        if (f[1]) {
          form.first_name = f[1];
        }

        if (f[2]) {
          form.middle_name = f
            .filter((item, index) => index !== 0 && index !== 1)
            .join(" ");
        }
      }

      let config = {
        header: {
          "Content-Type": "application/json",
        },
        timeout: 20000,
      };

      let url = "/user/add";
      if (this.userId) {
        url = "/user/" + this.userId;
        form.id = this.userId;
      }
      axios
        .post(url, form, config)
        .then((response) => {
          if (response.status == 200) {
            if (response.data.status.code != 0) {
              let message = response.data.status.message || "";
              this.errorMessage = this.getLangMessage(message);
            } else {
              if (this.userId) {
                router.push({ path: `/user/` + this.userId });
              } else {
                router.push({ path: `/users` });
              }
            }
          }
          this.lookBtn = false;
          //this.devices = response.data ? response.data : [];
        })
        .catch((error) => {
          if (error.response.status == 406) {
            this.errorMessage = this.getLangMessage("Bad photo");
          } else if (error.response.status == 422) {
            this.errorMessage = this.getLangMessage(
              "Fields filled in incorrectly"
            );
          } else if (error.response.status == 503) {
            this.errorMessage = this.getLangMessage("Device Unavailable");
          } else {
            let mes = error.response.data.status.message;
            if (mes.indexOf("Face match found User") !== -1 && !this.userId) {
              // Пользователь уже в базе
              let userID = parseInt(
                mes.replace("Face match found User ", "").trim()
              );
              let _this = this;
              this.$dialog
                .confirm(
                  `<h3>${this.$ml.get("message.Twin")} #${userID}</h3>` +
                    this.$ml.get("message.addTwin"),
                  {
                    loader: false,
                    okText: this.$ml.get("button.save"),
                    customText: this.$ml.get("button.show"),
                    cancelText: this.$ml.get("button.cancel"),
                    backdropClose: true,
                  }
                )
                .then(function () {
                  _this.twin_id = userID;
                  _this.storeUser();
                });
            }
            this.errorMessage = this.getLangMessage(mes);
          }
          this.lookBtn = false;
        });
    },
    refeature() {
      let url = "/user/" + this.userId + "/refeature";
      axios
        .put(url)
        .then((response) => {
          if (response.status == 200) {
            if (response.data.success == false) {
              this.errorMessage = this.getLangMessage(response.data.message);
            } else {
              this.$notify({
                group: "info",
                type: "success",
                text: this.$ml.get("message.refeature_success"),
              });
              //router.push({ path: `/user/` + this.userId });
            }
          }
          this.lookBtn = false;
          //this.devices = response.data ? response.data : [];
        })
        .catch((error) => {
          this.$notify({
            group: "info",
            type: "error",
            text: error.response.data.message,
          });
          //this.errorMessage = this.getLangMessage(error.response.data.message);
        });
    },
    getLangMessage(message) {
      this.messageClick = "";
      if (!message) {
        return this.$ml.get("message.BadRequest");
      } else if (message == "Cant find face of the image")
        return this.$ml.get("message.CantFaceImage");
      else if (message == "Not found device")
        return this.$ml.get("message.notFoundDevice");
      else if (message == "Bad photo")
        return this.$ml.get("message.CantFaceImage");
      else if (
        message == "Unavailable to device" ||
        message == "Device Unavailable"
      )
        return this.$ml.get("message.UnavailableToDevice");
      else if (message == "face rotated")
        return this.$ml.get("message.faceRotated");
      else if (message == "Fields filled in incorrectly")
        return this.$ml.get("message.FieldsIncorrectly");
      else if (message == "Face match found")
        return this.$ml.get("message.FaceMatchFound");
      else if (message == "No subdivision selected")
        return this.$ml.get("message.NoSubdivisionSelected");
      else if (message.indexOf("Face match found User") !== -1) {
        let userID = parseInt(
          message.replace("Face match found User ", "").trim()
        );
        this.messageClick = "/user/" + userID;

        return (
          this.$ml.get("message.FaceMatchFound") +
          message.replace("Face match found", "")
        );
        //return `<a href="/user/` + userID + `">` + message + `</a>`;
      } else if (message.indexOf("Duplicate access card") !== -1) {
        let l = message.split("#");
        let userID = parseInt(l[1].trim());
        this.messageClick = "/user/" + userID;
        let mrep = this.$ml.get("message.DuplicateAccessCard");
        return l[0].replace("Duplicate access card", mrep);
      } else if (
        message ===
        'pq: duplicate key value violates unique constraint "admins_username_key"'
      ) {
        return this.$ml.get("message.duplicateKeyLogin");
      } else return message;
    },
  },
  mounted() {
    this.getuser();
    this.getCompanies();
    this.getDepartments();
    this.getDevices();
  },
  watch: {
    phone(value) {
      this.phone = value;
    },

    async password() {
      await this.$nextTick();
      this.$refs.passwordConfirmation.validate();
    },
  },
};
</script>
