<template>
  <v-app class="d-flex add-new-visitor-form-container">
    <div class="container add-new-visitor-form col-sm-12">
      <div class="flash-message" style="display: none"></div>
      <div class="row">
        <div class="column col-sm-4 image-box">
          <ImageBox
            v-on:toggle="togglePhoto($event)"
            :devices="devices"
            :photo="photo"
          />
        </div>
        <div class="column form-column col-sm-8">
          <div v-if="errorMessage" class="alert alert-danger" role="alert">
            {{ errorMessage }}
          </div>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="full_name"
                :rules="nameRules"
                :label="`${$ml.get('user.fullName')} *`"
                outlined
                required
              ></v-text-field>
              <v-text-field
                v-model="phone"
                :label="$ml.get('user.phone')"
                outlined
              ></v-text-field>
              <label v-if="user_type != 'admin'">{{
                $ml.get("guard.zones")
              }}</label>
              <treeselect
                v-if="user_type != 'admin'"
                v-model="zone"
                :multiple="true"
                :options="zones"
                :placeholder="$ml.get('guard.zones')"
                :disable-branch-nodes="true"
                :searchable="true"
                :show-count="true"
              />
            </v-col>
          </v-row>
          <v-row v-if="user_type != 'admin'">
            <v-col cols="12">
              <label>{{ $ml.get("guard.permissions") }}</label>
              <treeselect
                v-if="user_type != 'admin'"
                v-model="roles"
                :multiple="true"
                :options="permissions"
                :placeholder="$ml.get('guard.permissions')"
                :disable-branch-nodes="true"
                :searchable="false"
                :show-count="true"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="login-data col-sm-6">
              <h6>{{ $ml.get("auth.logininformation") }}</h6>
              <template v-if="!id || isAuth">
                <v-text-field
                  v-if="!id"
                  v-model="username"
                  :rules="usernameRules"
                  :label="`${$ml.get('auth.username')} *`"
                  outlined
                  :readonly="id > 0"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="password"
                  :rules="passwordRules"
                  :label="`${$ml.get('auth.password')} *`"
                  type="password"
                  outlined
                  required
                ></v-text-field>
                <v-text-field
                  v-if="password"
                  ref="passwordConfirmation"
                  class="password-guard"
                  v-model="password_confirmation"
                  :rules="passwordConfirmRules"
                  :label="`${$ml.get('auth.passwordConfirm')} *`"
                  type="password"
                  outlined
                  required
                ></v-text-field>
              </template>
              <button
                v-if="!isAuth && id"
                class="btn btn-primary change-password-btn"
                @click="isAuth = true"
              >
                {{ $ml.get("guard.changeAuth") }}
              </button>
            </v-col>
          </v-row>

          <v-row justify="end">
            <v-col cols="auto">
              <v-btn @click="goCancel">
                {{ $ml.get("button.back") }}
              </v-btn>
            </v-col>
            <v-spacer />
            <template v-if="id && user_type != 'admin'">
              <v-col v-if="!deleted" cols="auto">
                <v-btn color="error" @click.prevent="deleteUser">
                  {{ $ml.get("button.delete") }}
                </v-btn>
              </v-col>
            </template>
            <v-col v-if="full_name && username" cols="auto">
              <v-btn color="primary" @click.prevent="storeUser">
                {{ $ml.get("button.save") }}
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <!-- <span class="invalid-feedback" role="alert" id="image-error"></span> -->
      </div>
    </div>
    <div class="filler"></div>
  </v-app>
</template>

<script>
import ImageBox from "../components/ImageBox";
import axios from "axios";
import vuetify from "../plugins/vuetify.js";
import router from "../router";

// import the component
import Treeselect from "@riophae/vue-treeselect";

export default {
  name: "GuardEdit",
  components: { ImageBox, Treeselect },
  vuetify,
  data() {
    return {
      id: this.$route.params.id,
      isAuth: false,
      deleted: false,
      user_type: "guard",
      newphoto: "",
      photo: "",
      phone: "",
      full_name: "",
      zone: [],
      username: "",
      password: "",
      password_confirmation: "",
      roles: [],
      permissions: [
        { id: "useradd", label: this.$ml.get("permission.useradd") },
        { id: "useredit", label: this.$ml.get("permission.useredit") },
        { id: "userdel", label: this.$ml.get("permission.userdel") },
        { id: "sdvedit", label: this.$ml.get("permission.sdvedit") },
        { id: "journal", label: this.$ml.get("permission.journal") },
        { id: "requests", label: this.$ml.get("permission.requests") },
        { id: "reqconfirm", label: this.$ml.get("permission.reqconfirm") },
        //{ id: "schedule", label: this.$ml.get("permission.schedule") },
        { id: "deviceedit", label: this.$ml.get("permission.deviceedit") },
        { id: "zoneedit", label: this.$ml.get("permission.zoneedit") },
        { id: "adminedit", label: this.$ml.get("permission.adminedit") },
        { id: "depadd", label: this.$ml.get("permission.depadd") },
        { id: "depedit", label: this.$ml.get("permission.depedit") },
        { id: "depdel", label: this.$ml.get("permission.depdel") },
      ],
      zones: [],
      devices: [],
      nameRules: [
        (v) => !!v || this.$ml.get("message.NameIsRequired"),
        //v => v.length <= 10 || 'Name must be less than 10 characters',
      ],
      errorMessage: "",
      usernameRules: [
        (v) =>
          this.validUserName(v) || this.$ml.get("auth.username_char_valid"),
        (v) => v.length >= 6 || this.$ml.get("auth.lenghtn_username_8"),

        //v => v.length <= 10 || 'Name must be less than 10 characters',
      ],
      passwordRules: [
        (v) => v.length >= 6 || this.$ml.get("auth.lenghtn_password"),
      ],
      passwordConfirmRules: [
        (v) =>
          !!v ||
          this.$ml.get("auth.password") +
            " " +
            this.$ml.get("message.IsRequired"),
        (v) => v == this.password || this.$ml.get("auth.passwords_must_match"),
      ],
    };
  },
  computed: {
    cardsAccess() {
      return this.cards;
    },
  },
  methods: {
    validUserName(v) {
      console.log(v);
      var re = /^[a-zA-Z0-9\\-]+$/;
      return re.test(v);
    },
    validUserConfirmPassword(v) {
      return (
        v == this.password_confirmation || this.password_confirmation == ""
      );
    },
    goCancel() {
      router.push({ path: "/guards" });
    },
    togglePhoto(val) {
      this.newphoto = val;
    },

    // restoreUser() {
    //   axios.put("/user/" + this.id + "/restore").then(response => {
    //     if (response.status == 200) {
    //       this.deleted = false;
    //     }
    //   });
    // },
    // clearUser() {
    //   axios.delete("/user/" + this.id + "/trash").then(response => {
    //     if (response.status == 200) {
    //       router.push({ path: `/guards` });
    //     }
    //   });
    // },
    getZones() {
      axios.get("/zones").then((response) => {
        this.zones = response.data.data ? response.data.data : [];
        this.zones.forEach((el) => {
          el.label = el.name;
        });
      });
      //console.log(this.zones);
    },
    getDevices() {
      axios.get("/devices").then((response) => {
        this.devices = response.data ? response.data : [];
      });
    },
    deleteUser() {
      if (this.id) {
        let _this = this;
        this.$dialog
          .confirm(
            `<h3>${this.$ml.get("message.Delete")}</h3>` +
              this.$ml.get("message.confirmDelete"),
            {
              loader: false,
              okText: this.$ml.get("button.delete"),
              cancelText: this.$ml.get("button.cancel"),
              backdropClose: true,
            }
          )
          .then(function () {
            axios.delete("/guard/" + _this.id).then((response) => {
              if (response.status == 200) {
                router.push({ path: `/guards` });
                _this.deleted = true;
              }
            });
          });
      }
    },
    getuser() {
      if (this.id) {
        this.password = "";
        this.password_confirmation = "";
        this.isAuth = false;
        axios.get("/guard/" + this.id).then((response) => {
          if (response.status == 200) {
            let item = response.data.data;
            this.full_name = (
              item.last_name +
              " " +
              item.first_name +
              " " +
              item.middle_name
            ).trim();
            this.cards = item.cards;
            this.user_type = item.type_user || "guard";
            this.photo = item.photo;
            this.phone = item.phone;
            this.username = item.username;
            this.zone = item.zones;
            this.roles = item.roles;

            this.deleted = item.deleted;
            let data = {};
            if (item.data) {
              data = JSON.parse(item.data);
              this.visit_purpose = data.visit_purpose || "";
              this.document_type = data.document_type || "";
              this.document_number = data.document_number || "";
            }
          }
          //this.full_name = response
        });
      }
    },
    storeUser() {
      this.errorMessage = "";
      let f = this.full_name.trim().split(" ");
      if (f.length == 0) return;
      if (this.password && this.password !== this.password_confirmation) {
        return;
      }
      let form = {
        first_name: "",
        last_name: "",
        middle_name: "",
        type_user: this.user_type,
        phone: this.phone,
        photo_encrypted: this.newphoto,
        photo: this.photo,
        zones: this.zone,
        username: this.username,
        password: this.password,
        roles: this.roles,
      };
      if (f.length == 1) form.first_name = f[0];
      else if (f.length == 2) {
        form.last_name = f[0];
        form.first_name = f[1];
      } else {
        form.last_name = f[0];
        form.first_name = f[1];
        form.middle_name = f[2];
      }

      let config = {
        header: {
          "Content-Type": "application/json",
        },
        timeout: 20000,
      };
      let _this = this;

      let url = "/guard/add";
      if (this.id) {
        url = "/guard/" + this.id;
        form.id = this.id;
      }

      axios
        .post(url, form, config)
        .then((response) => {
          if (response.data.status.code != 0) {
            this.errorMessage = this.getLangMessage(
              response.data.status.message
            );
          } else {
            router.push({ path: `/guards` });
          }
          //this.devices = response.data ? response.data : [];
        })
        .catch((error) => {
          this.errorMessage = this.getLangMessage(
            error.response.data.status.message
          );
          console.log(error.response);
        });
    },
    getLangMessage(message) {
      if (
        message.indexOf("duplicate key value violates unique constraint") != -1
      ) {
        return this.$ml.get("message.duplicateKeyLogin");
      } else if (message == "Unavailable to device")
        return this.$ml.get("message.UnavailableToDevice");
      else if (message == "Face match found")
        return this.$ml.get("message.FaceMatchFound");
      else if (message == "No subdivision selected")
        return this.$ml.get("message.NoSubdivisionSelected");
      else return message;
    },
  },

  mounted() {
    this.id = this.$route.params.id;
    this.getuser();
    this.getZones();
    this.getDevices();
  },
  watch: {
    phone(value) {
      this.phone = value;
    },

    async password() {
      await this.$nextTick();
      this.$refs.passwordConfirmation.validate();
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/_variables.scss";

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
}

.form-column1 {
  grid-column: 2 / 3;
}
button.submit {
  width: 130px;
  height: 54px;
  background: $primary;
  border-radius: 30px;
  font-family: $font-family-sans-serif, sans-serif;
  font-style: normal;
  line-height: normal;
  font-size: 15px;
  text-align: center;
  color: #ffffff;
  transition: all 0.2s;
  outline: none;
}
.cancel:active,
.cancel:focus,
.cancel:hover {
  outline: none !important;
  color: #fff !important;
  background: $primary;
  text-decoration: none;
}
.password-guard {
  width: auto !important;
}
.change-password-btn {
  margin-bottom: 25px;
}
</style>
