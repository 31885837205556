<template>
  <div class="modal-background" style="display: block">
    <div class="take-photo">
      <a href="#" class="modal-close" @click="close"></a>
      <div class="webcam-box">
        <web-cam
          ref="webcam"
          class="p-3 pb-0"
          :device-id="deviceId"
          width="100%"
          @started="onStarted"
          @stopped="onStopped"
          @error="onError"
          @cameras="onCameras"
          @camera-change="onCameraChange"
        />
        <div class="controls">
          <v-row>
            <v-col cols="12" sm="6">
              <v-select
                v-model="camera"
                :items="devices"
                item-text="label"
                item-value="deviceId"
                :label="$ml.get('imageBox.camera_for_reg')"
                return-value
                outlined
                hide-details
              />
            </v-col>
            <v-spacer />
            <v-col v-if="!loader" cols="12" sm="auto">
              <v-btn large color="primary" class="setphoto" @click="takePhoto"
                >Сделать фото</v-btn
              >
            </v-col>
          </v-row>
          <!--a href="#" class="transparent-link-button retake-photo-button col-6">Переснять</a-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
require("vue-flash-message/dist/vue-flash-message.min.css");
import renderTargetBox from "../plugins/TargetBox";
import { WebCam } from "vue-web-cam";
export default {
  props: ["showing"],
  data() {
    return {
      camera: {},
      select: {},
      cameras: [],
      loader: true,
      message: "",
      heightDiff: null,
      canvasObject: null,

      img: null,
      deviceId: null,
      devices: [],
    };
  },
  components: {
    WebCam,
  },
  computed: {
    device: function () {
      return this.devices.find((n) => n.deviceId === this.deviceId);
    },
  },
  watch: {
    camera: function (id) {
      this.deviceId = id;
    },
    devices: function () {
      // Once we have a list select the first one
      const [first, ...tail] = this.devices;
      if (first) {
        this.camera = first.deviceId;
        this.deviceId = first.deviceId;
      }
    },
  },
  methods: {
    takePhoto() {
      if (this.$refs.webcam.capture()) {
        this.$emit("toggle", false);
        this.$emit("image", this.$refs.webcam.capture());
      }
    },
    close() {
      this.$emit("toggle", false);
    },

    onCapture() {
      this.img = this.$refs.webcam.capture();
    },
    onStarted(stream) {
      console.log("On Started Event", stream);
    },
    onStopped(stream) {
      console.log("On Stopped Event", stream);
    },
    onStop() {
      this.$refs.webcam.stop();
    },
    onStart() {
      this.$refs.webcam.start();
    },
    onError(error) {
      console.log("On Error Event", error);
    },
    onCameras(cameras) {
      this.devices = cameras;
      this.loader = false;
      console.log("On Cameras Event", cameras);
    },
    onCameraChange(deviceId) {
      this.deviceId = deviceId;
      this.camera = deviceId;
      console.log("On Camera Change Event", deviceId);
    },
  },
};
</script>
<style scoped>
.select-block {
  padding-left: 25px;
  max-width: 300px;
}
.setphoto {
  height: 54px !important;
}
.setphoto-one {
  display: initial;
  float: none;
}
.mjpeg img {
  display: none;
  /* width: 640px;
  height: 480px; */
}
.mjpeg {
  overflow: hidden;
}
</style>
