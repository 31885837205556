<template>
  <v-dialog v-model="dialog" persistent max-width="400px">
    <v-card>
      <v-card-title>
        <span class="headline"
          >{{ $ml.get("card.accessCode") }} {{ getTypeCode() }}</span
        >
      </v-card-title>

      <v-card-text>
        <v-select
          v-if="id == 0"
          v-model="cardtype"
          :items="cardtypes"
          item-text="name"
          item-value="val"
          :label="$ml.get('card.type')"
          outlined
          :disabled="isCardReaderMode"
        />
        <v-row v-if="cardtype == 'card'">
          <v-col cols="6">
            <v-text-field
              :label="$ml.get('card.series')"
              type="number"
              v-model="series"
              :disabled="isCardReaderMode"
              @input="calcSerialAndNumber"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              :label="$ml.get('card.number')"
              type="number"
              v-model="number"
              :disabled="isCardReaderMode"
              @input="calcSerialAndNumber"
            />
          </v-col>
        </v-row>
        <v-text-field
          v-if="cardtype == 'card'"
          :label="$ml.get('card.hex_code') + ' ORION'"
          type="text"
          v-model="hex_code_bolid"
          :disabled="isCardReaderMode"
          @input="calcHexBolid"
        />
        <v-text-field
          v-if="cardtype == 'card'"
          :label="$ml.get('card.hex_code')"
          type="text"
          v-model="hex_code"
          :disabled="isCardReaderMode"
          @input="calcHex"
        />
        <v-text-field
          :label="$ml.get('card.fullNumber')"
          type="text"
          v-model="full_number"
          :disabled="isCardReaderMode"
          @input="calcFullNumber"
          @keyup.enter="store"
        />

        <v-switch
          v-model="isCardReaderMode"
          :label="$ml.get('card.cardReaderMode')"
          :disabled="!devices.length"
          :hint="!devices.length ? $ml.get('card.noCardReaderDevices') : ''"
          persistent-hint
        />

        <template v-if="isCardReaderMode">
          <v-select
            v-model="selectedDevice"
            :items="devices"
            :item-text="getItemText"
            item-value="id"
            :label="$ml.get('card.cardReaderDevice')"
            outlined
          />
        </template>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text @click="close">{{ $ml.get("button.close") }}</v-btn>
        <v-btn v-if="full_number" color="primary" text @click="store">{{
          $ml.get("button.save")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import crc8 from "../../plugins/crc8.js";

export default {
  props: ["value"],

  data() {
    return {
      devices: [],
      selectedDevice: null,
      isCardReaderMode: false,

      id: 0,
      dialog: true,
      name: "",
      series: "",
      number: "",
      full_number: "",
      hex_code: "",
      hex_code_bolid: "",
      expire_date: "",
      active: true,
      cardtype: "card",
      cardtypes: [
        { name: "CARD", val: "card" },
        { name: "QR-CODE", val: "qr" },
      ],
      nameRules: [
        (v) => !!v || this.$ml.get("message.NameIsRequired"),
        //v => v.length <= 10 || 'Name must be less than 10 characters',
      ],
    };
  },
  methods: {
    close() {
      this.$emit("toggle", false);
    },
    store() {
      if (this.full_number) {
        let name = this.full_number;
        if (this.full_number.length > 6) {
          name = this.full_number.substr(this.full_number.length - 6, 6);
        }
        this.value.name = this.cardtype + "-" + name;
        this.value.number = this.full_number;
        this.value.type_card = this.cardtype;
        this.$emit("toggle", false);
      }
    },
    getTypeCode() {
      let _this = this;
      let result = this.cardtype;
      this.cardtypes.forEach((v) => {
        if (v.val == _this.cardtype) {
          result = v.name;
          return;
        }
      });
      return result;
    },
    calcSerialAndNumber() {
      if (!this.number && !this.series) return;
      let hser = parseInt(this.series, 10).toString(16);
      if (!this.series) {
        hser = "00";
      }
      let hnum = parseInt(this.number, 10).toString(16);
      if (hnum.length < 4) {
        hnum = "0000" + hnum;
        hnum = hnum.substr(hnum.length - 4, 4);
      }
      if (hser.length < 2) {
        hser = "00" + hser;
        hser = hser.substr(hnum.length - 2, 2);
      }
      let hex = hser + hnum;
      this.hexString(hex);
      this.hex_code = hex.toUpperCase();
      this.full_number = BigInt("0x" + hex).toString(10);
    },
    calcFullNumber() {
      if (!this.full_number) return;

      if (this.cardtype != "card") return this.full_number;

      this.number = "";
      this.series = "";
      this.full_number = this.full_number.replace(/[^\d]/g, "");
      let hex = BigInt(this.full_number, 10).toString(16);
      //console.log(hex);
      hex = hex.padStart(16 - hex.length, "0");
      this.hex_code = hex.toUpperCase();

      let hnum = hex.substr(hex.length - 4, 4);

      this.number = "0";
      if (hnum != "") {
        this.number = parseInt(hnum, 16).toString();
      }
      let hser = hex.substr(0, hex.length - 4);

      if (this.name == "") this.name = hex;

      this.hexString(hser + hnum);

      this.series = "0";
      if (hser != "") {
        this.series = parseInt(hser, 16).toString();
      }
    },
    calcHex() {
      if (!this.hex_code) return;
      this.number = "";
      this.series = "";
      let hx = this.hex_code;
      this.full_number = BigInt("0x" + hx).toString(10);

      let hex = parseInt(this.full_number, 10).toString(16);
      this.hexString(hex);
      let hnum = hex.substr(hex.length - 4, 4);
      this.number = "0";
      if (hnum != "") {
        this.number = parseInt(hnum, 16).toString();
      }
      let hser = hex.substr(0, hex.length - 4);
      this.series = "0";
      if (hser != "") {
        this.series = parseInt(hser, 16).toString();
      }
    },
    calcHexBolid() {
      if (!this.hex_code_bolid) return;
      this.number = "";
      this.series = "";
      this.hex_code = "";
      console.log(this.hex_code_bolid);
      let hx = this.hex_code_bolid.substr(2, 12);
      console.log(hx);

      this.full_number = BigInt("0x" + hx).toString(10);

      let hex = BigInt(this.full_number).toString(16);
      this.hex_code = hex.toUpperCase();

      let hnum = hex.substr(hex.length - 4, 4);
      this.number = "0";
      if (hnum != "") {
        this.number = parseInt(hnum, 16).toString();
      }
      let hser = hex.substr(0, hex.length - 4);
      this.series = "0";
      if (hser != "") {
        this.series = parseInt(hser, 16).toString();
      }
    },
    hexString(wiegand_hex) {
      let hh = wiegand_hex.padStart(12, "0");
      let b = [];
      let j = 0;
      hh = hh + "01";
      for (var i = 6; i >= 0; i--) {
        //console.log(hh.substr(i * 2, 2))
        b[j] = parseInt(hh.substr(i * 2, 2), 16);
        j++;
      }
      //console.log(b)
      hh = crc8(b).toString(16).padStart(2, "0") + hh;
      this.hex_code_bolid = hh.toUpperCase();
    },

    setHexCode(hexCode) {
      this.hex_code = hexCode;
      this.calcHex();
    },

    getDevices() {
      axios("devices").then((response) => {
        const devices = this.getDevicesWithCardReader(response.data.data);
        this.devices = devices;
        this.selectedDevice = devices[0].id;
      });
    },

    getDevicesWithCardReader(devices) {
      return devices.filter((device) => {
        const data = JSON.parse(device.data);

        if (data.card_reader) {
          return true;
        }

        return false;
      });
    },

    getItemText(item) {
      let text = item.ip_address;

      if (item.name) {
        text += ` (${item.name})`;
      }

      return text;
    },
  },
  created() {
    this.getDevices();

    let card = this.value || {};
    if (card) {
      this.id = card.id || 0;
      this.name = card.name || "";
      this.cardtype = card.type_card || "card";
      this.series = "";
      this.number = "";
      this.full_number = card.number || "";
      this.expire_date = card.expire_date;
      this.active = card.active || true;
      if (this.cardtype == "card") {
        this.calcFullNumber();
      }
    }

    this.$root.$on("cardreader", (item) => {
      if (
        this.cardtype === "card" &&
        this.isCardReaderMode &&
        this.selectedDevice === item.id
      ) {
        this.setHexCode(item.cardNum);
        this.isCardReaderMode = false;
        this.selectedDevice = null;
      }
    });
  },

  destroyed() {
    this.$root.$off("cardreader");
  },
};
</script>
<style scoped></style>
