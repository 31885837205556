<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="700px">
      <v-card>
        <v-card-title>
          <span v-if="!multi" class="headline">{{
            $ml.get("subdiv.title")
          }}</span>
          <span v-else class="headline">{{ $ml.get("subdiv.titles") }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col :cols="setCols">
                <v-text-field
                  v-if="!multi"
                  :label="$ml.get('subdiv.name') + '*'"
                  v-model="name"
                  :rules="nameRules"
                  required
                ></v-text-field>
                <v-select
                  v-if="!l.cloud_connect"
                  return-object
                  item-text="name"
                  item-value="id"
                  :items="subdivisions"
                  dblclick
                  v-model="thisparent"
                  :label="$ml.get('subdiv.parent')"
                ></v-select>
                <v-select
                  return-object
                  item-text="name"
                  item-value="id"
                  :items="zones"
                  dblclick
                  multiple
                  v-model="zoneparents"
                  :label="$ml.get('subdiv.zone')"
                ></v-select>
                <v-switch
                  v-model="isSchedule"
                  flat
                  :label="$ml.get('subdiv.schedule')"
                ></v-switch>
              </v-col>
              <v-col v-if="isSchedule" cols="6">
                <div
                  v-for="(schedule, it) in schedules"
                  :key="it"
                  class="shedule-item"
                >
                  <v-btn
                    v-if="schedules.length > 1"
                    icon
                    color="red"
                    class="shedule-item__close-btn"
                    @click="sheduleRemove(it)"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <TimeAccess v-model="schedules[it]" />
                </div>
                <v-btn @click="addNewShedule" class="mt-5">{{
                  $ml.get("schedule.add")
                }}</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close">{{ $ml.get("button.close") }}</v-btn>
          <v-btn
            v-if="
              (multi && (zoneparents.length || isSchedule || thisparent.id)) ||
              (name && thisparent)
            "
            color="primary"
            text
            @click="store"
            >{{ $ml.get("button.save") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import TimeAccess from "../../components/TimeAccess";
export default {
  props: ["value", "subdivisions", "multi", "ids"],
  components: { TimeAccess },
  data() {
    return {
      dialog: true,
      thisparent: {
        id: null,
      },
      zoneparents: [],
      schedules: [],
      //schedule: {},
      zones: [],
      name: "",
      id: 0,
      nameRules: [(v) => !!v || this.$ml.get("message.NameIsRequired")],
      isSchedule: false,
      l: {
        lang: "en",
      },
    };
  },
  methods: {
    setCols() {
      return this.isSchedule ? 12 : 6;
    },
    close() {
      this.$emit("toggle", false);
    },
    store() {
      if (this.multi) {
        this.multistore();
        return;
      }

      if (!this.name) return;
      let url = "/subdivision/add";
      let zones = [];
      this.zoneparents.forEach((z) => {
        if (z.id !== null) {
          zones.push(z.id);
        }
      });
      if (!this.isSchedule) {
        this.schedules = [];
      }

      let data = {
        name: this.name,
        parent_id: this.thisparent.id,
        zones_id: zones,
        access: this.schedules,
      };

      if (this.id) {
        data.id = this.id;
        url = "/subdivision/" + this.id;
        axios
          .put(url, data, {
            header: {
              "Content-Type": "application/json",
            },
            timeout: 20000,
          })
          .then((response) => {
            if (response.status == 200) {
              console.log(response);
              this.close();
            }
          });
      } else {
        axios
          .post(url, data, {
            header: {
              "Content-Type": "application/json",
            },
            timeout: 20000,
          })
          .then((response) => {
            if (response.status == 200) {
              console.log(response);
              this.close();
            }
          });
      }
    },
    multistore() {
      if (!this.ids) return;
      let zones = [];
      this.zoneparents.forEach((z) => {
        if (z.id !== null) {
          zones.push(z.id);
        }
      });
      if (!this.isSchedule) {
        this.schedules = [];
      }
      let data = {};
      if (this.thisparent.id) {
        data.parent_id = this.thisparent.id;
      }
      if (zones.length) {
        data.zones_id = zones;
      }
      data.access = this.schedules;
      let len = this.ids.length;
      this.ids.forEach((id) => {
        data.id = id;
        let url = "/subdivision/" + id;
        axios
          .put(url, data, {
            header: {
              "Content-Type": "application/json",
            },
            timeout: 20000,
          })
          .then((response) => {
            if (response.status == 200) {
              //console.log(this.ids[len-1], id);
              if (this.ids[len - 1] == id) {
                this.close();
              }
            }
          });
      });
    },
    getZones() {
      axios.get("/zones").then((response) => {
        if (response.status == 200) {
          this.zones = response.data.data;
        }
      });
    },
    addNewShedule() {
      this.schedules.push({
        id: +new Date(),
        checkWeek: false,
        checkDate: false,
        menu1: false,
        menu2: false,
        menu3: false,
        menu4: false,
        timeFrom: "00:00",
        timeTo: "00:00",
        weekdays: "1111100",
        dateFrom: "",
        dateTo: "",
      });
    },
    sheduleRemove(id) {
      this.schedules = this.schedules.filter((_, it) => it !== id);
    },
  },
  mounted() {
    try {
      this.l = JSON.parse(localStorage.getItem("ovision-ml"));
    } catch (err) {
      console.error(err);
    }
    this.getZones();
    this.name = this.value.name;
    if (this.value.zones_id) {
      this.value.zones_id.forEach((zone) => {
        this.zoneparents.push({ id: zone });
      });
      //   this.zoneparents.id = this.value.zones_id;
    }
    this.subdivisions.splice(0, 0, {
      id: null,
      parent_id: null,
      name: this.$ml.get("subdiv.notParent"),
    });
    this.thisparent = {
      id: this.value.parent_id,
      parent_id: null,
      name: "",
    };

    if (this.value.access != null && this.value.access.length) {
      this.schedules = this.value.access;
      this.schedules.forEach((item) => {
        if (!item.full_access) {
          this.isSchedule = true;
        }
      });
    }

    let _this = this;
    this.subdivisions.forEach((item) => {
      if (item.parent_id != null) {
        let find = _this.subdivisions.find((el) => el.id == item.parent_id);
        item.name = find.name + "/" + item.name;
      }
    });

    this.id = this.value.id;
  },
};
</script>

<style lang="scss">
.shedule-item {
  position: relative;
  &__close-btn {
    position: absolute !important;
    right: 0;
    top: 0;
    z-index: 1;
  }
}
</style>
