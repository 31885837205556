<template>
  <div v-show="shown" class="pwa-install">
    <v-btn x-small depressed @click="installPWA"
      >{{ $ml.get("button.installPWA") }}
      <v-icon right dark>mdi-tray-arrow-down</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  data: () => ({
    shown: false,
  }),

  beforeMount() {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      this.installEvent = e;
      this.shown = true;
    });
  },

  methods: {
    installPWA() {
      this.installEvent.prompt();
      this.installEvent.userChoice.then((choice) => {
        if (choice.outcome === "accepted") {
          this.shown = false;
        }
      });
    },
  },
};
</script>
