<template>
  <div class="mt-5">
    <v-row>
      <v-col cols="12" md="6">
        <h5>{{ $ml.get("setting.UpdatingBiometricData") }}</h5>
        <v-row>
          <v-col cols="12" md="auto">
            <v-btn
              ref="refreshFeature"
              color="success"
              @click="refreshFeatures"
            >
              {{ $ml.get("setting.dataUpdate") }}
            </v-btn>
          </v-col>
          <v-col cols="12" md="auto">
            <v-btn
              ref="refreshFeatureFix"
              color="success"
              @click="appendFeatures"
            >
              {{ $ml.get("setting.featuresAppend") }}
            </v-btn>
          </v-col>
          <v-col v-if="refreshFeatureProcess" cols="12" md="auto">
            <v-btn color="primary" @click="refreshFeaturesStop">
              {{ $ml.get("setting.stopRefreshFeatures") }}
            </v-btn>
          </v-col>
        </v-row>

        <template v-if="progress1">
          <v-progress-linear :value="progress1" class="mt-5" />

          <hr />

          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                :label="$ml.get('setting.errors')"
                v-model="countError"
                :readonly="true"
                outlined
                style="width: 100px"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                :label="$ml.get('setting.updated')"
                v-model="countSuccess"
                :readonly="true"
                outlined
                style="width: 100px"
              />
            </v-col>
          </v-row>

          <v-textarea
            v-if="listError"
            :label="$ml.get('setting.errorlog')"
            :value="listError"
            outlined
          />
        </template>
      </v-col>

      <v-col v-if="!l.cloud_connect" cols="12" md="6">
        <v-row>
          <v-col cols="12">
            <h5>{{ $ml.get("setting.RemovingUsers") }}</h5>
            <v-btn ref="deleteusers" color="error" @click="deleteAllUsers">
              {{ $ml.get("setting.removeAllUsers") }}
            </v-btn>

            <template v-if="progress2">
              <v-progress-linear :value="progress2" class="bt-5" />
            </template>
          </v-col>

          <v-col cols="12">
            <h5>{{ $ml.get("setting.RemovingGroups") }}</h5>
            <v-btn ref="deletegroup" color="error" @click="deleteAllGroup">
              {{ $ml.get("setting.RemovingAccessGroup") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: ["value"],
  data() {
    return {
      company: [],
      companies: [],
      lockimport: true,
      file: {},
      progress: 0,
      progress1: 0,
      progress2: 0,
      pageRefresh: 1,
      listError: "",
      countError: 0,
      countSuccess: 0,
      refreshFeatureProcess: false,
      l: {
        lang: "en",
      },
    };
  },
  mounted() {
    if (localStorage.getItem("ovision-ml")) {
      try {
        this.l = JSON.parse(localStorage.getItem("ovision-ml"));
      } catch (err) {
        console.error(err);
      }
    }
    let self = this;
    this.$root.$off("delusers");
    this.$root.$on("delusers", function (item) {
      self.delusers(item);
    });
    this.$root.$off("featureupdate");
    this.$root.$on("featureupdate", function (item) {
      self.featureupdate(item);
    });
    this.$root.$off("featureupdateerror");
    this.$root.$on("featureupdateerror", function (item) {
      self.featureupdateerror(item);
    });
    this.$root.$off("featureupdatesuccess");
    this.$root.$on("featureupdatesuccess", function (item) {
      self.featureupdatesuccess(item);
    });
  },
  methods: {
    featureupdate(item) {
      this.progress1 = 0;
      if (item.total) {
        item.fails = item.fails || [];
        item.errors = item.errors || 0;
        item.success = item.success || 0;
        this.progress1 = Math.ceil((item.page / item.total) * 100);
        this.countError = item.errors;
        this.countSuccess = item.success;
        if (item.fails.length) {
          item.fails.forEach((element) => {
            this.listError = this.listError + element + "\n";
          });
        }
        if (item.page == item.total) {
          this.refreshFeatureProcess = false;
          this.$notify({
            group: "info",
            type: "success",
            text: this.$ml.get("setting.featureUpdated"),
          });
        }
      }
    },
    featureupdateerror(item) {
      this.listError = this.listError + item.message + "\n";
      this.countError++;
    },
    featureupdatesuccess(item) {
      console.log(item);
      this.countSuccess++;
    },
    delusers: function (item) {
      this.progress2 = 0;
      if (item.total) {
        this.progress2 = Math.ceil((item.item / item.total) * 100);
        if (item.item == item.total) {
          this.$notify({
            group: "info",
            type: "success",
            text: this.$ml.get("message.delete_success"),
          });
        }
      }
    },
    deleteAllUsers() {
      let _this = this;
      this.$dialog
        .confirm(
          `<h3>${_this.$ml.get("message.Delete")}</h3>` +
            `<span style="color: red;">` +
            this.$ml.get("message.confirmDeleteAllUsers") +
            `</span>`,
          {
            loader: false,
            okText: _this.$ml.get("button.delete"),
            cancelText: _this.$ml.get("button.cancel"),
            backdropClose: true,
          }
        )
        .then(function () {
          axios.delete("/users/all").then((response) => {
            _this.$notify({
              group: "info",
              type: "success",
              text: _this.$ml.get("message.deleteSuccess"),
            });
          });
        });
    },
    deleteAllGroup() {
      let _this = this;
      this.$dialog
        .confirm(
          `<h3>${_this.$ml.get("message.Delete")}</h3>` +
            `<span style="color: red;">` +
            this.$ml.get("message.confirmDeleteAllGroups") +
            `</span>`,
          {
            loader: false,
            okText: _this.$ml.get("button.delete"),
            cancelText: _this.$ml.get("button.cancel"),
            backdropClose: true,
          }
        )
        .then(function () {
          axios.delete("/subdivisions/all").then((response) => {
            _this.$notify({
              group: "info",
              type: "success",
              text: _this.$ml.get("message.deleteSuccess"),
            });
          });
        });
    },
    appendFeatures(e) {
      if (this.refreshFeatureProcess) return;
      this.listError = "";
      this.progress1 = 0;
      this.countError = 0;
      this.countSuccess = 0;
      axios
        .get("/features/append")
        .then((response) => {
          if (response.status == 200) {
            this.refreshFeatureProcess = true;
          }
        })
        .catch((error) => {
          this.$notify({
            group: "info",
            type: "error",
            text: this.$ml.get("setting.dataNotUpdated"),
          });
        });

      //this.nextGetFeaturesFix(1);
    },
    refreshFeatures(e) {
      if (this.refreshFeatureProcess) return;
      this.listError = "";
      this.progress1 = 0;
      this.countError = 0;
      this.countSuccess = 0;
      axios
        .get("/features/refresh")
        .then((response) => {
          if (response.status == 200) {
            this.refreshFeatureProcess = true;
          }
        })
        .catch((error) => {
          this.$notify({
            group: "info",
            type: "error",
            text: this.$ml.get("setting.dataNotUpdated"),
          });
        });
    },
    refreshFeaturesStop(e) {
      if (!this.refreshFeatureProcess) return;
      axios.put("/features/refresh/stop").then((response) => {
        this.refreshFeatureProcess = false;
        this.progress1 = 0;
        this.$notify({
          group: "info",
          type: "success",
          text: this.$ml.get("setting.featureStopped"),
        });
      });
    },
  },
};
</script>
<style scoped>
.btn-file > input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  opacity: 0;
  height: 100%;
  z-index: 5;
  cursor: pointer;
}
</style>
