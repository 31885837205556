var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{class:[
    'left-sidebar',
    { 'left-sidebar--min': _vm.isMenuMinimize },
    { 'left-sidebar--show': _vm.isOpenMenu } ]},[_c('header',[_c('a',{class:['logo', { 'logo--min': _vm.isMenuMinimize }],attrs:{"href":"/"}},[(_vm.l.show)?[(!_vm.isMenuMinimize)?_c('img',{attrs:{"src":require("@/assets/img/logo.svg"),"width":"70%","alt":"Ovision"}}):_c('img',{attrs:{"src":require("@/assets/img/logo-min.svg"),"alt":"Ovision"}})]:[(!_vm.isMenuMinimize)?_c('img',{attrs:{"src":require("@/assets/img/logo-youmay.svg"),"width":"70%","alt":"Youmay"}}):_c('img',{attrs:{"src":require("@/assets/img/logo-youmay-min.svg"),"width":"70%","alt":"Youmay"}})]],2)]),_c('ul',{staticClass:"nav flex-column"},[(
        _vm.$auth.user().type_user == 'admin' || _vm.$auth.user().type_user == 'guard'
      )?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"exact":"","to":{ name: 'dashboard' },"active-class":"active"}},[_c('v-icon',[_vm._v("mdi-view-dashboard")]),(!_vm.isMenuMinimize)?[_vm._v(" "+_vm._s(_vm.$ml.get("menu.dashboard"))+" ")]:_vm._e()],2)],1):_vm._e(),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'users' },"active-class":"active"}},[_c('v-icon',[_vm._v("mdi-account-multiple")]),(!_vm.isMenuMinimize)?[_vm._v(" "+_vm._s(_vm.$ml.get("menu.users"))+" ")]:_vm._e()],2)],1),(
        _vm.$auth.user().type_user == 'admin' ||
        _vm.$auth.user().roles.includes('journal')
      )?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'journal' },"active-class":"active"}},[_c('v-icon',[_vm._v("mdi-notebook")]),(!_vm.isMenuMinimize)?[_vm._v(" "+_vm._s(_vm.$ml.get("menu.journal"))+" ")]:_vm._e()],2)],1):_vm._e(),(
        _vm.$auth.user().type_user == 'admin' ||
        _vm.$auth.user().roles.includes('requests') ||
        _vm.$auth.user().roles.includes('reqconfirm')
      )?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'requests' },"active-class":"active"}},[_c('v-icon',[_vm._v("mdi-lock-check")]),(!_vm.isMenuMinimize)?[_vm._v(" "+_vm._s(_vm.$ml.get("menu.requests"))+" ")]:_vm._e()],2)],1):_vm._e(),(_vm.$auth.user().type_user == 'admin')?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'guards' },"active-class":"active"}},[_c('v-icon',[_vm._v("mdi-account-star")]),(!_vm.isMenuMinimize)?[_vm._v(" "+_vm._s(_vm.$ml.get("menu.guards"))+" ")]:_vm._e()],2)],1):_vm._e(),(
        _vm.$auth.user().type_user == 'admin' ||
        _vm.$auth.user().type_user == 'guard' ||
        _vm.$auth.user().roles.includes('devices')
      )?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'devices' },"active-class":"active"}},[_c('v-icon',[_vm._v("mdi-cctv")]),(!_vm.isMenuMinimize)?[_vm._v(" "+_vm._s(_vm.$ml.get("menu.devices"))+" ")]:_vm._e()],2)],1):_vm._e(),(
        _vm.$auth.user().type_user == 'admin' ||
        _vm.$auth.user().roles.includes('adminedit')
      )?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'settings' },"active-class":"active"}},[_c('v-icon',[_vm._v("mdi-cog")]),(!_vm.isMenuMinimize)?[_vm._v(" "+_vm._s(_vm.$ml.get("menu.settings"))+" ")]:_vm._e()],2)],1):_vm._e()]),_c('v-spacer'),_c('aside',[(!_vm.isMenuMinimize)?_c('ul',[(_vm.l.lang === 'ru')?_c('li',[_c('a',{staticClass:"mdi mdi-open-in-new",attrs:{"href":"/static/terminal.pdf","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$ml.get("menu.manual")))])]):_vm._e(),(_vm.l.lang === 'ru')?_c('li',[_vm._v(" "+_vm._s(_vm.$ml.get("menu.support"))+":"),_c('br'),_vm._v(_vm._s(_vm.$ml.get("menu.supportPhone"))+" ")]):_vm._e(),(_vm.l.version)?_c('li',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.$ml.get("menu.version"))+": "+_vm._s(_vm.l.version)+" ")]):_vm._e()]):_vm._e(),(!_vm.isMenuMinimize)?_c('PWAInstall'):_vm._e(),_c('button',{staticClass:"left-sidebar__hide-menu-btn",attrs:{"type":"button"},on:{"click":_vm.toggleMenuSize}},[(!_vm.isMenuMinimize)?_c('v-icon',[_vm._v("mdi-chevron-left")]):_c('v-icon',[_vm._v("mdi-chevron-right")]),(!_vm.isMenuMinimize)?[_vm._v(" "+_vm._s(_vm.$ml.get("menu.hideMenu"))+" ")]:_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }