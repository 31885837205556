<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="stateName"
          :label="`${$ml.get('requests.name')}*`"
          :rules="[(v) => !!v || $ml.get('message.NameIsRequired')]"
          :disabled="isReadonly"
          outlined
          @input="changeForm"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="stateDescription"
          :label="$ml.get('requests.description')"
          outlined
          persistent-hint
          :hint="$ml.get('requests.descriptionHint')"
          :disabled="isReadonly"
          @input="changeForm"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="stateEmail"
          ref="email"
          type="email"
          :label="emailLabel"
          :rules="emailRules"
          :disabled="isReadonly"
          outlined
          @input="changeForm"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="statePhone"
          ref="phone"
          v-mask="'+7 (###) ###-##-##'"
          :label="phoneLabel"
          :rules="phoneRules"
          outlined
          :disabled="isReadonly"
          @input="changeForm"
          @focus="onFocusPhone"
          @blur="onBlurPhone"
        />
      </v-col>
    </v-row>

    <v-row class="mb-5">
      <v-col cols="12" md="6">
        <treeselect
          v-model="stateDepartment"
          :multiple="false"
          :options="departments"
          :placeholder="`${$ml.get('user.departments')} *`"
          :searchable="true"
          :show-count="true"
          :disabled="isReadonly"
          @input="onChangeDepartments"
        />
        <div
          v-if="!validDepartments"
          class="v-messages theme--light error--text pl-3 pr-3 mt-2 mb-2"
          role="alert"
        >
          <div class="v-messages__wrapper">
            <div class="v-messages__message">
              {{ $ml.get("message.NameIsRequired") }}
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <treeselect
          v-model="stateSubdivisions"
          :multiple="true"
          :options="companies"
          :placeholder="`${$ml.get('requests.subdivisions')} *`"
          :searchable="true"
          :show-count="true"
          :disabled="isReadonly"
          @input="onChangeSubdivisions"
        />
        <div
          v-if="!validSubdivisions"
          class="v-messages theme--light error--text pl-3 pr-3 mt-2 mb-2"
          role="alert"
        >
          <div class="v-messages__wrapper">
            <div class="v-messages__message">
              {{ $ml.get("message.NameIsRequired") }}
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <v-switch
          v-model="isOnepass"
          :label="
            isOnepass
              ? $ml.get('requests.onepass')
              : $ml.get('requests.multiPass')
          "
          class="p-0 m-0"
          :disabled="isReadonly"
          persistent-hint
          :hint="
            !isCustomDue && !isReadonly ? $ml.get('requests.on24Hours') : ''
          "
          @change="changeForm"
        />
      </v-col>
      <!-- TODO: вернуть когда будет доступен выбор способа регистрации -->
      <!-- <v-col cols="12" md="6">
        <v-row>
          <v-col cols="6">
            <v-checkbox
              v-model="isRegByQR"
              :label="$ml.get('requests.byQR')"
              class="p-0 m-0"
              :disabled="isReadonly"
            />
          </v-col>
          <v-col cols="6">
            <v-checkbox
              v-model="isRegByKiosk"
              :label="$ml.get('requests.byKiosk')"
              class="p-0 m-0"
              :disabled="isReadonly"
            />
          </v-col>
        </v-row>
      </v-col> -->
    </v-row>

    <v-row v-if="!isReadonly">
      <v-col cols="12">
        <v-btn
          v-show="!isCustomDue"
          color="primary"
          x-small
          @click="isCustomDue = true"
          >{{ $ml.get("requests.setCustomDue") }}</v-btn
        >
        <v-btn
          v-show="isCustomDue"
          color="red"
          x-small
          @click="isCustomDue = false"
          >{{ $ml.get("requests.unsetCustomDue") }}</v-btn
        >
      </v-col>
    </v-row>

    <v-row v-show="isCustomDue || isReadonly">
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12" class="pb-0 pt-0">
            {{ $ml.get("requests.dueFrom") }}
          </v-col>
          <v-col cols="8">
            <v-menu
              v-model="dueDateFromMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="stateDueDateFrom"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :placeholder="$ml.get('requests.date')"
                  outlined
                  :disabled="isReadonly"
                />
              </template>
              <v-date-picker
                v-model="stateDueDateFrom"
                :min="dueFromMin"
                :max="dueFromMax"
                @input="onChangeDueDateFrom"
              />
            </v-menu>
          </v-col>
          <v-col cols="4">
            <div
              class="
                v-input v-input--is-disabled v-input--is-readonly
                theme--light
                v-text-field
                v-text-field--is-booted
                v-text-field--enclosed
                v-text-field--outlined
                v-text-field--placeholder
              "
            >
              <div class="v-input__control">
                <div class="v-input__slot">
                  <fieldset aria-hidden="true" style="margin-top: 5px">
                    <legend style="width: 0px"></legend>
                  </fieldset>

                  <div class="v-text-field__slot">
                    <input
                      type="time"
                      v-model="stateDueTimeFrom"
                      @click:minute="stateDueTimeFrom"
                      :disabled="!stateDueDateFrom || isReadonly"
                      :placeholder="$ml.get('requests.time')"
                      @change="changeForm"
                    />
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12" class="pb-0 pt-0">
            {{ $ml.get("requests.dueTo") }}
          </v-col>
          <v-col cols="8">
            <v-menu
              v-model="dueDateToMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="stateDueDateTo"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :placeholder="$ml.get('requests.date')"
                  outlined
                  :disabled="isReadonly"
                  @change="changeForm"
                />
              </template>
              <v-date-picker
                v-model="stateDueDateTo"
                :min="dueToMin"
                @input="onChangeDueDateTo"
              />
            </v-menu>
          </v-col>
          <v-col cols="4">
            <div
              class="
                v-input v-input--is-disabled v-input--is-readonly
                theme--light
                v-text-field
                v-text-field--is-booted
                v-text-field--enclosed
                v-text-field--outlined
                v-text-field--placeholder
              "
            >
              <div class="v-input__control">
                <div class="v-input__slot">
                  <fieldset aria-hidden="true" style="margin-top: 5px">
                    <legend style="width: 0px"></legend>
                  </fieldset>

                  <div class="v-text-field__slot">
                    <input
                      type="time"
                      v-model="stateDueTimeTo"
                      @click:minute="onChangeDueTimeTo"
                      :disabled="!stateDueDateTo || isReadonly"
                      :placeholder="$ml.get('requests.time')"
                      @change="changeForm"
                    />
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-textarea
          v-model="statePurpose"
          rows="1"
          auto-grow
          :label="$ml.get('requests.purpose')"
          outlined
          persistent-hint
          :hint="$ml.get('requests.purposeHint')"
          :disabled="isReadonly"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";

const dateFormat = "YYYY-MM-DD";
const timeFormat = "HH:mm";

export default {
  components: { Treeselect },

  props: {
    name: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    email: {
      type: String,
      default: "",
    },
    phone: {
      type: String,
      default: "",
    },
    dueFrom: {
      type: String,
      default: "",
    },
    dueTo: {
      type: String,
      default: "",
    },
    subdivisions: {
      type: Array,
      default: () => [],
    },
    department: {
      type: String,
      default: null,
    },
    status: {
      type: Number,
      default: 0,
    },
    requestType: {
      type: Number,
      default: 1,
    },
    onepass: {
      type: Boolean,
      default: true,
    },
    purpose: {
      type: String,
      default: "",
    },
  },

  data() {
    const stateName = this.name;
    const stateDescription = this.description;
    const stateEmail = this.email;
    const statePhone = this.phone;
    const stateDueDateFrom = this.dueFrom
      ? this.$moment(this.dueFrom).format(dateFormat)
      : this.$moment().format(dateFormat);
    const stateDueTimeFrom = this.dueFrom
      ? this.$moment(this.dueFrom).format(timeFormat)
      : this.$moment().format(timeFormat);
    const stateDueDateTo = this.dueTo
      ? this.$moment(this.dueTo).format(dateFormat)
      : this.$moment().add(24, "hour").format(dateFormat);
    const stateDueTimeTo = this.dueTo
      ? this.$moment(this.dueTo).format(timeFormat)
      : this.$moment().add(24, "hour").format(timeFormat);

    const isOnepass = this.onepass;
    let isRegByQR = true;
    let isRegByKiosk = false;

    const requestTypeSplit = String(this.requestType)
      .split("")
      .map((num) => {
        return Number(num);
      });

    if (requestTypeSplit.includes(1)) {
      isRegByQR = true;
    } else {
      isRegByQR = false;
    }

    if (requestTypeSplit.includes(0)) {
      isRegByKiosk = true;
    } else {
      isRegByKiosk = false;
    }

    const stateDepartment = this.department || null;

    const stateSubdivisions = this.subdivisions;

    const statePurpose = this.purpose;

    return {
      stateName,
      stateDescription,
      stateEmail,
      statePhone,
      isOnepass,

      dueDateFromMenu: false,
      dueTimeFromMenu: false,
      dueDateToMenu: false,
      dueTimeToMenu: false,
      stateDueDateFrom,
      stateDueTimeFrom,
      stateDueDateTo,
      stateDueTimeTo,
      statePurpose,
      isCustomDue: false,

      stateSubdivisions,
      companies: [],

      stateDepartment,
      departments: [],

      isRegByQR,
      isRegByKiosk,

      valid: true,
      validSubdivisions: true,
      validDepartments: true,

      emailRules: [
        () => {
          if (!this.stateEmail && !this.statePhone) {
            return this.$ml.get("requests.oneOfContactIsRequired");
          }

          return true;
        },
        (v) => {
          if (this.stateEmail) {
            return (
              this.validateEmail(v) || this.$ml.get("user.email_char_valid")
            );
          }

          return true;
        },
      ],
      phoneRules: [
        () => {
          if (!this.stateEmail && !this.statePhone) {
            return this.$ml.get("requests.oneOfContactIsRequired");
          }

          return true;
        },
      ],
    };
  },

  watch: {
    isRegByQR(isRegByQR) {
      if (!isRegByQR) {
        this.isRegByKiosk = true;
      }
      this.changeForm();
    },

    isRegByKiosk(isRegByKiosk) {
      if (!isRegByKiosk) {
        this.isRegByQR = true;
      }
      this.changeForm();
    },

    async stateEmail() {
      await this.$nextTick();
      this.$refs.phone.validate();
    },

    async statePhone() {
      await this.$nextTick();
      this.$refs.email.validate();
    },
  },

  methods: {
    changeForm() {
      const {
        stateName: name,
        stateDescription: description,
        stateEmail: email,
        statePhone: phone,
        isOnepass: onepass,
        isCustomDue,
        stateDueDateFrom,
        stateDueTimeFrom,
        stateDueDateTo,
        stateDueTimeTo,
        stateSubdivisions: subdivisions,
        stateDepartment: department,
        requestTypeInt: request_type,
        statePurpose: purpose,
        status,
      } = this;

      const contacts = {
        email,
        phone,
        onepass,
        purpose,
        department,
      };

      let due_from = null;
      if (isCustomDue) {
        due_from = this.$moment(
          `${stateDueDateFrom} ${stateDueTimeFrom}`
        ).toISOString();
      } else {
        due_from = this.$moment().toISOString();
      }

      let due_to = null;
      if (isCustomDue) {
        due_to = this.$moment(
          `${stateDueDateTo} ${stateDueTimeTo}`
        ).toISOString();
      } else {
        due_to = this.$moment().add(24, "hour").toISOString();
      }

      this.$emit("change", {
        name,
        description,
        contacts,
        due_from,
        due_to,
        subdivisions,
        request_type,
        status,
      });
    },

    onChangeSubdivisions() {
      this.validateSubdivisions();
      this.changeForm();
    },

    onChangeDepartments() {
      this.validateDepartments();
      this.changeForm();
    },

    getCompanies() {
      axios.get("subdivisions/tree").then((response) => {
        this.companies = response.data.data ? response.data.data : [];
      });
    },

    onChangeDueDateFrom() {
      this.dueDateFromMenu = false;

      this.changeForm();
    },

    onChangeDueTimeFrom() {
      this.$refs.dueTimeFromMenu.save(this.stateDueTimeFrom);

      this.changeForm();
    },

    onChangeDueDateTo() {
      this.dueDateToMenu = false;

      this.changeForm();
    },

    onChangeDueTimeTo() {
      this.$refs.dueTimeToMenu.save(this.stateDueTimeTo);

      this.changeForm();
    },

    getDepartments() {
      axios.get("departments/tree").then((response) => {
        this.departments = response.data.data ? response.data.data : [];
        if (this.departments.length) {
          this.department = this.departments[0].id;
        }
      });
    },

    validateSubdivisions() {
      this.validSubdivisions = true;

      if (!this.stateSubdivisions.length) {
        this.validSubdivisions = false;
      }

      return this.validSubdivisions;
    },

    validateDepartments() {
      this.validDepartments = true;

      if (!this.stateDepartment) {
        this.validDepartments = false;
      }

      return this.validDepartments;
    },

    validateEmail(v) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(v);
    },

    onFocusPhone() {
      if (!this.statePhone) {
        this.statePhone = "+7 (";
      }
    },

    onBlurPhone() {
      if (this.statePhone === "+7 (") {
        this.statePhone = "";
      }
    },
  },

  computed: {
    dueFromMin() {
      const date = this.$moment().format(dateFormat);

      return date;
    },

    dueFromMax() {
      const date = this.stateDueDateTo;

      return date;
    },

    dueToMin() {
      const date = this.stateDueDateFrom || this.dueFromMin;

      return date;
    },

    emailLabel() {
      let label = this.$ml.get("requests.email");

      if (!this.statePhone) {
        label += " *";
      }

      return label;
    },

    phoneLabel() {
      let label = this.$ml.get("requests.phone");

      if (!this.stateEmail) {
        label += " *";
      }

      return label;
    },

    isReadonly() {
      if (this.status === 0) {
        return false;
      }

      return true;
    },

    requestTypeInt() {
      const { isRegByQR, isRegByKiosk } = this;

      let requestType = "";

      if (isRegByQR) {
        requestType += "1";
      }

      if (isRegByKiosk) {
        requestType += "0";
      }

      requestType = parseInt(requestType);

      return requestType;
    },
  },

  created() {
    this.getCompanies();
    this.getDepartments();
  },
};
</script>
