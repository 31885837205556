var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{staticClass:"p-3"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-row',{attrs:{"justify":"start","align":"center"}},[_c('v-col',{staticClass:"pb-0 pt-0",attrs:{"cols":"auto"}},[_c('v-switch',{attrs:{"label":_vm.$ml.get('requests.showCanceled')},model:{value:(_vm.isShowCanceled),callback:function ($$v) {_vm.isShowCanceled=$$v},expression:"isShowCanceled"}})],1),_c('v-col',{staticClass:"pb-0 pt-0",attrs:{"cols":"auto"}},[_c('v-switch',{attrs:{"label":_vm.$ml.get('requests.showAll')},model:{value:(_vm.isShowAll),callback:function ($$v) {_vm.isShowAll=$$v},expression:"isShowAll"}})],1)],1)],1),(
        _vm.$auth.user().type_user == 'admin' ||
        _vm.$auth.user().roles.includes('requests')
      )?_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-row',{attrs:{"justify":"start","justify-md":"end","align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"to":{ name: 'addRequest' },"color":"primary"}},[_vm._v(_vm._s(_vm.$ml.get("button.add")))])],1)],1)],1):_vm._e()],1),_c('v-row',[_c('v-col',[(_vm.loading)?_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"table-tbody"}}):_vm._e(),_c('Datatable',{attrs:{"dblclick":_vm.goToEdit,"fields":_vm.fields,"searchfn":_vm.searchfn,"rows":_vm.data,"rowstyle":_vm.rowStyle},scopedSlots:_vm._u([{key:"header",fn:function(){return undefined},proxy:true},{key:"footer",fn:function(){return [_c('vue-pagination',{attrs:{"pagination":_vm.pagination,"offset":_vm.offset},on:{"paginate":function($event){return _vm.getRequests()}}})]},proxy:true},{key:"item.statusText",fn:function(ref){
      var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColorByStatus(item.status),"outlined":""}},[_vm._v(" "+_vm._s(item.statusText)+" ")])]}},{key:"item.requestType",fn:function(ref){
      var item = ref.item;
return [(item.requestType.includes(1))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-qrcode-scan ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("requests.byQR")))])]):_vm._e(),(item.requestType.includes(0))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-tablet ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("requests.byKiosk")))])]):_vm._e()]}},{key:"item.requestConfirm",fn:function(ref){
      var item = ref.item;
return [(_vm.isRequestConfirm(item.status))?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"green"},on:{"click":function($event){return _vm.confirmRequest(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-check ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("requests.confirmRequest")))])])],1),_c('v-spacer'),_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.cancelRequest(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-close ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("requests.cancelRequest")))])])],1)],1):_vm._e()]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$ml.get("requests.notRequests"))+" ")]),(_vm.isFailure)?_c('v-alert',{attrs:{"border":"top","color":"red lighten-2","dark":""}},[_vm._v(" "+_vm._s(_vm.$ml.get("message.errorLoading"))+" ")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }