<template>
  <div>
    <v-row>
      <v-col cols="12" md="6">
        <v-select
          v-model="systemmode"
          :items="systemmodes"
          item-text="name"
          item-value="val"
          :label="$ml.get('devices.system_mode')"
          outlined
        ></v-select>
        <v-select
          v-model="displayMode"
          :items="displaymodes"
          item-text="name"
          item-value="val"
          :label="$ml.get('devices.DisplayMode')"
          outlined
        ></v-select>
        <v-select
          v-model="aspoofing"
          :disabled="$auth.user().type_user != 'admin'"
          :items="antispoofing"
          item-text="name"
          item-value="val"
          :label="$ml.get('devices.AntispoofingMode')"
          outlined
        ></v-select>
      </v-col>
      <v-col cols="12" md="6">
        <v-select
          v-if="!controlModeSelect(controlMode)"
          v-model="controlMode"
          :items="controls"
          item-text="name"
          item-value="val"
          :label="$ml.get('devices.controlMode')"
          outlined
        ></v-select>
        <v-row v-else style="margin-top: -12px">
          <v-col cols="6">
            <v-select
              v-model="controlMode"
              :items="controls"
              item-text="name"
              item-value="val"
              :label="$ml.get('devices.controlMode')"
              outlined
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              v-if="controlModeSelect(controlMode)"
              v-model="cardtype"
              :items="cardtypes"
              item-text="name"
              item-value="val"
              :label="$ml.get('card.type')"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-select
          v-model="authenticationType"
          :items="authentications"
          item-text="name"
          item-value="val"
          :label="$ml.get('devices.authenticationType')"
          outlined
        ></v-select>
        <v-select
          v-model="showName"
          :items="shownames"
          item-text="name"
          item-value="val"
          :label="$ml.get('devices.ShowFullname')"
          outlined
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-subheader
          >{{ $ml.get("devices.recognition_range") }}
          <template v-if="l.temp_meas == 'c'">
            {{ $ml.get("devices.recognition_metr") }}
          </template>
          <template v-else>
            {{ $ml.get("devices.recognition_imper") }}
          </template>
        </v-subheader>
        <v-slider
          v-model="recognitionRange"
          class="align-center"
          :max="getFft(recognitionRangeMax)"
          :min="getFft(0.3)"
          step="0.1"
          hide-details
        >
          <template v-slot:append>
            <v-text-field
              v-model="recognitionRange"
              class="mt-0 pt-0"
              hide-details
              :max="getFft(recognitionRangeMax)"
              :min="getFft(0.3)"
              step="0.1"
              type="number"
              style="width: 60px"
              outlined
            ></v-text-field>
          </template>
        </v-slider>
        <v-subheader>{{ $ml.get("devices.recognition_face") }}</v-subheader>
        <v-slider
          v-model="recognitionFace"
          class="align-center"
          max="1"
          min="0"
          step="0.01"
          hide-details
        >
          <template v-slot:append>
            <v-text-field
              v-model="recognitionFace"
              class="mt-0 pt-0"
              hide-details
              max="1"
              min="0"
              step="0.01"
              type="number"
              style="width: 70px"
              outlined
            ></v-text-field>
          </template>
        </v-slider>
        <v-subheader v-if="regimMask">{{
          $ml.get("devices.recognition_mask")
        }}</v-subheader>
        <v-slider
          v-if="regimMask"
          v-model="recognitionMask"
          class="align-center"
          max="1"
          min="0"
          step="0.01"
          hide-details
        >
          <template v-slot:append>
            <v-text-field
              v-model="recognitionMask"
              class="mt-0 pt-0"
              hide-details
              max="1"
              min="0"
              step="0.01"
              type="number"
              style="width: 70px"
              outlined
            ></v-text-field>
          </template>
        </v-slider>
      </v-col>
      <v-col cols="12" md="6">
        <v-subheader>{{ $ml.get("devices.pass_timeout") }}</v-subheader>
        <v-slider
          v-model="mltimeout"
          class="align-center"
          max="90"
          min="0"
          step="1"
          hide-details
        >
          <template v-slot:append>
            <v-text-field
              v-model="mltimeout"
              class="mt-0 pt-0"
              hide-details
              max="90"
              min="0"
              step="1"
              type="number"
              style="width: 60px"
              outlined
            ></v-text-field>
          </template>
        </v-slider>
        <!-- TODO: восстановить когда появится функционал авторегистрации -->
        <!-- <div>
          <v-switch
            v-model="autoregistrationMode"
            :label="$ml.get('devices.autoregistrationMode')"
          ></v-switch>
        </div> -->
        <div>
          <v-switch
            :disabled="!l.temperature_enabled"
            v-model="temperatureEnable"
            :label="$ml.get('devices.temperatureEnable')"
          ></v-switch>
        </div>
        <div v-if="temperatureEnable">
          <v-switch
            v-model="showThempInfo"
            :label="$ml.get('devices.ShowThempInfo')"
          ></v-switch>
        </div>
        <div v-if="temperatureEnable && systemmode != 'temperature_pass'">
          <v-switch
            v-model="showAccess"
            :label="$ml.get('devices.ShowAccess')"
          ></v-switch>
        </div>
        <v-switch
          v-if="regimMask"
          v-model="maskEnabled"
          :label="$ml.get('setting.maskEnabled')"
        ></v-switch>
      </v-col>
    </v-row>
    <password
      v-if="pdlg"
      v-model="pconfirm"
      @toggle="confirm($event)"
    ></password>
  </div>
</template>
<script>
import axios from "axios";
import password from "../../../components/PasswordDlg.vue";
import moment from "moment-timezone";

export default {
  props: ["value"],
  components: {
    password,
  },
  watch: {
    aspoofing(val) {
      //console.log(val, this.antispoofingMode);
      if (val != this.antispoofingMode && val == "off") {
        this.pdlg = true;
        this.pconfirm = "";
      } else {
        this.antispoofingMode = val;
      }
      this.recognitionRangeMax = 3;
      //this.recognitionRangeMax = val != "off" ? 2 : 3;

      this.store();
    },
    temperatureEnable() {
      this.store();
    },
    systemmode(systemmode) {
      this.store();

      if (systemmode !== "default") {
        this.authenticationType = "face";
      }
    },
    displayMode() {
      this.store();
    },
    controlMode() {
      this.store();
    },
    cardtype() {
      this.store();
    },
    authenticationType() {
      this.store();
    },
    showName() {
      this.store();
    },
    recognitionRange() {
      this.store();
    },
    recognitionFace() {
      this.store();
    },
    recognitionMask() {
      this.store();
    },
    mltimeout() {
      this.store();
    },
    autoregistrationMode() {
      this.store();
    },
    maskEnabled() {
      this.store();
    },
    showFullname() {
      this.store();
    },
    showAccess() {
      this.store();
    },
    showThempInfo() {
      this.store();
    },
  },
  data() {
    return {
      pdlg: false,
      loading: false,
      l: {
        lang: "en",
        show: false,
        temp_meas: "c",
        temperature_range: [],
        temperature_enabled: false,
        temperature_rise: false,
        antispoofing_enabled: false,
      },
      recognitionRange: this.getFft(2),
      temperatureEnable: false,
      showFullname: "",
      controlMode: "no",
      authenticationsType: "face",
      showName: "full",
      controls: [
        { name: this.$ml.get("controls.card"), val: "card" },
        { name: this.$ml.get("controls.cardConfirm"), val: "card_confirm" },
        { name: this.$ml.get("controls.cardPass"), val: "card_pass" },
        //{ name: this.$ml.get("controls.cardPassConfirm"), val: "card_pass_confirm" },
        { name: this.$ml.get("controls.dryContact"), val: "dry_contact" },
        { name: this.$ml.get("controls.door"), val: "door" },
        { name: this.$ml.get("controls.doorInverted"), val: "door_inverted" },
        { name: this.$ml.get("controls.onlyLed"), val: "only_led" },
        { name: this.$ml.get("controls.no"), val: "no" },
      ],
      shownames: [
        { name: this.$ml.get("devices.showNo"), val: "no" },
        { name: this.$ml.get("devices.showFullName"), val: "full" },
        { name: this.$ml.get("devices.showShortName"), val: "short" },
      ],
      enabled: true,
      mltimeout: 0,
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => v.length <= 20 || "Name must be less than 20 characters",
      ],
      systemmode: "default",
      systemmodes: [
        {
          val: "default",
          name: "Default",
        },
        {
          val: "temp_sensor",
          name: this.$ml.get("devices.ThermalSensor"),
        },
        {
          val: "temperature_pass",
          name: this.$ml.get("devices.TemperaturePass"),
        },
      ],
      cardtype: "WDEC26",
      cardtypes: [
        { name: "Wiegand26", val: "WDEC26" },
        { name: "Wiegand34", val: "WDEC34" },

        { name: "Wiegand37", val: "WDEC37" },
        { name: "Wiegand42", val: "WDEC42" },
        { name: "Wiegand44", val: "WDEC44" },
        { name: "Wiegand48", val: "WDEC48" },

        { name: "Wiegand58", val: "WDEC58" },
        { name: "WiegandUni", val: "UDEC" },
        { name: "Touch Memory", val: "TM" },
      ],
      recognitionRangeMax: 3,
      recognitionFace: 0.7,
      recognitionMask: 0.7,
      regimMask: false,
      showAccess: false,
      showThempInfo: false,
      maskEnabled: false,
      passlist: ["only_led", "door", "door_inverted", "card"],
      errorMessage: "",
      autoregistrationMode: false,
      authenticationType: "face",
      antispoofingMode: "print",
      aspoofing: "print",
      pconfirm: "",
      apiVersion: "",
      deviceType: "OGATE",
      featuresVersion: {
        basic: {
          version: "",
          thresholds: {
            min: 0.5,
            max: 0.6,
          },
        },
        masked: {
          version: "",
          thresholds: {
            min: 0.5,
            max: 0.6,
          },
        },
      },
      antispoofing: [
        { val: "off", name: this.$ml.get("button.off") },
        { val: "phone", name: this.$ml.get("button.AntispoofingPhone") },
        //{ val: "print", name: this.$ml.get("button.AntispoofingPhoto") },
        { val: "print", name: this.$ml.get("button.AntispoofingFull") },
      ],
      displayMode: "static",
      displaymodes: [
        { val: "static", name: this.$ml.get("devices.modeDiaplayStatic") },
        { val: "video", name: this.$ml.get("devices.modeDiaplayVideo") },
      ],
    };
  },
  methods: {
    confirm(ev) {
      if (ev && this.pconfirm == "facechain123") {
        this.antispoofingMode = this.aspoofing;
      } else {
        this.aspoofing = this.antispoofingMode;
      }
      this.store();
      this.pdlg = false;
    },
    controlModeSelect(mode) {
      console.log(mode);
      return mode.indexOf("card") != -1;
    },
    getFft(m) {
      if (this.l !== undefined && this.l["temp_meas"] == "f") {
        return Math.round(m * 3.28084 * 10) / 10;
      } else {
        return m;
      }
    },
    getMetr(m) {
      if (this.l !== undefined && this.l["temp_meas"] == "f") {
        return Math.round((m / 3.28084) * 10) / 10;
      } else {
        return m;
      }
    },
    store() {
      let temperature_enable = this.temperatureEnable ? "on" : "off";
      if (this.systemmode == "temperature_pass") {
        this.showAccess = false;
      }
      let basic = this.featuresVersion.basic;
      let masked = this.featuresVersion.masked;
      let data = {
        api_version: this.apiVersion,
        temperature_enable: temperature_enable,
        recognition_range: this.getMetr(this.recognitionRange),
        control_mode: this.controlMode,
        card_type: this.cardtype,
        authentication_type: this.authenticationType,
        pass_timeout: this.mltimeout,
        system_mode: this.systemmode,
        recognition_face: this.recognitionFace,
        recognition_mask: this.recognitionMask,
        mask_enabled: this.maskEnabled,
        show_name: this.showName,
        show_themp_info: this.showThempInfo,
        show_access: this.showAccess,
        antispoofing_mode: this.antispoofingMode,
        display_mode: this.displayMode,
        autoregistration_mode: this.autoregistrationMode,
        device_type: this.deviceType,
        features_version: { basic, masked },
      };
      this.$emit("input", data);
    },
    getRegimMask() {
      let _this = this;
      axios.get("/setting/MASK_RECOGNITION").then((response) => {
        if (response.status == 200) {
          let val = response.data.data.value || "off";
          _this.regimMask = val == "on";
        }
      });
    },
    getData(data) {
      //let data = this.value;
      this.temperatureEnable = data["temperature_enable"] == "on";
      // if (this.temperatureEnable && !this.l.temperature_enabled) {
      //   this.temperatureEnable = false
      // }
      this.recognitionRange = this.getFft(data["recognition_range"] || 2);
      this.recognitionFace = data["recognition_face"] || 0;
      this.recognitionMask = data["recognition_mask"] || 0;
      this.controlMode = data["control_mode"] || "no";
      this.apiVersion = data["api_version"] || "";

      console.log("test", this.controlMode, data["control_mode"], data);

      this.authenticationType = data["authentication_type"] || "face";
      this.systemmode = data["system_mode"] || "default";
      this.showFullname = data["show_fullname"] || false;
      this.showName = data["show_name"] || "full";
      this.showAccess = data["show_access"] || false;
      this.showThempInfo = data["show_themp_info"] || false;
      this.maskEnabled = data["mask_enabled"] || false;
      this.mltimeout = data["pass_timeout"] || 0;
      this.cardtype = data["card_type"] || "WDEC26";
      this.autoregistrationMode = data["autoregistration_mode"] || false;
      this.aspoofing = data["antispoofing_mode"] || "off";
      this.antispoofingMode = this.aspoofing;
      this.displayMode = data["display_mode"] || "static";
      this.deviceType = data["device_type"] || "OGATE";
      this.featuresVersion = data["features_version"] || {
        basic: { version: "", thresholds: { min: 0.5, max: 0.6 } },
        masked: { version: "", thresholds: { min: 0.5, max: 0.6 } },
      };
    },
    getSettingValues() {
      if (localStorage.getItem("ovision-ml")) {
        try {
          this.l = JSON.parse(localStorage.getItem("ovision-ml"));
          //this.settings = response.data.settings || {};
          if (this.l.temperature_range != undefined) {
            let range = this.l.temperature_range.split(",");
            if (range.length > 1) this.tAlarm = range[1];
          }
          this.loading = true;
        } catch (err) {
          this.loading = true;
        }
      }
    },
  },

  computed: {
    authentications() {
      let list = [{ name: this.$ml.get("devices.auth.face"), val: "face" }];

      if (this.systemmode === "default") {
        list = [
          ...list,

          { name: this.$ml.get("devices.auth.card"), val: "card" },
          { name: this.$ml.get("devices.auth.qrCode"), val: "qr_code" },

          {
            name: this.$ml.get("devices.auth.faceOrCard"),
            val: "face_or_card",
          },
          {
            name: this.$ml.get("devices.auth.faceOrQrCode"),
            val: "face_or_qr_code",
          },
          {
            name: this.$ml.get("devices.auth.faceOrCardOrQrCode"),
            val: "face_or_card_or_qr_code",
          },
        ];
      }

      switch (this.deviceType) {
        case "OGATEV8":
          break;
        default:
          list = [
            ...list,

            {
              name: this.$ml.get("devices.auth.faceAndCard"),
              val: "face_and_card",
            },
            {
              name: this.$ml.get("devices.auth.faceAndQrCode"),
              val: "face_and_qr_code",
            },
            {
              name: this.$ml.get("devices.auth.faceAndCardOrQrCode"),
              val: "face_and_card_or_qr_code",
            },
          ];
          break;
      }

      return list;
    },
  },

  created() {
    this.getSettingValues();
  },
  mounted() {
    if (this.value) {
      this.getData(this.value);
      this.getRegimMask();
    }
  },
};
</script>
