<template>
  <div style="margin-top: 40px; text-align: left; padding-left: 20px">
    <h5>{{ $ml.get("card.access_cards") }}</h5>
    <template v-if="cards">
      <ul style="list-style-type: none; padding-left: 0">
        <li class="card-item" v-for="item in cards" :key="item.name">
          <span
            class="card-icon mdi mdi-credit-card"
            v-if="item.type_card == 'card'"
          ></span>
          <span
            class="card-icon mdi mdi-qrcode"
            v-if="item.type_card == 'qr'"
          ></span>
          <span
            class="card-icon mdi mdi-account-key"
            v-if="item.type_card == 'pin'"
          ></span>
          <a href="#" class="card-name" @click="editCard(item)">{{
            item.name
          }}</a>
          <a
            class="btn-delete mdi mdi-close"
            @click="deleteCard(item)"
            :title="$ml.get('button.delete')"
          ></a>
        </li>
      </ul>
    </template>
    <p>
      <a @click="addCard">{{ $ml.get("card.add_new_card") }}</a>
    </p>
    <template v-if="showDialog">
      <CardEditAccess v-model="card" @toggle="toggle($event)" />
    </template>
  </div>
</template>

<script>
import CardEditAccess from "./cardaccess/CardEditAccess";
export default {
  props: ["value"],
  components: { CardEditAccess },
  data() {
    return {
      id: 0,
      card: {
        id: 0,
        name: "",
        full_number: "",
        expire_date: "",
        active: true,
      },
      cards: [],
      showDialog: false,
      editing: false,
    };
  },
  methods: {
    toggle(e) {
      this.showDialog = e;
      if (!this.editing && this.card.name) {
        this.cards.push(this.card);
      }
      this.$emit("result", this.cards);
    },
    editCard(item) {
      this.editing = true;
      this.card = item;
      this.showDialog = true;
    },
    addCard() {
      this.editing = false;
      this.showDialog = true;
      this.card = {};
    },
    deleteCard(item) {
      let index = this.cards.indexOf(item);
      if (index != -1) {
        this.cards.splice(index, 1);
      }
    },
    calc(fullnumber) {
      let number = "";
      let series = "";
      if (fullnumber) {
        let hex = parseInt(fullnumber, 10).toString(16);
        hex = "000000" + hex;
        let hnum = hex.substr(hex.length - 4, 4);
        if (hnum != "") {
          number = parseInt(hnum, 16).toString();
        }
        let hser = hex.substr(0, hex.length - 4);
        if (hser != "") {
          series = parseInt(hser, 16).toString();
        }
      }
      return { number, series };
    },
  },
  watch: {
    value(item) {
      this.cards = this.value || [];
    },
    // card(val) {
    //   if (!this.editing && val.name) {
    //     this.cards.push(val)
    //   }
    // }
  },
};
</script>

<style scoped>
.card-item {
  height: 30px;
  display: block;
}
.card-icon {
  font-size: 20px;
  width: 20px;
  height: 20px;
  float: left;
  margin-right: 5px;
}
.card-name {
  float: left;
  margin-right: 5px;
  width: 200px;
  font-size: 20px;
}
.btn-delete {
  width: 18px;
  height: 18px;
  background-color: red;
  color: white;
  font-size: 14px;
  border-radius: 3px;
  border: 1px solid #000;
  font-style: normal;
  text-decoration: none;
}

@media screen and (max-width: 1024px) {
  .card-item {
    height: 25px;
  }
  .card-icon {
    font-size: 16px;
    width: 16px;
    height: 16px;
    margin-right: 5px;
  }
  .card-name {
    float: left;
    margin-right: 5px;
    width: 150px;
    font-size: 16px;
  }
  .btn-delete {
    width: 16px;
    height: 16px;
    font-size: 16px;
  }
}

@media screen and (max-width: 960px) {
  .card-item {
    height: 22px;
  }
  .card-icon {
    font-size: 14px;
    width: 14px;
    height: 14px;
    margin-right: 5px;
  }
  .card-name {
    float: left;
    margin-right: 5px;
    width: 100px;
    font-size: 14px;
  }
  .btn-delete {
    width: 14px;
    height: 14px;
    font-size: 14px;
  }
}
@media screen and (max-width: 860px) {
}

@media screen and (max-width: 680px) {
}
</style>
