<template>
  <v-container>
    <v-app>
      <v-card elevation="2">
        <v-card-text>
          <v-alert v-if="isFailure && errorMessage" type="error">{{
            errorMessage
          }}</v-alert>

          <v-row class="mb-4">
            <v-col>
              <v-btn @click="goBack">{{ $ml.get("button.back") }}</v-btn>
            </v-col>
          </v-row>

          <Form ref="addForm" :description="description" @change="onChange" />

          <v-row justify="end">
            <v-col cols="auto">
              <v-btn color="primary" @click="add">{{
                $ml.get("button.add")
              }}</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-app>
  </v-container>
</template>

<script>
import Form from "./form";

export default {
  components: { Form },

  data() {
    return {
      description: localStorage.getItem("requestDescription") || "",

      isPending: false,
      isSuccess: false,
      isFailure: false,
      errorMessage: "",
      form: {
        subdivisions: [],
      },
    };
  },

  methods: {
    goBack() {
      this.$router.push({ path: `/requests` });
    },

    onChange(form) {
      this.form = { ...form };
    },

    add() {
      const validate = this.$refs.addForm.$refs.form.validate();
      const validateSubdivisions = this.$refs.addForm.validateSubdivisions();
      const validateDepartments = this.$refs.addForm.validateDepartments();

      if (validate && validateSubdivisions && validateDepartments) {
        const { form } = this;

        this.isPending = true;
        this.isSuccess = false;
        this.isFailure = false;

        axios
          .post("requests", form)
          .then(() => {
            this.isPending = false;
            this.isSuccess = true;
            this.isFailure = false;

            localStorage.setItem("requestDescription", form.description);

            this.$router.push({ name: "requests" });
          })
          .catch((error) => {
            this.isPending = false;
            this.isSuccess = false;
            this.isFailure = true;
            this.errorMessage = error.response.data.status.message;
          });
      }
    },
  },
};
</script>
