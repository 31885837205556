<template>
  <div>
    <div class="vue-treeselect">
      <div class="vue-treeselect__control">
        <div v-for="it in values" :key="it">
          <div class="value-container">
            <div class="multi-value-item" @click="modaledit(it)">
              <span class="access-item">{{ it.name }}</span>
              <span class="access-label">{{ accesslabel(it.access) }}</span>
            </div>
          </div>
          <div class="x-container" title="Clear all" @click="clear(it)">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 348.333 348.333"
              class="vue-treeselect__x"
            >
              <path
                d="M336.559 68.611L231.016 174.165l105.543 105.549c15.699 15.705 15.699 41.145 0 56.85-7.844 7.844-18.128 11.769-28.407 11.769-10.296 0-20.581-3.919-28.419-11.769L174.167 231.003 68.609 336.563c-7.843 7.844-18.128 11.769-28.416 11.769-10.285 0-20.563-3.919-28.413-11.769-15.699-15.698-15.699-41.139 0-56.85l105.54-105.549L11.774 68.611c-15.699-15.699-15.699-41.145 0-56.844 15.696-15.687 41.127-15.687 56.829 0l105.563 105.554L279.721 11.767c15.705-15.687 41.139-15.687 56.832 0 15.705 15.699 15.705 41.145.006 56.844z"
              ></path>
            </svg>
          </div>
        </div>
        <div style="padding: 5px" v-if="!values.length" class="float-left">
          <span
            style="margin-top: 7px; display: inline-block"
            class="text-danger"
            >{{ $ml.get("message.NoSubdivisionSelected") }}</span
          >
        </div>
        <div style="padding: 5px" class="float-right">
          <v-btn color="primary" @click="modaledit()">
            {{ $ml.get("button.add") }}
          </v-btn>
        </div>
      </div>
    </div>
    <v-row justify="center" v-if="edit">
      <v-dialog v-model="dialog" persistent max-width="550px">
        <v-card class="access-list">
          <v-card-title>
            <span class="headline">{{ $ml.get("user.setUpAccess") }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <div class="tree-box">
                    <v-treeview
                      v-if="enable"
                      selectable
                      return-object="true"
                      item-disabled="locked"
                      :items="items"
                      item-text="label"
                      open-all="true"
                      v-model="acclist"
                    />
                  </div>
                  <v-text-field
                    v-if="!enable"
                    :readonly="!enable"
                    v-model="acc.label"
                    outlined
                  />
                  <div v-if="!enable" class="shedule">
                    <div
                      v-for="(schedule, it) in schedules"
                      :key="it"
                      class="shedule-item"
                    >
                      <v-btn
                        icon
                        color="red"
                        class="shedule-item__close-btn"
                        @click="sheduleRemove(it)"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                      <TimeAccess v-model="schedules[it]" />
                    </div>
                    <v-btn @click="addNewShedule" class="mt-5">{{
                      $ml.get("schedule.add")
                    }}</v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="edit = false">{{
              $ml.get("button.close")
            }}</v-btn>
            <v-btn
              v-if="acc || acclist.length"
              color="primary"
              text
              @click="modalstore"
              >{{ $ml.get("button.save") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
import TimeAccess from "./TimeAccess";

export default {
  props: ["items", "values", "disabledEditItem"],
  data() {
    return {
      item: {
        id: 0,
        access: "{}",
        name: "",
      },
      edit: false,
      dialog: true,
      enable: false,
      acc: null,
      acclist: [],
      schedules: [],
      schedule: {
        weekdays: "1111100",
        date_from: "",
        date_to: "",
        time_from: "",
        time_to: "",
      },
      days: [
        "Week_1",
        "Week_2",
        "Week_3",
        "Week_4",
        "Week_5",
        "Week_6",
        "Week_0",
      ],
    };
  },
  components: { TimeAccess },
  methods: {
    formatDate(date) {
      if (!date) return "";

      const [year, month, day] = date.split("-");
      let lang = localStorage.getItem("ovision-ml").lang || "ru";
      if (lang == "ru") {
        return `${day}.${month}.${year}`;
      } else return `${month}/${day}/${year}`;
    },
    modalstore() {
      this.edit = false;
      if (this.acclist.length) {
        this.acclist.forEach((item) => {
          let indexOfStevie = this.values.findIndex((i) => i.id === item.id);
          console.log(item, indexOfStevie);
          if (indexOfStevie == -1) {
            this.values.push({ id: item.id, name: item.label });
          }
        });
      } else if (this.acc) {
        this.item.id = this.acc.id;
        this.item.name = this.acc.label;
        this.item.access = [];
        this.schedules.forEach((item) => {
          console.log("store:", item);
          if (item.weekdays == "0000000") {
            item.weekdays = "";
          }
          if (
            !item.full_access ||
            item.date_from ||
            item.date_to ||
            item.time_from ||
            item.time_to ||
            item.weekdays
          ) {
            this.item.access.push(item);
          }
        });

        if (this.enable) {
          let indexOfStevie = this.values.findIndex(
            (i) => i.id === this.item.id
          );
          if (indexOfStevie == -1) {
            this.values.push(this.item);
          } else {
            this.$dialog
              .confirm(
                `<h3>${this.$ml.get("message.Delete")}</h3>` +
                  this.$ml.get("message.confirmDelete"),
                {
                  loader: false,
                  okText: this.$ml.get("button.delete"),
                  backdropClose: true,
                }
              )
              .then(function () {});
            console.log("Find ID in list ", this.item.id);
          }
        }
      }

      this.$emit("save");
    },
    modaledit(it) {
      if (it === undefined) {
        this.item = {};
        this.enable = true;
        this.acc = null;
        this.schedules = [];
        this.acclist = [];
        this.edit = true;
        return;
      } else {
        if (this.disabledEditItem) {
          return;
        }

        this.item = it;
        console.log(it);
        this.acc = {
          id: it.id,
          label: it.name,
          access: it.access,
        };
        this.enable = false;
        console.log("Edit");
      }
      if (this.item.access) {
        //this.schedule = JSON.parse(this.item.access);
        try {
          this.schedules = this.item.access;
        } catch (err) {
          console.log(err);
        }
      }
      this.edit = true;
    },
    clear(it) {
      this.values = this.values.filter(function (x) {
        if (it.id != x.id) {
          return x;
        }
      });
      this.$emit("values", this.values);
    },
    accesslabel(access) {
      if (!access) {
        return "";
      }
      // let d = {
      //   weekdays: "",
      //   date_from: "",
      //   date_to: "",
      //   time_from: "",
      //   time_to: "",
      // };
      let label = "";
      access.forEach((d) => {
        if (label) {
          label += ", ";
        }
        label += this.getWeekString(d.weekdays);
        if ((d.date_from || d.date_from) && label) label += " / ";
        if (d.date_from) {
          label += this.formatDate(d.date_from);
        }
        if (d.date_to) {
          label += " - " + this.formatDate(d.date_to);
        }
        if (d.time_from != d.time_to) {
          if (label) label += " / ";
          if (d.time_from) {
            label += d.time_from;
          }
          if (d.time_to) {
            label += " - " + d.time_to;
          }
        }
      });
      return label;
    },
    getWeekString(weekdays) {
      if (weekdays) {
        let r = [];
        let val = weekdays.toString().split("");
        console.log(val);
        if (val.length == 7) {
          val.forEach((it, i) => {
            if (it == 1) {
              r.push(this.$ml.get("dtpicker." + this.days[i]));
            }
          });
        }
        return r.join(",");
      }
      return "";
    },
    addNewShedule() {
      this.schedules.push({
        id: +new Date(),
        checkWeek: false,
        checkDate: false,
        menu1: false,
        menu2: false,
        menu3: false,
        menu4: false,
        timeFrom: "00:00",
        timeTo: "00:00",
        weekdays: "1111100",
        dateFrom: "",
        dateTo: "",
      });
    },
    sheduleRemove(id) {
      this.schedules = this.schedules.filter((_, it) => it !== id);
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
@import "@/assets/sass/_variables.scss";

.access-item {
  cursor: pointer;
  display: inline-table;
  background: $primary;
  padding: 2px;
  border: 1px solid transparent;
  border-radius: 2px;
  color: $white;
  font-size: 12px;
  vertical-align: top;
}
.access-label {
  font-size: 11px;
}
.vue-treeselect__x-container:hover {
  color: #e53935;
}
.vue-treeselect div,
.vue-treeselect span {
  box-sizing: border-box;
}
.value-container {
  display: table-cell;
  position: relative;
  width: 100%;
  vertical-align: middle;
}
.x-container {
  display: table-cell;
  vertical-align: middle;
  width: 20px;
  padding: 7px;
  text-align: center;
  line-height: 0;
  cursor: pointer;
  color: #ccc;
  -webkit-animation: 200ms vue-treeselect-animation-fade-in
    cubic-bezier(0.075, 0.82, 0.165, 1);
  animation: 200ms vue-treeselect-animation-fade-in
    cubic-bezier(0.075, 0.82, 0.165, 1);
}
.multi-value-item-container {
  display: inline-block;
  padding-top: 5px;
  padding-right: 5px;
  vertical-align: top;
}
.multi-value-item {
  display: inline-block;
  padding-top: 5px;
  padding-right: 5px;
  vertical-align: top;
}
.multi-value {
  box-sizing: border-box;
  margin-bottom: 5px;
  display: inline-block;
  position: relative;
  width: 100%;
  vertical-align: middle;
}
.value-container {
  cursor: text;
  display: table-cell;
  position: relative;
  width: 100%;
  vertical-align: middle;
}
.arrow-container {
  display: table-cell;
  vertical-align: middle;
  width: 20px;
  padding: 7px;
  text-align: center;
  line-height: 0;
  cursor: pointer;
}
</style>
<style lang="scss">
.shedule-item {
  position: relative;
  width: 90%;
  &__close-btn {
    position: absolute !important;
    right: 0;
    top: 0;
    z-index: 1;
  }
}
// .shedule {
//   overflow-y: auto;
//   margin-left: -10px;
//   margin-right: -10px;
//   height: 60%;
// }

.access-list {
  .v-treeview-node__label {
    white-space: break-spaces;
  }
}
</style>
