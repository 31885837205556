import VueRouter from "vue-router";
import Page404 from "./pages/Page404.vue";

import Dashboard from "./pages/Dashboard.vue";
import Register from "./components/Register.vue";
import DeviceList from "./pages/Device";
import Schedules from "./pages/Schedules";
//import ShowDevices from "./pages/ShowDevices";
import ShowUser from "./pages/ShowUser";
import EditUser from "./pages/EditUser";
import Users from "./pages/Users";
import Guards from "./pages/Guards";
import GuardEdit from "./pages/GuardEdit";
import AddNewUser from "./pages/AddNewUser";
import ScheduleEdit from "./pages/schedule/ScheduleEdit";
import ViewLog from "./pages/ViewLog";
import BlackList from "./pages/BlackList";
import DeviceEdit from "./pages/device/DeviceEdit";
import Settings from "./pages/Settings";
import Requests from "./pages/requests";
import AddRequest from "./pages/requests/add";
import EditRequest from "./pages/requests/edit";
import EditPersonal from "./pages/personal/Edit";
import { MLBuilder } from "vue-multilanguage";

const router = new VueRouter({
  routes: [
    {
      path: "/devices",
      name: "devices",
      component: DeviceList,
      meta: {
        auth: true,
      },
    },
    {
      path: "/device/new",
      name: "addDevice",
      component: DeviceEdit,
      meta: {
        auth: true,
        permission: "admin",
        roles: ["deviceedit"],
      },
    },
    {
      path: "/device/:id",
      name: "editDevice",
      component: DeviceEdit,
      meta: {
        auth: true,
        permission: "admin",
        roles: ["deviceedit"],
      },
    },
    {
      path: "/users",
      name: "users",
      component: Users,
      meta: {
        auth: true,
      },
    },
    {
      path: "/blackList",
      name: "blackList",
      component: BlackList,
      meta: {
        auth: true,
      },
    },
    {
      path: "/user/new",
      name: "adduser",
      component: AddNewUser,
      meta: {
        auth: true,
        // permission: "admin",
        roles: ["useradd", "useredit", "userdel"],
      },
    },
    {
      path: "/user/:id",
      name: "showUser",
      component: ShowUser,
      meta: {
        auth: true,
      },
    },
    {
      path: "/user/:id/edit",
      name: "editUser",
      component: EditUser,
      meta: {
        auth: true,
        permission: "admin",
        roles: ["useradd", "useredit", "userdel"],
      },
    },
    {
      path: "/guards",
      name: "guards",
      component: Guards,
      meta: {
        auth: true,
        permission: "admin",
      },
    },
    {
      path: "/guard/new",
      name: "addGuard",
      component: GuardEdit,
      meta: {
        auth: true,
        permission: "admin",
      },
    },
    {
      path: "/guard/:id",
      name: "editGuard",
      component: GuardEdit,
      meta: {
        auth: true,
        permission: "admin",
      },
    },
    {
      path: "/",
      name: "dashboard",
      component: Dashboard,
      meta: {
        auth: true,
      },
    },
    {
      path: "/requests",
      name: "requests",
      component: Requests,
      meta: {
        auth: true,
      },
    },
    {
      path: "/requests/new",
      name: "addRequest",
      component: AddRequest,
      meta: {
        auth: true,
      },
    },
    {
      path: "/requests/:id",
      name: "editRequest",
      component: EditRequest,
      meta: {
        auth: true,
      },
    },
    {
      path: "/journal",
      name: "journal",
      component: ViewLog,
      meta: {
        auth: true,
        permission: "admin",
        roles: ["journal"],
      },
    },
    {
      path: "/settings",
      name: "settings",
      component: Settings,
      meta: {
        auth: true,
        permission: "admin",
        roles: ["adminedit"],
      },
    },
    {
      path: "/schedules",
      name: "schedules",
      component: Schedules,
      meta: {
        auth: true,
        permission: "admin",
        roles: ["schedule"],
      },
    },
    {
      path: "/schedule/new",
      name: "scheduleNew",
      component: ScheduleEdit,
      meta: {
        auth: true,
        permission: "admin",
        roles: ["schedule"],
      },
    },
    {
      path: "/schedule/:id",
      name: "scheduleEdit",
      component: ScheduleEdit,
      meta: {
        auth: true,
        permission: "admin",
        roles: ["schedule"],
      },
    },
    {
      path: "/personal/:id",
      name: "editPersonal",
      component: EditPersonal,
      meta: {
        auth: true,
      },
    },
    {
      path: "*",
      name: "dashboard",
      component: Dashboard,
    },
  ],
  linkActiveClass: "active",
  //mode: 'history'
});

export default router;
