require("./bootstrap");

import Vue from "vue";
import Vuelidate from "vuelidate";
import Notifications from "vue-notification";
import VueRouter from "vue-router";

import VuejsDialog from "vuejs-dialog";

// include the default style
import "vuejs-dialog/dist/vuejs-dialog.min.css";

//import VueConfirmDialog from "vue-confirm-dialog"

import axios from "axios";
import VueAxios from "vue-axios";
import App from "./App.vue";
import "./ml";

import router from "./router";
import vuetify from "./plugins/vuetify";

import VueFlashMessage from "vue-flash-message";
import enLocale from "element-ui/lib/locale/lang/en";
import ruLocale from "element-ui/lib/locale/lang/ru-RU";
import locale from "element-ui/lib/locale";

import "./plugins/vue-moment";
import "./plugins/vue-clipboard";

// Auth
import auth from "@websanova/vue-auth/dist/v2/vue-auth.esm.js";
import driverHttpAxios from "@websanova/vue-auth/dist/drivers/http/axios.1.x.esm.js";
import driverRouterVueRouter from "@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm.js";

import "./plugins/vmask";
import "./registerServiceWorker";

Vue.use(VueFlashMessage);

function setDateLang() {
  let l = localStorage.getItem("ovision-ml");
  if (l) {
    l = JSON.parse(l);
  }
  let lang = l.lang || "en";
  console.log(l, l.lang, lang);
  if (lang == "ru") {
    locale.use(ruLocale);
  } else {
    locale.use(enLocale);
  }
}
//Vue.use(setDateLang);
Vue.directive("language", (el, binding) => {
  setDateLang();
});

function debounce(fn, delay = 300) {
  var timeoutID = null;

  return function () {
    clearTimeout(timeoutID);

    var args = arguments;
    var that = this;

    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
}

Vue.directive("debounce", (el, binding) => {
  if (binding.value !== binding.oldValue) {
    // window.debounce is our global function what we defined at the very top!
    el.oninput = debounce((ev) => {
      el.dispatchEvent(new Event("change"));
    }, parseInt(binding.value) || 300);
  }
});

const ignoreWarnMessage =
  "The .native modifier for v-on is only valid on components but it was used on <div>.";
Vue.config.warnHandler = function (msg, vm, trace) {
  // `trace` is the component hierarchy trace
  if (msg === ignoreWarnMessage) {
    msg = null;
    vm = null;
    trace = null;
  }
};

Vue.use(Vuelidate);
Vue.use(Notifications);
Vue.use(VueRouter);
Vue.use(VueAxios, axios);
//setDateLang();
//Vue.use(VueConfirmDialog)
Vue.use(VuejsDialog, {
  html: true,
  loader: true,
  okText: "Proceed",
  cancelText: "Cancel",
  animation: "bounce",
});

axios.defaults.baseURL = "";

axios.interceptors.response.use(
  (res) => {
    if (
      res.data.status &&
      typeof res.data.status === "object" &&
      res.data.status.code !== 0
    ) {
      const error = new Error(res.data.status.message);
      error.response = res;

      throw error;
    }

    return res;
  },
  async (error) => {
    return Promise.reject(error);
  }
);

Vue.router = router;

Vue.use(auth, {
  plugins: {
    http: Vue.axios,
    router: Vue.router,
  },
  drivers: {
    auth: {
      request: function (req, token) {
        if (req.url === this.options.refreshData.url) {
          this.drivers.http.setHeaders.call(this, req, {
            Authorization: "Bearer " + localStorage.getItem("refresh_token"),
          });
        } else {
          this.drivers.http.setHeaders.call(this, req, {
            Authorization: "Bearer " + token,
          });
        }
      },
      response: function (res) {
        var headers = this.drivers.http.getHeaders.call(this, res),
          token = headers.Authorization || headers.authorization;

        let refresh_token = undefined;
        if (res.data.refresh_token) {
          refresh_token = res.data.refresh_token;
        }

        if (token) {
          token = token.split(/Bearer:?\s?/i);
          if (refresh_token) {
            localStorage.setItem("refresh_token", refresh_token);
          }
          return token[token.length > 1 ? 1 : 0].trim();
        }
      },
    },
    http: driverHttpAxios,
    router: driverRouterVueRouter,
  },
  options: {
    refreshData: {
      url: "/auth/refresh",
      enabled: true,
      method: "GET",
      interval: 10,
    },
  },
});
App.ws = null;

//Vue.config.productionTip = false
export const eventEmmitter = new Vue(); //Шина данных

App.router = Vue.router;
App.vuetify = vuetify;

new Vue(App).$mount("#app");
