<template>
  <div>
    <datatable
      :fields="fields"
      :rows="users.data"
      :searchfn="searchfn"
      :click="showUser"
      >{{ $ml.get("users.notUsers") }}
      <template v-slot:header></template>
      <template v-slot:footer>
        <vue-pagination
          :pagination="users"
          @paginate="getUsers()"
          :offset="offset"
        >
        </vue-pagination>
      </template>
    </datatable>
    <UserCard v-model="user" />
  </div>
</template>

<script>
//import {eventEmmitter} from "../app";
import Datatable from "../components/datatable";
import VuePagination from "../components/pagination";
import axios from "axios";
import UserCard from "../components/UserCard";

export default {
  name: "Users",
  data() {
    return {
      fields: [
        { name: "avatar", title: "", style: "width: 50px;", type: "img" },
        { name: "fio", title: "ФИО" },
        { name: "user_type", title: "Статус" },
        { name: "visits", title: "ВРЕМЯ ПРОХОДА", style: "width: 150px;" },
        { name: "companyName", title: "Компания", style: "width: 150px;" },
        { name: "id", title: "ID", style: "width: 50px;" },
      ],
      rows: [],
      users: {
        total: 0,
        per_page: 2,
        from: 1,
        to: 0,
        current_page: 1,
        data: [],
      },
      offset: 4,
      search: "",
      user: false,
    };
  },
  components: { UserCard, Datatable, VuePagination },
  methods: {
    showUser(item) {
      this.user = item;
    },
    searchfn(value) {
      this.search = value;
      this.users.current_page = 1;
      this.getUsers();
      this.user = false;
    },
    getUsers() {
      let values = {
        search: this.search,
        filter: {},
        page: this.users.current_page,
      };
      axios.get("/blackList", { params: values }).then((response) => {
        this.users = response.data.items ? response.data.items : [];
        this.users.data.forEach((item) => {
          item.user_type = this.$ml.get("users." + item.user_type);
        });
      });
    },
  },
  mounted() {
    this.getUsers();
  },
};
</script>

<style scoped></style>
