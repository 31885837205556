<template>
  <v-app>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="550px" height="400px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ $ml.get("subdiv.title") }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="tree-box">
                  <v-treeview
                    v-if="treeLoaded"
                    selectable
                    item-disabled="locked"
                    :items="accesslist"
                    item-text="label"
                    open-all
                    v-model="access"
                  ></v-treeview>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">{{ $ml.get("button.close") }}</v-btn>
            <v-btn
              :disabled="sending"
              v-if="access.length"
              color="primary"
              text
              @click="store"
              >{{ $ml.get("button.save") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-app>
</template>
<script>
export default {
  props: ["value", "action"],
  data() {
    return {
      accesslist: [],
      access: [],
      dialog: true,
      treeLoaded: false,
      sending: false,
    };
  },
  methods: {
    getAccessList() {
      axios.get("/subdivisions/tree").then((response) => {
        if (response.status == 200) {
          this.accesslist = response.data.data ? response.data.data : [];
          this.treeLoaded = true;
        }
      });
    },
    close() {
      this.$emit("toggle", false);
    },
    store() {
      if (!this.access.length || this.sending) return;
      this.sending = true;
      let data = {
        ids: this.value,
        subdivisions: this.access,
        action: this.action,
      };
      axios
        .post("/users/multi/sdv", data, {
          header: {
            "Content-Type": "application/json",
          },
          timeout: 20000,
        })
        .then((response) => {
          if (response.status == 200) {
            console.log(response);
            this.sending = true;
            this.close();
          }
        })
        .catch((err) => {
          this.sending = false;
        });
    },
  },
  created() {
    this.getAccessList();
  },
};
</script>
