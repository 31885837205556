<template>
  <DatePicker
    v-model="value"
    type="datetimerange"
    range-separator="-"
    format="dd.MM.yyyy HH:mm"
    start-placeholder="Start date"
    v-language="true"
    end-placeholder="End date"
  />
</template>

<script>
import { DatePicker } from "element-ui";

export default {
  components: { DatePicker },

  props: ["value"],
  methods: {
    getLang() {
      let l = localStorage.getItem("ovision-ml");
      console.log(l);
      return l.lang || "en";
    },
  },
  watch: {
    value(val) {
      this.$emit("input", val);
    },
  },
  mounted() {
    if (!this.value) {
      this.value = [new Date(), new Date()];
    }
  },
};
</script>
