<template>
  <v-app>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="550px" height="400px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ $ml.get("users.department") }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="tree-box">
                  <Treeselect
                    v-model="department"
                    :multiple="false"
                    :options="departments"
                    :placeholder="$ml.get('user.departments')"
                    :searchable="true"
                    :show-count="true"
                    class="mb-5"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">{{ $ml.get("button.close") }}</v-btn>
            <v-btn
              :disabled="sending"
              v-if="department"
              color="primary"
              text
              @click="store"
              >{{ $ml.get("button.save") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-app>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";

export default {
  components: { Treeselect },

  props: ["value"],

  data() {
    return {
      departments: [],
      department: null,
      dialog: true,
      treeLoaded: false,
      sending: false,
    };
  },

  methods: {
    getList() {
      this.axios("departments/tree").then((response) => {
        this.departments = response.data.data ? response.data.data : [];
        this.treeLoaded = true;
      });
    },

    close() {
      this.$emit("toggle", false);
    },

    store() {
      if (!this.department || this.sending) return;

      this.sending = true;

      let data = {
        ids: this.value,
        department: this.department,
      };

      this.axios
        .post("users/multi/dpt", data)
        .then(() => {
          this.sending = true;
          this.close();
        })
        .catch(() => {
          this.sending = false;
        });
    },
  },

  created() {
    this.getList();
  },
};
</script>
