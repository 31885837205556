// If you like you can this part/section into separate file
// ------- Move from here -------
const dataURItoBlob = (dataURI) => {
  const bytes =
    dataURI.split(",")[0].indexOf("base64") >= 0
      ? atob(dataURI.split(",")[1])
      : unescape(dataURI.split(",")[1]);
  const mime = dataURI.split(",")[0].split(":")[1].split(";")[0];
  const max = bytes.length;
  const ia = new Uint8Array(max);
  for (let i = 0; i < max; i += 1) ia[i] = bytes.charCodeAt(i);
  return new Blob([ia], { type: mime });
};

const resize = (image, maxSize) => {
  const canvas = document.createElement("canvas");
  //console.log(image)
  let { width, height } = image;

  // if (width > height) {
  //     if (width > maxSize) {
  //         height *= maxSize / width;
  //         width = maxSize;
  //     }
  // } else if (height > maxSize) {
  //     width *= maxSize / height;
  //     height = maxSize;
  // }

  if (maxSize < width) {
    height = (maxSize / width) * height;
    width = maxSize;
  }

  // canvas.width = width;
  // canvas.height = height;

  let maxcanvas = 0;
  let vx = 0;
  let vy = 0;
  // if (width > height) {
  //     vx = (height - width) / 2
  //     maxcanvas = height
  // } else {
  //     vy = (width - height) / 2
  //     maxcanvas = width
  // }

  canvas.width = width;
  canvas.height = height;

  // canvas.width = maxcanvas;
  // canvas.height = maxcanvas;

  canvas.getContext("2d").drawImage(image, vx, vy, width, height);

  const dataUrl = canvas.toDataURL("image/jpeg");

  return dataUrl; //dataURItoBlob(dataUrl);
};

const resizeImage = ({ file, maxSize }) => {
  const reader = new FileReader();
  const image = new Image();
  return new Promise((ok, no) => {
    if (!file.type.match(/image.*/)) {
      no(new Error("Not an image"));
      return;
    }

    reader.onload = (readerEvent) => {
      image.onload = () => ok(resize(image, maxSize));
      image.src = readerEvent.target.result;
    };

    reader.readAsDataURL(file);
  });
};

const resizeImageSrc = ({ src, maxSize }) => {
  const image = new Image();
  return new Promise((ok, no) => {
    image.src = src;
    image.onload = () => ok(resize(image, maxSize));
  });
};

export default { fromFile: resizeImage, fromSrc: resizeImageSrc }; // uncomment once moved to resizeImage.js
// ------- till here - into ./src/plugins/image-resize.js -------
