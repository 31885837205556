<template>
  <v-container v-if="loading">
    <v-app>
      <v-card elevation="2">
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col>
                <div
                  v-if="errorMessage"
                  class="alert alert-danger"
                  role="alert"
                >
                  {{ errorMessage }}
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="name"
                  :rules="nameRules"
                  :label="`${$ml.get('devices.nameDevice')} *`"
                  outlined
                  required
                />

                <v-text-field
                  v-model="ipAddress"
                  :label="`${$ml.get('devices.ipDevice')} *`"
                  outlined
                  required
                />

                <v-switch
                  v-if="cardReader !== undefined"
                  v-model="cardReader"
                  :label="$ml.get('devices.cardReader')"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  v-model="purpose"
                  :items="purposeItems"
                  item-text="name"
                  item-value="val"
                  :label="$ml.get('devices.purpose')"
                  outlined
                ></v-select>
                <v-row>
                  <v-col cols="6">
                    <v-select
                      ref="zones"
                      v-model="zonesId"
                      :items="zones"
                      item-text="name"
                      item-value="id"
                      :label="$ml.get('devices.zoneTo') + ' *'"
                      :rules="zoneRules"
                      outlined
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      ref="sources"
                      v-model="sourceId"
                      :items="zonesFrom"
                      item-text="name"
                      item-value="id"
                      :label="$ml.get('devices.zoneFrom') + ' *'"
                      :rules="sourceRules"
                      outlined
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <DeviceOgate v-if="deviceType == 'OGATE'" v-model="data" />
            <DeviceUface
              v-if="deviceType == 'UFACE'"
              v-model="data"
              :isEdit="id ? true : false"
            />
            <v-row>
              <v-col cols="auto">
                <v-btn @click.prevent="back">
                  {{ $ml.get("button.back") }}
                </v-btn>
              </v-col>
              <v-spacer />
              <v-col v-if="id" cols="auto">
                <v-btn color="error" @click.prevent="remove">
                  {{ $ml.get("button.delete") }}
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn color="primary" @click.prevent="store(false)">
                  {{ $ml.get("button.save") }}
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn color="primary" @click.prevent="store(true)">
                  {{ $ml.get("button.apply") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-app>

    <v-snackbar v-model="isShowSuccessToast" :timeout="2000" color="success">
      {{ $ml.get("message.saveSuccess") }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="isShowSuccessToast = false">
          {{ $ml.get("button.close") }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script>
import axios from "axios";
// import vuetify from "../../plugins/vuetify.js";
import router from "../../router";
//import password from "../../components/PasswordDlg.vue";
import moment from "moment-timezone";
import DeviceOgate from "./ogate/deviceOgate.vue";
import DeviceUface from "./uface/deviceUface.vue";

export default {
  components: {
    DeviceOgate,
    DeviceUface,
  },
  data() {
    const deviceType = this.$route.query.device;

    return {
      valid: true,

      isShowSuccessToast: false,

      data: null,
      pdlg: false,
      loading: false,
      sourceId: null,
      id: this.$route.params.id || 0,
      cardReader: undefined,
      name: "",
      ipAddress: "192.168.0.100",
      purpose: "entrance",
      // l: {
      //   lang: "en",
      //   show: false,
      //   temp_meas: "c",
      //   temperature_range: [],
      //   temperature_enabled: false,
      //   temperature_rise: false,
      //   antispoofing_enabled: false,
      // },
      enabled: true,
      zonesId: 0,
      zones: [],
      zonesFrom: [],
      mltimeout: 0,
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => v.length <= 20 || "Name must be less than 20 characters",
      ],
      errorMessage: "",

      deviceType,

      zoneRules: [
        (v) => v != this.sourceId || this.$ml.get("devices.zoneNotEmpty"),
      ],
      sourceRules: [
        (v) => v != this.zonesId || this.$ml.get("devices.zoneNotEmpty"),
      ],
    };
  },

  watch: {
    async zonesId() {
      await this.$nextTick();
      this.$refs.sources.validate();
    },

    async sourceId() {
      await this.$nextTick();
      this.$refs.zones.validate();
    },
  },

  methods: {
    back() {
      router.push({ path: `/devices` });
    },
    remove() {
      let options = {
        loader: false,
        okText: this.$ml.get("button.delete"),
        cancelText: this.$ml.get("button.cancel"),
        backdropClose: true,
      };
      let _this = this;
      this.$dialog
        .confirm(
          `<h3>${this.$ml.get("message.Delete")}</h3>` +
            this.$ml.get("message.confirmDelete"),
          {
            loader: false,
            okText: this.$ml.get("button.delete"),
            cancelText: this.$ml.get("button.cancel"),
            backdropClose: true,
          }
        )
        .then(function () {
          axios.delete("/device/" + _this.id).then((response) => {
            if (response.statusText == "OK") {
              if (response.data.status.code != 0) {
                _this.errorMessage = response.data.status.message;
              } else {
                router.push({ path: `/devices` });
              }
            }
          });
        });
    },
    store(isApply) {
      this.errorMessage = ""
      if (this.$refs.form.validate()) {
        let form = {
          timestamp: moment().unix(),
          name: this.name,
          purpose: this.purpose.trim(),
          ip_address: this.ipAddress,
          enabled: this.enabled,
          zones_id: this.zonesId,
          source_id: this.sourceId,
          device_type: this.deviceType,
          data: JSON.stringify({
            ...this.data,
            card_reader: this.cardReader,
          }),
        };
        let config = {
          header: {
            "Content-Type": "application/json",
          },
          timeout: 20000,
        };

        if (!this.id) {
          axios
            .post("/device/add", form, config)
            .then((response) => {
              if (response.data.status.code != 0) {
                this.errorMessage = response.data.status.message;
              } else {
                if (isApply) {
                  this.$router.push({
                    name: "editDevice",
                    params: { id: response.data.data.id },
                  });
                } else {
                  router.push({ path: `/devices` });
                }
              }
            })
            .catch((error) => {
              this.errorMessage = error.response.data.status.message;
            });
        } else {
          axios
            .put("/device/" + this.id, form, config)
            .then((response) => {
              if (response.data.status.code != 0) {
                this.errorMessage = response.data.status.message;
              } else {
                if (isApply) {
                  this.isShowSuccessToast = true;
                } else {
                  router.push({ path: `/devices` });
                }
              }
            })
            .catch((error) => {
              this.errorMessage = error.response.data.status.message;
            });
        }
      }
    },
    // getRegimMask() {
    //   let _this = this;
    //   axios.get("/setting/MASK_RECOGNITION").then((response) => {
    //     if (response.status == 200) {
    //       let val = response.data.data.value || "off";
    //       _this.regimMask = val == "on";
    //     }
    //   });
    // },
    getZones() {
      axios.get("/zones").then((response) => {
        if (response.data.status.code == 0) {
          this.zones = response.data.data;
          let tmpId = 0;
          let z = localStorage.getItem("ovision-select-zone");
          let it = {};
          if (z) {
            it = JSON.parse(z);
          }
          this.zones.forEach((v) => {
            if (!this.zonesId) {
              if (!tmpId) tmpId = v.id;
              if (it.id !== null && v.id == it.id) {
                this.zonesId = v.id;
              }
            }
          });
          if (!this.zonesId) {
            this.zonesId = tmpId;
          }
          this.zonesFrom =[...this.zones]
          this.zonesFrom.splice(0, 0, {
            id: null,
            name: this.$ml.get("devices.zoneEmpty"),
          });
        }
      });
    },
    getDevice() {
      if (this.id == 0) return;
      axios.get("/device/" + this.id).then((response) => {
        if (response.status == 200) {
          let item = response.data.data;
          this.cardReader = JSON.parse(item.data).card_reader;
          this.name = item.name;
          this.deviceType = item.device_type || "OGATE";
          this.purpose = item.purpose;
          this.ipAddress = item.ip_address;
          //this.recognitionRange = this.getFft(item.recognition_range);
          this.zonesId = item.zones_id;
          this.sourceId = item.source_id;
          this.enabled = item.enabled;
          if (item.data != "") {
            this.data = JSON.parse(item.data);
          }
        }
      });
    },
    getSettingValues() {
      if (localStorage.getItem("ovision-ml")) {
        try {
          this.l = JSON.parse(localStorage.getItem("ovision-ml"));
          //this.settings = response.data.settings || {};
          if (this.l.temperature_range != undefined) {
            let range = this.l.temperature_range.split(",");
            if (range.length > 1) this.tAlarm = range[1];
          }
          this.loading = true;
        } catch (err) {
          this.loading = true;
        }
      }
    },
  },
  computed: {
    purposeItems() {
      return [
        { val: "entrance", name: this.$ml.get("button.entrance") },
        { val: "exit", name: this.$ml.get("button.exit") },
        { val: "duplex", name: this.$ml.get("button.duplex") },
        { val: "off", name: this.$ml.get("button.off") },
      ];
    },
    enableItems() {
      return [
        { val: "on", name: this.$ml.get("button.use") },
        { val: "off", name: this.$ml.get("button.doNotUse") },
      ];
    },
    setstyle() {
      return (
        `<style> span.switcher input:before { content: "` +
        this.$ml.get("button.on") +
        `";} span.switcher input:after { content: "` +
        this.$ml.get("button.off") +
        `";}</style>`
      );
    },
  },
  created() {
    // this.getSettingValues();
  },
  mounted() {
    this.getDevice();
    this.getZones();
    this.loading = true;
    //this.getRegims();
    //this.getRegimMask();
  },
};
</script>

<style scoped>
.has-float-label {
  margin-top: -30px;
}
/* .white-link-button {
  margin-right: 50px;
} */
@media only screen and (max-width: 1024px) {
  button {
    width: 140px;
  }
  .white-link-button {
    margin-right: 20px;
  }
}
@media only screen and (max-width: 900px) {
  button {
    width: 120px;
  }
  .white-link-button {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 768px) {
  button {
    width: 100px;
  }
  .white-link-button {
    margin-right: 10px;
  }
}
</style>
