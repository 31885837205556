<template>
  <nav class="horizontal-menu">
    <div class="horizontal-menu__top">
      <div class="horizontal-menu__burger">
        <v-btn icon @click="toogleMenu">
          <v-icon v-if="!isOpenMenu">mdi-menu</v-icon>
          <v-icon v-else>mdi-close</v-icon>
        </v-btn>
      </div>

      <ul class="horizontal-menu__nav">
        <li class="horizontal-menu__name-tag">
          <router-link
            :to="{ name: 'editPersonal', params: { id: $auth.user().id } }"
          >
            {{ $auth.user().last_name }} {{ $auth.user().first_name }}
            {{ $auth.user().middle_name }}
          </router-link>
        </li>
        <li
          class="horizontal-menu__avatar"
          :style="{ backgroundImage: `url(${AvatarPath})` }"
        >
          <router-link
            :to="{ name: 'editPersonal', params: { id: $auth.user().id } }"
          />
        </li>

        <li class="horizontal-menu__logout">
          <v-btn icon plain @click="logout">
            <v-icon> mdi-logout </v-icon>
          </v-btn>
        </li>
      </ul>
    </div>

    <h3 class="horizontal-menu__title">{{ topMenuTitle }}</h3>
  </nav>
</template>

<script>
export default {
  name: "TopMenu",

  props: {
    isOpenMenu: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      topMenuTitle: "",
    };
  },

  methods: {
    logout() {
      this.$auth.logout();
    },

    UpdateTitle() {
      if (this.$route.name) {
        this.topMenuTitle = this.$ml.get("menu." + this.$route.name);
      }
    },

    removeUserStore() {
      localStorage.removeItem("terminal.users.tabs");
      localStorage.removeItem("terminal.users.subdiv");
    },

    toogleMenu() {
      this.$emit("toggleMenu", !this.isOpenMenu);
    },
  },

  computed: {
    AvatarPath() {
      if (this.$auth.user().photo_encrypted) {
        return this.$auth.user().photo_encrypted;
      }

      if (this.$auth.user().photo) {
        return "/avatar/small/" + this.$auth.user().photo;
      }

      return "/avatar/small/avatar.png";
    },
  },

  watch: {
    $route(toR, romR) {
      this.UpdateTitle();
      console.log(toR);
      if (toR.path.indexOf("/user") == -1) {
        this.removeUserStore();
      }
    },
  },

  created() {
    this.UpdateTitle();
  },

  updated() {
    this.UpdateTitle();
  },
};
</script>
