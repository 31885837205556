<template>
  <nav
    :class="[
      'left-sidebar',
      { 'left-sidebar--min': isMenuMinimize },
      { 'left-sidebar--show': isOpenMenu },
    ]"
  >
    <header>
      <a href="/" :class="['logo', { 'logo--min': isMenuMinimize }]">
        <template v-if="l.show">
          <img
            v-if="!isMenuMinimize"
            src="~@/assets/img/logo.svg"
            width="70%"
            alt="Ovision"
          />
          <img v-else src="~@/assets/img/logo-min.svg" alt="Ovision" />
        </template>
        <template v-else>
          <img
            v-if="!isMenuMinimize"
            src="~@/assets/img/logo-youmay.svg"
            width="70%"
            alt="Youmay"
          />
          <img
            v-else
            src="~@/assets/img/logo-youmay-min.svg"
            width="70%"
            alt="Youmay"
          />
        </template>
      </a>
    </header>
    <ul class="nav flex-column">
      <li
        class="nav-item"
        v-if="
          $auth.user().type_user == 'admin' || $auth.user().type_user == 'guard'
        "
      >
        <router-link
          class="nav-link"
          exact
          :to="{ name: 'dashboard' }"
          active-class="active"
        >
          <v-icon>mdi-view-dashboard</v-icon>
          <template v-if="!isMenuMinimize">
            {{ $ml.get("menu.dashboard") }}
          </template>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link"
          :to="{ name: 'users' }"
          active-class="active"
        >
          <v-icon>mdi-account-multiple</v-icon>
          <template v-if="!isMenuMinimize">
            {{ $ml.get("menu.users") }}
          </template>
        </router-link>
      </li>
      <li
        class="nav-item"
        v-if="
          $auth.user().type_user == 'admin' ||
          $auth.user().roles.includes('journal')
        "
      >
        <router-link
          class="nav-link"
          :to="{ name: 'journal' }"
          active-class="active"
        >
          <v-icon>mdi-notebook</v-icon>
          <template v-if="!isMenuMinimize">
            {{ $ml.get("menu.journal") }}
          </template>
        </router-link>
      </li>
      <li
        class="nav-item"
        v-if="
          $auth.user().type_user == 'admin' ||
          $auth.user().roles.includes('requests') ||
          $auth.user().roles.includes('reqconfirm')
        "
      >
        <router-link
          class="nav-link"
          :to="{ name: 'requests' }"
          active-class="active"
        >
          <v-icon>mdi-lock-check</v-icon>
          <template v-if="!isMenuMinimize">
            {{ $ml.get("menu.requests") }}
          </template>
        </router-link>
      </li>
      <!-- <li
        v-if="($auth.user().type_user=='admin' || $auth.user().roles.includes('schedule'))"
        class="nav-item"
      >
        <router-link
          class="nav-link"
          :to="{ name: 'schedules' }"
          active-class="active"
        >{{ $ml.get('menu.schedules') }}</router-link>
      </li> -->

      <li v-if="$auth.user().type_user == 'admin'" class="nav-item">
        <router-link
          class="nav-link"
          :to="{ name: 'guards' }"
          active-class="active"
        >
          <v-icon>mdi-account-star</v-icon>
          <template v-if="!isMenuMinimize">
            {{ $ml.get("menu.guards") }}
          </template>
        </router-link>
      </li>
      <li
        class="nav-item"
        v-if="
          $auth.user().type_user == 'admin' ||
          $auth.user().type_user == 'guard' ||
          $auth.user().roles.includes('devices')
        "
      >
        <router-link
          class="nav-link"
          :to="{ name: 'devices' }"
          active-class="active"
        >
          <v-icon>mdi-cctv</v-icon>
          <template v-if="!isMenuMinimize">
            {{ $ml.get("menu.devices") }}
          </template>
        </router-link>
      </li>
      <li
        v-if="
          $auth.user().type_user == 'admin' ||
          $auth.user().roles.includes('adminedit')
        "
        class="nav-item"
      >
        <router-link
          class="nav-link"
          :to="{ name: 'settings' }"
          active-class="active"
        >
          <v-icon>mdi-cog</v-icon>
          <template v-if="!isMenuMinimize">
            {{ $ml.get("menu.settings") }}
          </template>
        </router-link>
      </li>
    </ul>

    <v-spacer />

    <aside>
      <ul v-if="!isMenuMinimize">
        <li v-if="l.lang === 'ru'">
          <a
            class="mdi mdi-open-in-new"
            href="/static/terminal.pdf"
            target="_blank"
          >
            {{ $ml.get("menu.manual") }}</a
          >
        </li>
        <li v-if="l.lang === 'ru'">
          {{ $ml.get("menu.support") }}:<br />{{ $ml.get("menu.supportPhone") }}
        </li>

        <li v-if="l.version" class="mt-2">
          {{ $ml.get("menu.version") }}: {{ l.version }}
        </li>
      </ul>

      <PWAInstall v-if="!isMenuMinimize" />

      <button
        type="button"
        class="left-sidebar__hide-menu-btn"
        @click="toggleMenuSize"
      >
        <v-icon v-if="!isMenuMinimize">mdi-chevron-left</v-icon>
        <v-icon v-else>mdi-chevron-right</v-icon>
        <template v-if="!isMenuMinimize">
          {{ $ml.get("menu.hideMenu") }}
        </template>
      </button>
    </aside>
  </nav>
</template>

<script>
import PWAInstall from "@/components/PWAInstall";

export default {
  name: "LeftMenu",

  components: { PWAInstall },

  props: {
    isMenuMinimize: {
      type: Boolean,
      default: false,
    },

    isOpenMenu: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      l: {
        lang: "en",
        show: false,
        temp_meas: "c",
        temperature_range: [],
        temperature_enabled: false,
        temperature_rise: false,
        version: "",
      },
    };
  },

  methods: {
    getSettngs() {
      try {
        this.l = JSON.parse(localStorage.getItem("ovision-ml"));
      } catch (e) {
        console.log(e);
      }
    },

    toggleMenuSize() {
      this.$emit("toggleMenuSize");
    },
  },

  mounted() {
    this.getSettngs();
  },
};
</script>
