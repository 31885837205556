<template>
  <v-container>
    <v-row class="p-5">
      <v-col
        cols="12"
        md="6"
        lg="4"
        offset-md="3"
        offset-lg="4"
        class="login-page-card"
      >
        <v-container>
          <div v-if="error" class="alert alert-danger">
            {{ $ml.get("login.Error") }}
          </div>

          <v-form autocomplete="off" @submit.prevent="login" method="post">
            <h3 class="text-center">{{ $ml.get("login.ToLogin") }}</h3>

            <v-text-field
              v-model="username"
              :placeholder="`${$ml.get('login.Login')} *`"
              required
              outlined
            />

            <v-text-field
              type="password"
              v-model="password"
              :placeholder="`${$ml.get('login.Password')} *`"
              required
              outlined
            />

            <button type="submit" class="login-page-card__btn">
              {{ $ml.get("login.Entry") }}
            </button>
          </v-form>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      username: null,
      password: null,
      error: false,
    };
  },
  methods: {
    login() {
      var app = this;
      this.error = false;
      this.$auth
        .login({
          data: {
            username: app.username,
            password: app.password,
          },
          rememberMe: true,
          method: "POST",
          redirect: { name: "dashboard" },
          fetchUser: true,
        })
        .catch(() => {
          app.error = true;
        });
    },
  },
};
</script>
