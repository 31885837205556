export default {
  data() {
    return {
      faceValidErrorMessage: "",
    };
  },

  methods: {
    validateFace(photo) {
      this.faceValidErrorMessage = "";

      this.axios
        .post("validate/face", {
          photo_encrypted: photo,
        })
        .catch((error) => {
          this.faceValidErrorMessage = this.getLangMessage(
            error.response.data.status.message
          );
        });
    },

    getLangMessage(message) {
      if (!message) {
        return this.$ml.get("message.BadRequest");
      } else if (message == "Cant find face of the image")
        return this.$ml.get("message.CantFaceImage");
      else if (message == "Bad photo")
        return this.$ml.get("message.CantFaceImage");
      else if (
        message == "Unavailable to device" ||
        message == "Device Unavailable"
      )
        return this.$ml.get("message.UnavailableToDevice");
      else if (message == "face rotated")
        return this.$ml.get("message.faceRotated");
      else if (message == "Fields filled in incorrectly")
        return this.$ml.get("message.FieldsIncorrectly");
      else if (message == "Face match found")
        return this.$ml.get("message.FaceMatchFound");
      else if (message == "No subdivision selected")
        return this.$ml.get("message.NoSubdivisionSelected");
      else if (message.indexOf("Face match found User") !== -1) {
        let userID = parseInt(
          message.replace("Face match found User ", "").trim()
        );
        this.messageClick = "/user/" + userID;

        return (
          this.$ml.get("message.FaceMatchFound") +
          message.replace("Face match found", "")
        );
        //return `<a href="/user/` + userID + `">` + message + `</a>`;
      } else if (message.indexOf("Duplicate access card") !== -1) {
        let l = message.split("#");
        let userID = parseInt(l[1].trim());
        this.messageClick = "/user/" + userID;
        let mrep = this.$ml.get("message.DuplicateAccessCard");
        return l[0].replace("Duplicate access card", mrep);
      } else if (
        message ===
        'pq: duplicate key value violates unique constraint "admins_username_key"'
      ) {
        return this.$ml.get("message.duplicateKeyLogin");
      } else return message;
    },
  },
};
