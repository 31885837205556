<template>
  <v-app class="p-3">
    <v-row align="center">
      <v-col cols="12" md="8">
        <v-row justify="start" align="center">
          <v-col cols="auto" class="pb-0 pt-0">
            <v-switch
              v-model="isShowCanceled"
              :label="$ml.get('requests.showCanceled')"
            />
          </v-col>
          <v-col cols="auto" class="pb-0 pt-0">
            <v-switch
              v-model="isShowAll"
              :label="$ml.get('requests.showAll')"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        v-if="
          $auth.user().type_user == 'admin' ||
          $auth.user().roles.includes('requests')
        "
        cols="12"
        md="4"
      >
        <v-row justify="start" justify-md="end" align="center">
          <v-col cols="auto">
            <v-btn :to="{ name: 'addRequest' }" color="primary">{{
              $ml.get("button.add")
            }}</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-skeleton-loader v-if="loading" class="mx-auto" type="table-tbody" />
        <Datatable
          :dblclick="goToEdit"
          :fields="fields"
          :searchfn="searchfn"
          :rows="data"
          :rowstyle="rowStyle"
        >
          {{ $ml.get("requests.notRequests") }}
          <template v-slot:header></template>
          <template v-slot:footer>
            <vue-pagination
              :pagination="pagination"
              @paginate="getRequests()"
              :offset="offset"
            />
          </template>

          <template #[`item.statusText`]="{ item }">
            <v-chip :color="getColorByStatus(item.status)" outlined>
              {{ item.statusText }}
            </v-chip>
          </template>

          <template #[`item.requestType`]="{ item }">
            <v-tooltip v-if="item.requestType.includes(1)" top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" small>
                  mdi-qrcode-scan
                </v-icon>
              </template>
              <span>{{ $ml.get("requests.byQR") }}</span>
            </v-tooltip>

            <v-tooltip v-if="item.requestType.includes(0)" top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" small> mdi-tablet </v-icon>
              </template>
              <span>{{ $ml.get("requests.byKiosk") }}</span>
            </v-tooltip>
          </template>

          <template #[`item.requestConfirm`]="{ item }">
            <v-row v-if="isRequestConfirm(item.status)" no-gutters>
              <v-col>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="green"
                      @click="confirmRequest(item.id)"
                    >
                      <v-icon> mdi-check </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $ml.get("requests.confirmRequest") }}</span>
                </v-tooltip>
              </v-col>
              <v-spacer />
              <v-col>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="red"
                      @click="cancelRequest(item.id)"
                    >
                      <v-icon> mdi-close </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $ml.get("requests.cancelRequest") }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </template>
        </Datatable>
        <v-alert v-if="isFailure" border="top" color="red lighten-2" dark>
          {{ $ml.get("message.errorLoading") }}
        </v-alert>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import Datatable from "@/components/datatable";
import VuePagination from "@/components/pagination";

export default {
  components: { Datatable, VuePagination },

  data() {
    const fields = [
      { name: "id", header: { text: "ID" }, style: "width: 50px;" },
      { name: "name", header: { text: this.$ml.get("requests.name") } },
      {
        name: "department_name",
        header: { text: this.$ml.get("requests.department") },
      },
      {
        name: "statusText",
        style: "width: 170px",
        header: { text: this.$ml.get("requests.status") },
      },
      {
        name: "createdAtFormatted",
        style: "width: 130px",
        header: { text: this.$ml.get("requests.date") },
      },
      {
        name: "requestType",
        style: "width: 30px",
      },
      {
        name: "requestConfirm",
        style: "width: 100px",
      },
    ];

    return {
      fields,

      isPending: false,
      isSuccess: false,
      isFailure: false,
      data: [],
      pagination: {
        total: 0,
        per_page: 2,
        from: 1,
        to: 30,
        current_page: 1,
      },
      search: "",
      isShowCanceled: false,
      isShowAll: true,
    };
  },

  watch: {
    isShowCanceled() {
      this.getRequests();
    },

    isShowAll() {
      this.getRequests();
    },
  },

  methods: {
    getRequests() {
      const params = {
        owner_id: this.$auth.user().id,
        page: this.pagination.current_page,
        limit: 10,
        search: this.search,
      };

      if (!this.isShowCanceled) {
        params.excluded_status = [3];
      }

      if (this.isShowAll) {
        delete params.owner_id;
      }

      this.isPending = true;
      this.isSuccess = false;
      this.isFailure = false;

      axios("requests", { params })
        .then((response) => {
          this.isPending = false;
          this.isSuccess = true;
          this.isFailure = false;

          this.data = this.formattedData(response.data.data);
          this.pagination = response.data.meta.pagination;
        })
        .catch(() => {
          this.isPending = false;
          this.isSuccess = false;
          this.isFailure = true;
        });
    },
    searchfn(value) {
      this.search = value;
      this.pagination.current_page = 1;
      this.getRequests();
    },

    formattedData(data) {
      const getStatus = (code) => {
        switch (code) {
          case 0:
            return this.$ml.get("requests.statusNew");
          case 1:
            return this.$ml.get("requests.statusWait");
          case 2:
            return this.$ml.get("requests.statusDone");
          case 3:
            return this.$ml.get("requests.statusCancel");
          default:
            break;
        }
      };

      const getRequestType = (requestType) => {
        const requestTypeSplit = String(requestType)
          .split("")
          .map((num) => {
            return Number(num);
          });

        return requestTypeSplit;
      };

      return data.map((item) => ({
        ...item,
        statusText: getStatus(item.status),
        requestType: getRequestType(item.request_type),
        createdAtFormatted: this.$moment(item.created_at).format(
          "DD.MM.YY HH:mm"
        ),
      }));
    },

    getColorByStatus(status) {
      switch (status) {
        case 0:
          return "blue";
        case 1:
          return "orange";
        case 2:
          return "green";
        case 3:
          return "";
        default:
          return "";
      }
    },

    goToEdit({ id, status }) {
      if (status !== 3) {
        this.$router.push({ name: "editRequest", params: { id } });
      }
    },

    rowStyle({ status }) {
      if (status === 3) {
        return "background-color: #eee; color: #aaa; cursor: default";
      }
    },

    confirmRequest(id) {
      axios
        .put(`request/${id}/status`, {
          status: 1,
        })
        .then(() => {
          this.getRequests();
        });
    },

    cancelRequest(id) {
      axios
        .put(`request/${id}/status`, {
          status: 3,
        })
        .then(() => {
          this.getRequests();
        });
    },

    isRequestConfirm(status) {
      return (
        (this.$auth.user().type_user == "admin" ||
          this.$auth.user().roles.includes("reqconfirm")) &&
        status === 0
      );
    },
  },

  created() {
    this.getRequests();
  },
};
</script>
