<template>
  <div>
    <div
      v-if="$auth.check()"
      :style="{ overflow: isOpenMenu ? 'hidden' : 'visible' }"
    >
      <LeftMenu
        v-if="this.$auth.user().password_upd"
        :isMenuMinimize="isMenuMinimize"
        :isOpenMenu="isOpenMenu"
        @toggleMenuSize="onMenuSizeToggle"
      />
      <main
        :class="[
          'main',
          { 'main--menu-min': isMenuMinimize },
          { 'main--menu-open': isOpenMenu },
        ]"
      >
        <TopMenu :isOpenMenu="isOpenMenu" @toggleMenu="isOpenMenu = $event" />
        <router-view :key="$route.fullPath"></router-view>
      </main>
    </div>
    <Login v-else />
  </div>
</template>
<script>
import axios from "axios";
import TopMenu from "./navigation/TopMenu";
import LeftMenu from "./navigation/LeftMenu";
import Login from "./components/Login";
export default {
  components: { TopMenu, LeftMenu, Login },

  data() {
    let isMenuMinimize = false;

    if (localStorage.getItem("isMenuMinimize") === "true") {
      isMenuMinimize = true;
    }

    return {
      isMenuMinimize,
      isOpenMenu: false,
    };
  },

  watch: {
    $route(to) {
      this.isOpenMenu = false;

      // redirect to login page if not logged in and trying to access a restricted page
      //const { authorize } = to.meta;

      var roles = to.meta.roles != undefined ? to.meta.roles : [];
      var isRole = false;
      if (roles.length > 0) {
        roles.forEach((role) => {
          let roles = this.$auth.user().roles || [];
          if (roles.includes(role)) {
            isRole = true;
            return;
          }
        });
      }
      if (
        to.meta.permission != undefined &&
        to.meta.permission != this.$auth.user().type_user &&
        !isRole
      ) {
        this.$router.push("/");
      }
      if (to.name == "dashboard" && this.$auth.user().type_user == "manager") {
        this.$router.push("/users");
      }
      if (!this.$auth.user().password_upd && to.name !== "editPersonal") {
        this.$router.push({
          name: "editPersonal",
          params: { id: this.$auth.user().id },
        });
      }
    },
  },

  methods: {
    getLang() {
      axios.get("/setting/default").then((response) => {
        if (response.status == 200) {
          this.$ml.change(response.data.data.lang);
          localStorage.setItem(
            "ovision-ml",
            JSON.stringify(response.data.data)
          );
        }
      });
    },
    wsocketCreate(isAfterError = false) {
      let prot = "ws://";
      if (window.location.protocol == "https:") {
        prot = "wss://";
      }
      let _this = this;
      window.$ws = new WebSocket(prot + window.location.host + "/websocket");
      var self = this;
      window.$ws.onmessage = function (event) {
        // websocket
        var msg = JSON.parse(event.data);
        self.$root.$emit(msg.Event, msg.Args);
      };

      window.$ws.onclose = function () {
        setTimeout(function () {
          _this.wsocketCreate(true);
        }, 1000);
      };
    },
    // reactivationWs() {
    //   let _this = this
    //   setTimeout(function () {
    //     console.log("readyState:", _this.$ws.readyState)
    //     if (_this.$ws && _this.$ws.readyState != 1) {
    //       _this.wsocketCreate()
    //     }
    //     _this.reactivationWs()
    //   }, 10000);
    // }

    onMenuSizeToggle() {
      this.isMenuMinimize = !this.isMenuMinimize;

      localStorage.setItem("isMenuMinimize", this.isMenuMinimize);
    },
  },
  mounted() {
    console.log("GET LANG");
    this.getLang();
    //if (!this.$ws) {
    this.wsocketCreate();
    //}
    //this.reactivationWs()
  },
};
</script>

<style lang="scss">
@import "assets/sass/app.scss";
</style>
