<template>
  <div>
    <template v-if="searchfn">
      <div class="search-bar-flex-container">
        <slot name="header"></slot>
        <div class="search-bar">
          <label class="no-label has-float-label search_term_label">
            <input
              class="search_term"
              type="text"
              v-model="search"
              v-debounce="300"
              :placeholder="$ml.get('datatable.enter_search_string')"
            />
            <span class="search-button search_icon"></span>
          </label>
        </div>
      </div>
    </template>
    <div ref="tableBox" class="panel-list-dashboard">
      <div class="panel-list-dashboard__wrapper">
        <table
          ref="table"
          :class="[
            'search-visitors-dashboard',
            { 'search-visitors-dashboard--scroll-hint': isTableMoreThanBox },
          ]"
        >
          <thead>
            <tr>
              <th
                v-for="(fld, i) in fields"
                :key="i"
                :width="fld.tablewidth"
                :style="asValue(fld.header, 'style') || fld.style"
              >
                <v-checkbox
                  v-if="asValue(fld.header, 'type') == 'checkbox'"
                  hide-details
                  :class="`p-0 m-0 ${asValue(fld.header, 'class')}`"
                  :style="asValue(fld.header, 'style')"
                  :value="asValue(fld.header, 'value') ? true : false"
                  @change="
                    buttonClick(
                      fld.header.click,
                      fld.header.value,
                      $event,
                      items
                    )
                  "
                />
                <span
                  v-else-if="asValue(fld.header, 'text')"
                  :style="asValue(fld.header, 'style')"
                  :class="asValue(fld.header, 'class')"
                  :title="asValue(fld.header, 'title')"
                  @change="buttonClick(fld.header.check, item, $event)"
                  >{{ asValue(fld.header, "text") }}</span
                >
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-if="!items.length">
              <tr>
                <td class="no-results" :colspan="fields.length">
                  <slot></slot>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr
                v-for="(item, i) in items"
                :key="i"
                @click="openClick(item, $event)"
                @dblclick.prevent="openDblClick(item, $event)"
                :style="trStyle(item)"
              >
                <td v-for="(fld, i) in fields" :key="i">
                  <slot
                    v-if="hasItemSlot(`item.${fld.name}`)"
                    :name="`item.${fld.name}`"
                    :item="item"
                  />
                  <template v-else>
                    <template v-if="!fld.group">
                      <template v-if="fld.type === 'img'">
                        <img
                          :src="item[fld.name]"
                          :style="fld.style"
                          alt=""
                          :title="item[fld.title] ? item[fld.title] : fld.title"
                          class="search-visitors-dashboard__img"
                          @click.prevent="buttonClick(fld.click, item, $event)"
                        />
                      </template>
                      <template v-else-if="fld.type === 'button'">
                        <button
                          @click.prevent="buttonClick(fld.click, item, $event)"
                          :src="item[fld.name]"
                          :class="fld.class"
                          :data-icon="fld.icon"
                          :style="fld.style"
                          :title="fld.title"
                        >
                          {{ fld.text }}
                        </button>
                      </template>
                      <template v-else-if="fld.type === 'html'">
                        <div v-html="item[fld.name]"></div>
                      </template>
                      <template v-else-if="fld.type === 'checkbox'">
                        <v-checkbox
                          v-model="item[fld.name]"
                          hide-details
                          :class="`p-0 m-0 ${fld.class}`"
                        />
                      </template>
                      <template v-else>
                        <span
                          :style="fld.style"
                          :class="fld.class"
                          @click.prevent="buttonClick(fld.click, item, $event)"
                          >{{ item[fld.name] }}</span
                        >
                      </template>
                    </template>
                    <template v-else>
                      <div
                        v-for="(fld2, i) in fld.group"
                        :key="i"
                        :style="fld2.style"
                        :title="fld2.title"
                      >
                        <template>{{ fld2.btag }}</template>
                        <template v-if="fld2.type === 'img'">
                          <img
                            :src="item[fld2.name]"
                            :style="fld2.style"
                            alt=""
                            :title="fld.title"
                          />
                        </template>
                        <template v-else-if="fld2.type === 'html'">
                          <div v-html="item[fld2.name]"></div>
                        </template>
                        <template v-else>{{ item[fld2.name] }}</template>
                        <template>{{ fld2.atag }}</template>
                      </div>
                    </template>
                  </template>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <slot name="footer"></slot>
  </div>
</template>

<script>
export default {
  props: [
    "fields",
    "rows",
    "noResult",
    "searchfn",
    "dblclick",
    "click",
    "rowstyle",
    "progress",
    "select",
    "header",
  ],

  name: "datatable",

  data() {
    return {
      search: "",
      cnt: 0,
      isTableMoreThanBox: false,
    };
  },

  computed: {
    items() {
      return this.rows ? this.rows : [];
    },
  },
  watch: {
    search(value) {
      if (this.searchfn) {
        this.searchfn(value);
      }
    },
  },
  methods: {
    hasItemSlot(slotname) {
      return !!this.$scopedSlots[slotname];
    },

    selShift(item, e) {
      if (e.shiftKey) {
        let fl = false;
        if (this.cnt > 1) {
          this.rows.forEach((item) => {
            if (item["_select"]) {
              item["_select"] = false;
            }
          });
          this.cnt = 0;
          return;
        }
        if (!this.cnt) {
          item["_select"] = true;
          this.cnt++;
          return;
        }
        this.rows.forEach((it) => {
          if (it["_select"]) {
            fl = true;
          }
          if (fl) {
            it["_select"] = true;
            this.cnt++;
          }
          if (it == item) {
            fl = false;
            return;
          }
        });
      } else if (e.ctrlKey) {
        item["_select"] = !item["_select"];
        if (item["_select"]) {
          this.cnt++;
        } else {
          this.cnt--;
        }
      } else {
        // this.rows.forEach(item => {
        //   if (item["_select"]) {
        //     item["_select"] = false;
        //   }
        // });
        // item["_select"] = true;
      }
    },

    openDblClick(value, e) {
      if (this.dblclick) {
        this.dblclick(value, e);
      }
    },
    buttonClick(click, item, event, items) {
      if (click) {
        click(item, event, items);
      }
    },
    openClick(value, e) {
      if (this.select) {
        this.selShift(value, e);
      }
      if (this.click) {
        console.log("click");
        this.click(value, e);
      }
    },
    trStyle(value) {
      if (this.rowstyle) {
        return this.rowstyle(value);
      }
    },
    // isSame(ftd, field, text) {
    //   ftd = ftd || {};
    //   ftd[field] = ftd[field] === undefined || null;
    //   if (ftd[field] === undefined || ftd[field] == null) {
    //     return false;
    //   }
    //   if (ftd[field] === text) {
    //     return true;
    //   }
    // },
    asValue(ftd, field) {
      ftd = ftd || {};
      return ftd[field] || null;
    },

    tableScrollHint() {
      const { table, tableBox } = this.$refs;

      if (table.offsetWidth > tableBox.clientWidth) {
        this.isTableMoreThanBox = true;
      } else {
        this.isTableMoreThanBox = false;
      }
    },
  },

  mounted() {
    setTimeout(() => {
      this.tableScrollHint();
    }, 100);
  },
};
</script>
