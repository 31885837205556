import Vue from "vue";
import { MLInstaller, MLCreate, MLanguage } from "vue-multilanguage";
var ru = require("./lang/ru.json");
var en = require("./lang/en.json");
var currentLang = "en";
if (localStorage.getItem("ovision-ml")) {
  try {
    let l = JSON.parse(localStorage.getItem("ovision-ml"));
    currentLang = l.lang;
  } catch (err) {
    console.error(err);
  }
}

Vue.use(MLInstaller);

export default new MLCreate({
  initial: currentLang,
  save: process.env.NODE_ENV === "production",
  languages: [new MLanguage("en").create(en), new MLanguage("ru").create(ru)],
});
