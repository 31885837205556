<template>
  <div class="mt-5">
    <v-row>
      <v-col cols="12">
        <h3>{{ $ml.get("setting.log") }}</h3>
      </v-col>
      <v-col cols="12">
        <textarea class="form-control" rows="15" v-model="log" readonly />
      </v-col>
    </v-row>

    <v-row justify="end">
      <v-col cols="12" md="auto">
        <v-btn color="success" @click="getLogAll">
          {{ $ml.get("setting.download_logs") }}
        </v-btn>
      </v-col>
      <v-col cols="12" md="auto">
        <v-text-field
          v-model="maxsize"
          type="number"
          max="20000"
          min="1000"
          width="100"
          outlined
          required
          dense
          hide-details
        />
      </v-col>
      <v-col cols="12" md="auto">
        <v-btn color="primary" @click="getLog">
          {{ $ml.get("setting.log_reload") }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h3>{{ $ml.get("setting.user_log") }}</h3>
      </v-col>
      <v-col cols="12">
        <textarea class="form-control" rows="15" v-model="userLog" readonly />
      </v-col>
    </v-row>

    <v-row justify="end">
      <v-col cols="12" md="auto">
        <v-btn color="success" @click="getLogAllUser">
          {{ $ml.get("setting.download_logs_user") }}
        </v-btn>
      </v-col>
      <v-col cols="12" md="auto">
        <v-text-field
          v-model="maxsizeUser"
          type="number"
          max="20000"
          min="1000"
          width="100"
          outlined
          required
          dense
          hide-details
        />
      </v-col>
      <v-col cols="12" md="auto">
        <v-btn color="primary" @click="getUserLog">
          {{ $ml.get("setting.user_log_reload") }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      log: "",
      userLog: "",
      maxsize: 1500,
      maxsizeUser: 1500,
    };
  },
  methods: {
    getLog() {
      axios
        .get("/system/log", {
          params: {
            size: this.maxsize,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            this.log = response.data.data.text;
          }
        });
    },
    getUserLog() {
      axios
        .get("/change/log", {
          params: {
            size: this.maxsizeUser,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            this.userLog = response.data.data.text;
          }
        });
    },
    getLogAll() {
      axios
        .get("/system/log_all", { responseType: "arraybuffer" })
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/zip" })
          );

          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "system.log.zip");
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch(() => {
          this.$notify({
            group: "info",
            type: "error",
            text: this.$ml.get("setting.download_logs_error"),
          });
        });
    },
    getLogAllUser() {
      axios
        .get("/change/log_all", {
          responseType: "arraybuffer",
          params: {
            size: this.maxsizeUser,
          },
        })
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/zip" })
          );

          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "updateuser.log.zip");
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch(() => {
          this.$notify({
            group: "info",
            type: "error",
            text: this.$ml.get("setting.download_logs_error"),
          });
        });
    },
  },
  mounted() {
    this.getLog();
    this.getUserLog();
  },
};
</script>
<style scoped>
textarea {
  padding: 10px;
  width: 100%;
}
</style>
