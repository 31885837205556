<template>
  <v-app style="background-color: transparent">
    <!-- MainPageMenu /-->
    <div v-if="loaded" class="visitors-container">
      <notifications group="info" position="top right" />

      <div class="view-switcher">
        <v-btn-toggle v-model="viewType">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on">
                <v-icon>mdi-card-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ $ml.get("users.combinedMode") }}</span>
          </v-tooltip>

          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on">
                <v-icon>mdi-card-multiple-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ $ml.get("users.separateMode") }}</span>
          </v-tooltip>
        </v-btn-toggle>
      </div>

      <div v-if="viewType === 0" class="user-card-wrapper">
        <div
          class="user-card user-card--once"
          :style="
            visitorLast.temperatureValue >= tAlarm ? 'background: #ff9996;' : ''
          "
        >
          <div
            class="column image-column"
            :style="{ backgroundImage: `url(${visitorLast.photo})` }"
          />

          <div class="user-card__content">
            <div
              v-if="visitorLast.deleted && !this.l.cloud_connect"
              style="width: 100%; text-align: center; margin-bottom: 10px"
            >
              <button class="btn btn-danger unblock" @click="userUndelelete">
                {{ $ml.get("users.restore") }}
              </button>
            </div>
            <div
              v-if="visitorLast.blocked && !this.l.cloud_connect"
              style="text-align: center; margin-bottom: 10px"
            >
              <button
                class="btn btn-danger unblock"
                @click="guestUnblock('last')"
              >
                {{ $ml.get("users.unblock") }}
              </button>
            </div>
            <div
              v-if="!visitorLast.user_id && !this.l.cloud_connect"
              style="text-align: center; margin-bottom: 10px"
            >
              <button
                class="btn btn-danger unblock"
                @click="guestReg(visitorLast)"
              >
                {{ $ml.get("users.guestPass") }}
              </button>
            </div>

            <div
              v-if="visitorLast.identity_types.length"
              class="mb-2 text-center"
            >
              <span
                v-for="type in visitorLast.identity_types"
                :key="type"
                class="mr-2 ml-2"
              >
                <v-tooltip v-if="type === 'face'" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" small>
                      mdi-emoticon-happy-outline
                    </v-icon>
                  </template>
                  <span>{{ $ml.get("users.face") }}</span>
                </v-tooltip>

                <v-tooltip v-if="type === 'qr'" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" small>
                      mdi-qrcode-scan
                    </v-icon>
                  </template>
                  <span>{{ $ml.get("users.qr") }}</span>
                </v-tooltip>

                <v-tooltip v-if="type === 'card'" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" small>
                      mdi-card-bulleted-outline
                    </v-icon>
                  </template>
                  <span>{{ $ml.get("users.card") }}</span>
                </v-tooltip>
              </span>
            </div>

            <div class="title">{{ visitorLast.full_name }}</div>
            <div class="title-small">
              {{ $ml.get("users." + visitorLast.type_user) }}
              <span
                v-if="visitorLast.temperature && !l.temperature_rise"
                :style="
                  visitorLast.temperatureValue >= tAlarm
                    ? 'color: red; font-size: 22px; font-weight: 600;'
                    : 'font-size: 22px; color: green;'
                "
                >{{ visitorLast.temperature.replace("/", "") }}</span
              >
            </div>
            <div
              v-for="org in visitorLast.organization"
              :key="org.name"
              class="title-company"
            >
              {{ org.name }}
            </div>
            <div v-if="visitorLast.info" class="title-small">
              {{ visitorLast.info }}
            </div>
          </div>
        </div>
      </div>

      <div v-if="viewType === 1" class="user-card-wrapper">
        <div
          class="user-card"
          :style="
            visitorEntrance.temperatureValue >= tAlarm
              ? 'background: #ff9996;'
              : ''
          "
        >
          <div class="user-card__badge">
            {{ visitorEntrance.purpose }}
          </div>

          <div
            class="column image-column"
            :style="{ backgroundImage: `url(${visitorEntrance.photo})` }"
          />

          <div class="user-card__content">
            <div
              v-if="visitorEntrance.deleted && !this.l.cloud_connect"
              style="width: 100%; text-align: center; margin-bottom: 10px"
            >
              <button class="btn btn-danger unblock" @click="userUndelelete">
                {{ $ml.get("users.restore") }}
              </button>
            </div>
            <div
              v-if="visitorEntrance.blocked && !this.l.cloud_connect"
              style="text-align: center; margin-bottom: 10px"
            >
              <button
                class="btn btn-danger unblock"
                @click="guestUnblock('entrance')"
              >
                {{ $ml.get("users.unblock") }}
              </button>
            </div>
            <div
              v-if="!visitorEntrance.user_id && !this.l.cloud_connect"
              style="text-align: center; margin-bottom: 10px"
            >
              <button
                class="btn btn-danger unblock"
                @click="guestReg(visitorEntrance)"
              >
                {{ $ml.get("users.guestPass") }}
              </button>
            </div>

            <div
              v-if="visitorEntrance.identity_types.length"
              class="mb-2 text-center"
            >
              <span
                v-for="type in visitorEntrance.identity_types"
                :key="type"
                class="mr-2 ml-2"
              >
                <v-tooltip v-if="type === 'face'" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" small>
                      mdi-emoticon-happy-outline
                    </v-icon>
                  </template>
                  <span>{{ $ml.get("users.face") }}</span>
                </v-tooltip>

                <v-tooltip v-if="type === 'qr'" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" small>
                      mdi-qrcode-scan
                    </v-icon>
                  </template>
                  <span>{{ $ml.get("users.qr") }}</span>
                </v-tooltip>

                <v-tooltip v-if="type === 'card'" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" small>
                      mdi-card-bulleted-outline
                    </v-icon>
                  </template>
                  <span>{{ $ml.get("users.card") }}</span>
                </v-tooltip>
              </span>
            </div>

            <div class="title">{{ visitorEntrance.full_name }}</div>
            <div class="title-small">
              {{ $ml.get("users." + visitorEntrance.type_user) }}
              <span
                v-if="visitorEntrance.temperature && !l.temperature_rise"
                :style="
                  visitorEntrance.temperatureValue >= tAlarm
                    ? 'color: red; font-size: 22px; font-weight: 600;'
                    : 'font-size: 22px; color: green;'
                "
                >{{ visitorEntrance.temperature.replace("/", "") }}</span
              >
            </div>
            <div
              v-for="org in visitorEntrance.organization"
              :key="org.name"
              class="title-company"
            >
              {{ org.name }}
            </div>
            <div v-if="visitorEntrance.info" class="title-small">
              {{ visitorEntrance.info }}
            </div>
            <div class="title-small">
              {{ visitorEntrance.visits }}
            </div>
          </div>
        </div>

        <div
          class="user-card"
          :style="
            visitorExit.temperatureValue >= tAlarm ? 'background: #ff9996;' : ''
          "
        >
          <div class="user-card__badge">
            {{ visitorExit.purpose }}
          </div>

          <div
            class="column image-column"
            :style="{ backgroundImage: `url(${visitorExit.photo})` }"
          />

          <div class="user-card__content">
            <div
              v-if="visitorExit.deleted && !this.l.cloud_connect"
              style="width: 100%; text-align: center; margin-bottom: 10px"
            >
              <button class="btn btn-danger unblock" @click="userUndelelete">
                {{ $ml.get("users.restore") }}
              </button>
            </div>
            <div
              v-if="visitorExit.blocked && !this.l.cloud_connect"
              style="text-align: center; margin-bottom: 10px"
            >
              <button
                class="btn btn-danger unblock"
                @click="guestUnblock('exit')"
              >
                {{ $ml.get("users.unblock") }}
              </button>
            </div>
            <div
              v-if="!visitorExit.user_id && !this.l.cloud_connect"
              style="text-align: center; margin-bottom: 10px"
            >
              <button
                class="btn btn-danger unblock"
                @click="guestReg(visitorExit)"
              >
                {{ $ml.get("users.guestPass") }}
              </button>
            </div>

            <div
              v-if="visitorExit.identity_types.length"
              class="mb-2 text-center"
            >
              <span
                v-for="type in visitorExit.identity_types"
                :key="type"
                class="mr-2 ml-2"
              >
                <v-tooltip v-if="type === 'face'" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" small>
                      mdi-emoticon-happy-outline
                    </v-icon>
                  </template>
                  <span>{{ $ml.get("users.face") }}</span>
                </v-tooltip>

                <v-tooltip v-if="type === 'qr'" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" small>
                      mdi-qrcode-scan
                    </v-icon>
                  </template>
                  <span>{{ $ml.get("users.qr") }}</span>
                </v-tooltip>

                <v-tooltip v-if="type === 'card'" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" small>
                      mdi-card-bulleted-outline
                    </v-icon>
                  </template>
                  <span>{{ $ml.get("users.card") }}</span>
                </v-tooltip>
              </span>
            </div>

            <div class="title">{{ visitorExit.full_name }}</div>
            <div class="title-small">
              {{ $ml.get("users." + visitorExit.type_user) }}
              <span
                v-if="visitorExit.temperature && !l.temperature_rise"
                :style="
                  visitorExit.temperatureValue >= tAlarm
                    ? 'color: red; font-size: 22px; font-weight: 600;'
                    : 'font-size: 22px; color: green;'
                "
                >{{ visitorExit.temperature.replace("/", "") }}</span
              >
            </div>
            <div
              v-for="org in visitorExit.organization"
              :key="org.name"
              class="title-company"
            >
              {{ org.name }}
            </div>
            <div v-if="visitorExit.info" class="title-small">
              {{ visitorExit.info }}
            </div>
            <div class="title-small">
              {{ visitorExit.visits }}
            </div>
          </div>
        </div>
      </div>

      <div class="visitors-table">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a
              :class="'nav-link' + activeBlock('all')"
              href="#visite-all"
              @click="setTab('all')"
              data-toggle="tab"
              >{{ $ml.get("users.all") }}</a
            >
          </li>
          <li class="nav-item">
            <a
              :class="'nav-link' + activeBlock('employees')"
              href="#visite-employees"
              @click="setTab('employees')"
              data-toggle="tab"
              >{{ $ml.get("users.employees") }}</a
            >
          </li>
          <li class="nav-item">
            <a
              :class="'nav-link ' + activeBlock('guests')"
              href="#visite-guests"
              @click="setTab('guests')"
              data-toggle="tab"
              >{{ $ml.get("users.guests") }}</a
            >
          </li>
          <li class="nav-item">
            <a
              :class="'nav-link' + activeBlock('indoors')"
              href="#indoors"
              @click="setTab('indoors')"
              data-toggle="tab"
              >{{ $ml.get("users.indoors") }}</a
            >
          </li>
          <li class="nav-item">
            <a
              :class="'nav-link' + activeBlock('alarmvisit')"
              href="#alarmvisit"
              @click="setTab('alarmvisit')"
              data-toggle="tab"
              >{{ $ml.get("users.alarm") }}</a
            >
          </li>
        </ul>
        <div class="tab-content">
          <div
            :class="
              'tab-pane add-new-visitor-form-container' + activeBlock('all')
            "
            id="visite-all"
          >
            <datatable
              :fields="fields"
              :rows="visitors['all']"
              :click="showVisitor"
              :dblclick="showUser"
              :rowstyle="styleRow"
            >
              {{ $ml.get("users.no_passes_registered") }}

              <template #[`item.identity_types`]="{ item }">
                <div v-if="item.identity_types.length">
                  <span v-for="type in item.identity_types" :key="type">
                    <v-tooltip v-if="type === 'face'" top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" small>
                          mdi-emoticon-happy-outline
                        </v-icon>
                      </template>
                      <span>{{ $ml.get("users.face") }}</span>
                    </v-tooltip>

                    <v-tooltip v-if="type === 'qr'" top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" small>
                          mdi-qrcode-scan
                        </v-icon>
                      </template>
                      <span>{{ $ml.get("users.qr") }}</span>
                    </v-tooltip>

                    <v-tooltip v-if="type === 'card'" top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" small>
                          mdi-card-bulleted-outline
                        </v-icon>
                      </template>
                      <span>{{ $ml.get("users.card") }}</span>
                    </v-tooltip>
                  </span>
                </div>
              </template>
            </datatable>
          </div>
          <div
            :class="
              'tab-pane add-new-visitor-form-container' +
              activeBlock('employees')
            "
            id="visite-employees"
          >
            <datatable
              :fields="fields"
              :rows="visitors['employees']"
              :click="showVisitor"
              :dblclick="showUser"
              :rowstyle="styleRow"
            >
              {{ $ml.get("users.no_passes_registered") }}

              <template #[`item.identity_types`]="{ item }">
                <div v-if="item.identity_types.length">
                  <span v-for="type in item.identity_types" :key="type">
                    <v-tooltip v-if="type === 'face'" top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" small>
                          mdi-emoticon-happy-outline
                        </v-icon>
                      </template>
                      <span>{{ $ml.get("users.face") }}</span>
                    </v-tooltip>

                    <v-tooltip v-if="type === 'qr'" top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" small>
                          mdi-qrcode-scan
                        </v-icon>
                      </template>
                      <span>{{ $ml.get("users.qr") }}</span>
                    </v-tooltip>

                    <v-tooltip v-if="type === 'card'" top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" small>
                          mdi-card-bulleted-outline
                        </v-icon>
                      </template>
                      <span>{{ $ml.get("users.card") }}</span>
                    </v-tooltip>
                  </span>
                </div>
              </template>
            </datatable>
          </div>
          <div
            :class="
              'tab-pane add-new-visitor-form-container' + activeBlock('guests')
            "
            id="visite-guests"
          >
            <datatable
              :fields="fields"
              :rows="visitors['guests']"
              :click="showVisitor"
              :dblclick="showUser"
              :rowstyle="styleRow"
            >
              {{ $ml.get("users.no_passes_registered") }}

              <template #[`item.identity_types`]="{ item }">
                <div v-if="item.identity_types.length">
                  <span v-for="type in item.identity_types" :key="type">
                    <v-tooltip v-if="type === 'face'" top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" small>
                          mdi-emoticon-happy-outline
                        </v-icon>
                      </template>
                      <span>{{ $ml.get("users.face") }}</span>
                    </v-tooltip>

                    <v-tooltip v-if="type === 'qr'" top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" small>
                          mdi-qrcode-scan
                        </v-icon>
                      </template>
                      <span>{{ $ml.get("users.qr") }}</span>
                    </v-tooltip>

                    <v-tooltip v-if="type === 'card'" top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" small>
                          mdi-card-bulleted-outline
                        </v-icon>
                      </template>
                      <span>{{ $ml.get("users.card") }}</span>
                    </v-tooltip>
                  </span>
                </div>
              </template>
            </datatable>
          </div>
          <div
            :class="
              'tab-pane add-new-visitor-form-container' + activeBlock('indoors')
            "
            id="indoors"
          >
            <datatable
              :fields="fields_indoors"
              :rows="indoors.data"
              :click="showVisitor"
              :dblclick="showUser"
              :rowstyle="styleRow"
            >
              {{ $ml.get("users.no_passes_registered") }}
              <template v-slot:header></template>
              <template v-slot:footer>
                <vue-pagination
                  :pagination="indoors.pagination"
                  @paginate="getIndoors"
                  :offset="offset"
                ></vue-pagination>
                <button
                  v-if="indoors.data.length"
                  class="btn btn-primary add"
                  style="float: right"
                  @click="closeVisitAll"
                >
                  {{ $ml.get("button.closeAllVisits") }}
                </button>
                <button
                  name="reportIndoors"
                  v-if="indoors.data.length"
                  class="btn btn-success mdi mdi-file-excel mr-1"
                  style="float: right"
                  :title="$ml.get('advanced.reportIndoors')"
                  @click="reportIndoors"
                ></button>
              </template>

              <template #[`item.identity_types`]="{ item }">
                <div v-if="item.identity_types.length">
                  <span v-for="type in item.identity_types" :key="type">
                    <v-tooltip v-if="type === 'face'" top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" small>
                          mdi-emoticon-happy-outline
                        </v-icon>
                      </template>
                      <span>{{ $ml.get("users.face") }}</span>
                    </v-tooltip>

                    <v-tooltip v-if="type === 'qr'" top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" small>
                          mdi-qrcode-scan
                        </v-icon>
                      </template>
                      <span>{{ $ml.get("users.qr") }}</span>
                    </v-tooltip>

                    <v-tooltip v-if="type === 'card'" top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" small>
                          mdi-card-bulleted-outline
                        </v-icon>
                      </template>
                      <span>{{ $ml.get("users.card") }}</span>
                    </v-tooltip>
                  </span>
                </div>
              </template>
            </datatable>
          </div>
          <div
            :class="
              'tab-pane add-new-visitor-form-container' +
              activeBlock('alarmvisit')
            "
            id="alarmvisit"
          >
            <datatable
              :fields="fields_nousers"
              :rows="nousers.data"
              :click="showVisitor"
              :rowstyle="styleRow"
            >
              {{ $ml.get("users.no_passes_registered") }}
              <template v-slot:header></template>
              <template v-slot:footer>
                <div class="row">
                  <div class="col-sm-10">
                    <vue-pagination
                      :pagination="nousers.pagination"
                      @paginate="getNoUsers"
                      :offset="offset"
                    ></vue-pagination>
                  </div>
                  <div class="col-sm-2">
                    <button
                      name="reportNoUsers"
                      :disabled="reportNoUserDisable"
                      v-if="nousers.data.length"
                      class="btn btn-success mdi mdi-file-excel mr-1"
                      style="float: right"
                      :title="$ml.get('advanced.reportNoUsers')"
                      @click="reportNoUsers"
                    ></button>
                  </div>
                </div>
              </template>

              <template #[`item.identity_types`]="{ item }">
                <div v-if="item.identity_types.length">
                  <span v-for="type in item.identity_types" :key="type">
                    <v-tooltip v-if="type === 'face'" top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" small>
                          mdi-emoticon-happy-outline
                        </v-icon>
                      </template>
                      <span>{{ $ml.get("users.face") }}</span>
                    </v-tooltip>

                    <v-tooltip v-if="type === 'qr'" top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" small>
                          mdi-qrcode-scan
                        </v-icon>
                      </template>
                      <span>{{ $ml.get("users.qr") }}</span>
                    </v-tooltip>

                    <v-tooltip v-if="type === 'card'" top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" small>
                          mdi-card-bulleted-outline
                        </v-icon>
                      </template>
                      <span>{{ $ml.get("users.card") }}</span>
                    </v-tooltip>
                  </span>
                </div>
              </template>

              <template #[`item.pass_type`]="{ item }">
                <v-tooltip v-if="item.pass_type === 'before'" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" small> mdi-check </v-icon>
                  </template>
                  <span>{{ $ml.get("users.recognized") }}</span>
                </v-tooltip>

                <v-tooltip v-if="item.pass_type === 'pass'" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" small>
                      mdi-check-all
                    </v-icon>
                  </template>
                  <span>{{ $ml.get("users.recognizedAndPass") }}</span>
                </v-tooltip>

                <v-tooltip v-if="item.pass_type === 'alarm'" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" small>
                      mdi-account-remove-outline
                    </v-icon>
                  </template>
                  <span>{{ $ml.get("users.notRecognized") }}</span>
                </v-tooltip>

                <v-tooltip v-if="item.pass_type === 'error'" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" small>
                      mdi-alert-circle-outline
                    </v-icon>
                  </template>
                  <span>{{ $ml.get("users.error") }}</span>
                </v-tooltip>
              </template>
            </datatable>
          </div>
        </div>
      </div>
    </div>
    <div v-if="loaded" class="visitors-container-footer">
      <v-switch
        v-if="l.temperature_enabled"
        v-model="temperatureCheck"
        :label="$ml.get('users.displayWithTemperature')"
        @change="setTab(v)"
      />
    </div>
  </v-app>
</template>

<script>
import axios from "axios";
import Datatable from "../components/datatable";
import VuePagination from "../components/pagination";
import router from "../router";
//import UserCard from "../components/UserCard";
//import moment from "moment";
import moment from "moment-timezone";

export default {
  components: { Datatable, VuePagination },
  data() {
    return {
      loaded: false,
      temperatureCheck: false,
      v: "alarmvisit",
      fields: [
        {
          name: "identity_types",
          style: "width: 16px",
        },
        {
          name: "avatar",
          title: "",
          style: "width: 50px;",
          type: "img",
          header: { style: "width: 91px" },
        },
        {
          group: [
            {
              name: "full_name",
              title: this.$ml.get("users.fullname"),
              btag: "",
              atag: "",
            },
            {
              name: "purpose",
              title: this.$ml.get("users.device"),
              style:
                "font-size: 13px; color: #999; float: left; margin-right: 3px;",
            },
            {
              name: "utype",
              title: this.$ml.get("users.status"),
              btag: " /",
              style: "font-size: 13px; color: #999; float: left;",
            },
            {
              name: "temperature",
              title: this.$ml.get("users.temperature"),
              btag: "",
              style:
                "font-size: 13px; color: #999; float: left; margin-left: 3px;",
            },
          ],
          header: { text: this.$ml.get("users.visitor") },
        },
        {
          name: "visits",
          header: { text: this.$ml.get("users.visit_time") },
          style: "width: 50px;",
        },
      ],
      fields_indoors: [
        {
          name: "identity_types",
          style: "width: 16px",
        },
        {
          name: "avatar",
          title: "",
          style: "width: 50px;",
          type: "img",
          header: { style: "width: 91px" },
        },
        {
          group: [
            {
              name: "full_name",
              title: this.$ml.get("users.fullname"),
              btag: "",
              atag: "",
            },
            {
              name: "utype",
              title: this.$ml.get("users.status"),
              style: "font-size: 10px; float: left; margin-right: 3px;",
            },
            {
              name: "company",
              title: this.$ml.get("users.subdivision"),
              btag: " : ",
              style: "font-size: 10px;",
            },
          ],
          header: { text: this.$ml.get("users.visitor") },
        },
        {
          name: "visits",
          header: { text: this.$ml.get("users.visit_time") },
          style: "width: 50px;",
        },
        {
          type: "button",
          title: this.$ml.get("users.eventClose"),
          class: "btn btn-success mb-3 mdi mdi-open-in-new",
          click: this.closeVisit,
          style: "width: 40px; margin-left: 2px;",
        },
      ],
      nousers: {
        pagination: {
          total: 0,
          per_page: 2,
          from: 1,
          to: 10,
          current_page: 1,
        },
        data: [],
      },
      fields_nousers: [
        {
          name: "pass_type",
          style: "width: 16px",
        },
        {
          name: "identity_types",
          style: "width: 16px",
        },
        {
          name: "avatar",
          title: "",
          style: "width: 50px;",
          type: "img",
          header: { style: "width: 91px" },
        },
        {
          group: [
            {
              name: "full_name",
              title: this.$ml.get("users.fullname"),
              btag: "",
              atag: "",
            },
            {
              name: "purpose",
              title: this.$ml.get("users.device"),
              style: "font-size: 10px; float: left; margin-right: 3px;",
            },
            {
              name: "temperature",
              title: this.$ml.get("users.temperature"),
              btag: "",
              style: "font-size: 10px; float: left; margin-left: 3px;",
            },
          ],
          header: { text: this.$ml.get("users.visitor") },
        },
        {
          name: "visits",
          header: { text: this.$ml.get("users.visit_time") },
          style: "width: 50px;",
        },
      ],
      offset: 1,
      visitors: {
        all: [],
        employees: [],
        guests: [],
      },
      indoors: {
        pagination: {
          total: 0,
          per_page: 2,
          from: 1,
          to: 10,
          current_page: 1,
        },
        data: [],
      },
      visitorEntrance: {
        photo: "/avatar/profile/avatar.png",
        type_user: "guest",
        identity_types: [],
      },
      visitorExit: {
        photo: "/avatar/profile/avatar.png",
        type_user: "guest",
        identity_types: [],
      },
      visitorLast: {
        photo: "/avatar/profile/avatar.png",
        type_user: "guest",
        identity_types: [],
      },
      tAlarm: 37.4,
      l: {
        lang: "en",
        show: false,
        temp_meas: "c",
        temperature_range: [],
        temperature_enabled: false,
        temperature_rise: false,
      },
      reportNoUserDisable: false,

      viewType: parseInt(localStorage.getItem("viewType")) || 0,
    };
  },

  watch: {
    viewType(type) {
      localStorage.setItem("viewType", type);
    },
  },

  methods: {
    newvisit: function (item) {
      console.log("newvisit:", item);
      this.correctVisits(item, false);
      if (this.$auth.user().type_user == "guard") {
        //console.log(this.$auth.user().zones, parseInt(item.zone_id))
        if (!this.$auth.user().zones.includes(parseInt(item.zone_id))) {
          return;
        }
      }

      this.visitors["all"].unshift(item);
      this.removeOldVisits("all");
      if (
        item.type_user == "employee" &&
        !["all", "indoors", "alarmvisit"].includes(this.v)
      ) {
        this.visitors[this.v].unshift(item);
        this.removeOldVisits(this.v);
      }
      if (item.identity_types === undefined) {
        item.identity_types = [];
      }

      this.visitorLast = { ...item };

      if (item.purposeType === "entrance" || item.purposeType === "duplex") {
        this.visitorEntrance = { ...item };
      }
      if (item.purposeType === "exit") {
        this.visitorExit = { ...item };
      }

      if (this.v == "indoors") {
        this.getIndoors();
      }
    },
    beforevisit: function (item) {
      this.correctVisits(item, false);
      if (this.$auth.user().type_user == "guard") {
        //console.log(this.$auth.user().zones, parseInt(item.zone_id))
        if (!this.$auth.user().zones.includes(parseInt(item.zone_id))) {
          return;
        }
      }
      this.temperatureAlarm(item);

      this.visitorLast = { ...item };

      if (item.purposeType === "entrance" || item.purposeType === "duplex") {
        this.visitorEntrance = { ...item };
      }
      if (item.purposeType === "exit") {
        this.visitorExit = { ...item };
      }

      if (this.v == "alarmvisit") {
        this.getNoUsers();
      }
    },
    alarmvisit: function (item) {
      this.correctVisits(item, false);
      if (this.$auth.user().type_user == "guard") {
        if (!this.$auth.user().zones.includes(parseInt(item.zone_id))) {
          return;
        }
      }
      this.temperatureAlarm(item);

      this.visitorLast = { ...item };

      if (item.purposeType === "entrance" || item.purposeType === "duplex") {
        this.visitorEntrance = { ...item };
      }
      if (item.purposeType === "exit") {
        this.visitorExit = { ...item };
      }

      if (this.v == "alarmvisit") {
        this.getNoUsers();
      }
    },
    removeOldVisits(tab) {
      const cnt = 50;
      // Удаление старых записей за пределами лимита
      if (["all", "employee", "guest"].includes(tab)) {
        let l = this.visitors[tab].length;
        if (l > cnt) {
          this.visitors[tab].splice(cnt, l - cnt);
        }
      }
    },
    activeBlock(name) {
      return this.v == name ? " active" : "";
    },
    setTab(tab) {
      localStorage.setItem("terminal.dascboard.tabs", tab);
      // localStorage.removeItem("terminal.users." + tab + ".current_page");
      // this.users[tab].pagination.current_page = 1;
      if (tab == "indoors") {
        this.indoors.pagination.current_page = 1;
        this.getIndoors();
      } else if (tab == "alarmvisit") {
        this.nousers.pagination.current_page = 1;
        this.getNoUsers();
      } else this.getVisitors(tab);
      this.v = tab;
    },
    // Гостевой пропуск
    guestReg(visitor) {
      this.errorMessage = "";
      const due_to = this.$moment().add(24, "hour").toISOString();
      let form = {
        first_name:
          this.$ml.get("users.guest") + "-" + moment().format("DDMMYYHHmm"),
        last_name: "",
        middle_name: "",
        type_user: "guest",
        phone: "",
        photo_encrypted: visitor.photo,
        blocked: false,
        blacklisted: false,
        data: `{ "onepass": true, "expire_date": "${due_to}"}`,
      };
      let config = {
        header: {
          "Content-Type": "application/json",
        },
        timeout: 20000,
      };
      let _this = this;
      axios
        .post("/user/add", form, config)
        .then((response) => {
          console.log(response);
          if (response.data.status.code != 0) {
            let errorMessage = response.data.status.message;
            if (
              errorMessage.indexOf("subdivision ID not found") !== -1 ||
              errorMessage.indexOf("Subdivision ID not found") !== -1
            ) {
              errorMessage = this.$ml.get("message.NoSubdivisionSelected");
            }
            if (errorMessage.indexOf("cant extract features") !== -1) {
              errorMessage = this.$ml.get("message.CantFaceImage");
            }
            if (errorMessage.indexOf("Face match found User") !== -1) {
              // Пользователь уже в базе
              let userID = parseInt(
                errorMessage.replace("Face match found User ", "").trim()
              );
              let _this = this;
              this.$dialog
                .confirm(
                  `<h3>${this.$ml.get("message.Twin")} #${userID}</h3>`,
                  {
                    loader: false,
                    okText: this.$ml.get("button.show"),
                    cancelText: this.$ml.get("button.cancel"),
                    backdropClose: true,
                  }
                )
                .then(function () {
                  router.push({ path: `/user/` + userID });
                });
            }
            this.$notify({
              group: "info",
              type: "error",
              text: errorMessage,
            });
          } else {
            let user = response.data.data;
            if (visitor.identity_types === undefined) {
              visitor.identity_types = [];
            }

            this.visitorLast = {
              user_id: user.id,
              photo: "/avatar/profile/" + user.photo,
              type_user: user.type_user,
              full_name: (user.last_name + " " + user.first_name).trim(),
              identity_types: user.identity_types,
              created_at: user.created_at,
            };

            if (
              visitor.purposeType === "entrance" ||
              visitor.purposeType === "duplex"
            ) {
              this.visitorEntrance = {
                user_id: user.id,
                photo: "/avatar/profile/" + user.photo,
                full_name: (user.last_name + " " + user.first_name).trim(),
                type_user: user.type_user,
                identity_types: visitor.identity_types,
                created_at: user.created_at,
              };
            }

            if (visitor.purposeType === "exit") {
              this.visitorExit = {
                user_id: user.id,
                photo: "/avatar/profile/" + user.photo,
                type_user: user.type_user,
                full_name: (user.last_name + " " + user.first_name).trim(),
                identity_types: visitor.identity_types,
                created_at: user.created_at,
              };
            }

            this.$notify({
              group: "info",
              type: "success",
              text: this.$ml.get("message.saveSuccess"),
            });
          }
        })
        .catch((error) => {
          if (error.response.status == 302) {
            let user = error.response.data.item;
            let item = {
              user_id: user.id,
              photo: user.photo,
              full_name: (user.last_name + " " + user.first_name).trim(),
              type_user: user.type_user,
            };
            this.correctVisits(item, false);

            this.visitorLast = { ...item };

            if (
              user.purposeType === "entrance" ||
              user.purposeType === "duplex"
            ) {
              this.visitorEntrance = { ...item };
            }
            if (user.purposeType === "exit") {
              this.visitorExit = { ...item };
            }
          } else {
            let errorMessage = "";
            if (error.response.status == 503) {
              errorMessage = this.$ml.get("message.UnavailableToDevice");
            } else {
              errorMessage = error.response.data.message;
            }
            this.$notify({
              group: "info",
              type: "error",
              text: errorMessage,
            });
          }
        });
    },
    closeVisitAll() {
      console.log(this.indoors.data.length);
      if (this.indoors.data.length) {
        let ids = [];
        this.indoors.data.forEach((it) => {
          ids.push(it.user_id);
        });
        axios.put("/visit/close", { ids }).then((response) => {
          if (response.status == 200) {
            this.getIndoors(true);
          }
        });
      }
    },
    closeVisit(item, event) {
      axios.put("/visit/close", { ids: [item.user_id] }).then((response) => {
        if (response.status == 200) {
          this.getIndoors();
        }
      });
    },
    deleteAlarm(item, alarm) {
      axios.delete("/alarmvisit/" + item.id + "/delete").then((response) => {
        if (response.status == 200) {
          //this.getIndoors();
        }
      });
    },
    guestUnblock(type) {
      let visitor;

      switch (type) {
        case "entrance":
          visitor = this.visitorEntrance;
          break;
        case "exit":
          visitor = this.visitorExit;
          break;
        case "last":
          visitor = this.visitorLast;
          break;
        default:
          break;
      }

      if (visitor && visitor.blocked && visitor.user_id) {
        axios
          .put("/user/unblocked", { ids: [parseInt(visitor.user_id)] })
          .then((response) => {
            if (response.data.status.code == 0) {
              visitor.blocked = false;
            }
          });
      }
    },
    styleRow(item) {
      if (item.blacklisted) {
        return "background: #000; color: #fff;";
      } else if (item.temperatureValue >= this.tAlarm) {
        return "background: #ff9996; color: #000;";
      }
    },
    showVisitor(item) {
      this.visitorLast = { ...item };

      if (item.purposeType === "entrance" || item.purposeType === "duplex") {
        this.visitorEntrance = { ...item };
      }
      if (item.purposeType === "exit") {
        this.visitorExit = { ...item };
      }
    },
    showUser(item) {
      //console.log(item);
      this.$router.push("/user/" + item.user_id);
    },
    getVisitors(tab) {
      let values = {
        temp: this.temperatureCheck,
      };
      if (tab == "employees") values.type_user = "employee";
      else if (tab == "guests") values.type_user = "guest";
      axios.get("/visitors", { params: values }).then((response) => {
        //console.log(response);
        this.visitors[tab] = response.data.data ? response.data.data : [];
        this.visitors[tab].forEach((item) => {
          this.correctVisits(item, false);
        });

        const visitorEntrance = this.visitors[tab].find(
          (user) =>
            user.purposeType === "entrance" || user.purposeType === "duplex"
        );
        const visitorExit = this.visitors[tab].find(
          (user) => user.purposeType === "exit"
        );
        if (visitorEntrance) {
          this.visitorEntrance = visitorEntrance;
        }
        if (visitorExit) {
          this.visitorExit = visitorExit;
        }
        if (this.visitors[tab].length) {
          this.visitorLast = this.visitors[tab][0];
        }
      });
    },
    getIndoors(isClose) {
      let values = {
        page: this.indoors.pagination.current_page,
        temp: this.temperatureCheck,
      };

      axios
        .get("/advanced/log", {
          params: values,
        })
        .then((response) => {
          //console.log(response);
          this.indoors.data = response.data.data ? response.data.data : [];
          this.indoors.pagination = response.data.meta.pagination || {};
          if (isClose) {
            this.closeVisitAll();
          } else {
            this.indoors.data.forEach((item) => {
              this.correctVisits(item, false);
            });
          }
        });
    },
    getNoUsers() {
      let values = {
        page: this.nousers.pagination.current_page,
        temp: this.temperatureCheck,
      };
      axios.get("/alarmvisits", { params: values }).then((response) => {
        if (response.data.status.code == 0) {
          this.nousers.data = response.data.data ? response.data.data : [];
          this.nousers.pagination = response.data.meta.pagination || {};
          this.nousers.data.forEach((item) => {
            //item.full_name = "User " + item.id;
            this.correctVisits(item, false);
          });
          const visitorEntrance = this.nousers.data.find(
            (user) =>
              user.purposeType === "entrance" || user.purposeType === "duplex"
          );
          const visitorExit = this.nousers.data.find(
            (user) => user.purposeType === "exit"
          );
          if (visitorEntrance) {
            this.visitorEntrance = visitorEntrance;
          }
          if (visitorExit) {
            this.visitorExit = visitorExit;
          }
          if (this.nousers.data.length) {
            this.visitorLast = this.nousers.data[0];
          }
        }
      });
    },
    temperatureAlarm(item) {
      if (item.temperatureValue && this.tAlarm) {
        if (item.temperatureValue > this.tAlarm) {
          console.log(item.temperatureValue, ">", this.tAlarm, item);
          let audio = new Audio(require("@/assets/sound/ambulance.mp3"));
          audio.play();
        }
      }
    },
    calculateCalcFeel(t) {
      if (this.l.temp_meas == "f") {
        return Math.round(((t * 9) / 5 + 32) * 10) / 10;
      } else {
        return t;
      }
    },
    correctVisits(item, is_short) {
      //.tz(String(item.CreatedAt), "Europe/Moscow")
      let format = is_short ? "HH:mm:ss" : "DD.MM.YY HH:mm:ss";
      item.visits = moment(String(item.created_at)).format(format);

      if (!item.type_user) {
        item.type_user = "guest";
      }
      let zone = "";
      let zone_id = 0;
      let data = {};
      let fullname = "";
      let udata = {};
      let device = "";
      let identity_types = [];
      let pass_type = "";
      if (item.data) {
        try {
          data = JSON.parse(item.data);
          zone = data.zone || "";
          fullname = data.name || this.$ml.get("users.guest");
          device = data.device_name || "";
          zone_id = data.zone_id || "";
          identity_types = this.getIdentityTypesArr(data.identity_types);
          if (data.type) {
            pass_type = data.type;
          } else {
            pass_type = item.user_id ? "before" : "alarm";
          }
        } catch (err) {
          console.log(item.data, err);
          return;
        }
      }
      if (!item.full_name || !item.full_name.trim()) {
        item.full_name = fullname;
      }

      if (item.udata) {
        try {
          udata = JSON.parse(item.udata);
        } catch (err) {
          console.log(item.udata, err);
          return;
        }
      }
      if (data.temperature > 0) {
        item.temperatureValue = data.temperature;
        if (!this.l.temperature_rise)
          item.temperature =
            "/ " +
            this.calculateCalcFeel(data.temperature) +
            "°" +
            this.l.temp_meas.toUpperCase();
      }

      item.zone_id = zone_id;
      item.identity_types = identity_types;
      item.pass_type = pass_type;
      item.company = "";
      let cmpname = [];
      item.organization = item.organization || [];
      item.organization.forEach((el) => {
        cmpname.push(el.name);
      });

      item.company = cmpname.join(", ");
      //console.log(data);

      if (data["image_encode"]) {
        item.photo = "data:image/png;base64," + data["image_encode"];
        item.avatar = item.photo;
      } else {
        item.photo = item.photo ? item.photo : "avatar.png";
        item.avatar = "/avatar/small/" + item.photo;
        item.photo = "/avatar/profile/" + item.photo;
      }
      //if (!item.type_user) item.type_user = "guest";

      // Вывод информационного поля
      if (item.type_user == "guest") {
        item.info = udata["visit_purpose"];
      } else {
        item.info = udata["post"];
      }
      item.udata = "";

      item.utype = this.$ml.get("users." + item.type_user);

      if (!item.purpose) item.purpose = "exit";

      item.purposeType = item.purpose;

      if (item.purpose && item.purpose != "off") {
        item.purpose = this.$ml.get("device.purpose_" + item.purpose.trim());
      }
      if (zone)
        item.purpose = item.purpose + " (" + zone + " / " + device + ") ";
      item.data = "";
      //console.log(item);
    },

    getIdentityTypesArr(types) {
      let result = ["face"];

      if (types) {
        result = types.split(",");
      }

      return result;
    },

    reportIndoors() {
      let params = {
        lang: this.l.lang,
      };

      let requestParams = {
        method: "get",
        params,
        url: "/report/occup",
        responseType: "blob",
      };

      axios.request(requestParams).then((response) => {
        if (response.status == 200) {
          let filename = "occup-" + moment().format("YYYYMMDDHHmmss") + ".xlsx";
          let url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: "application/xlsx",
            })
          );
          let link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
          link.remove();
        } else {
          this.$dialog
            .alert(this.$ml.get("advanced.nopass"), {
              loader: false,
              okText: "OK",
              backdropClose: true,
            })
            .then(function (dialog) {});
        }
      });
    },
    reportNoUsers() {
      this.reportNoUserDisable = true;
      this.getReportNoUsers(1);
    },
    getReportNoUsers(page) {
      const limit = 1000;
      let params = {
        lang: this.l.lang,
        page,
        limit,
      };

      let requestParams = {
        method: "get",
        params,
        url: "/report/nousers",
        responseType: "blob",
      };

      axios.request(requestParams).then((response) => {
        if (response.status == 200) {
          let filename =
            "nousers-" +
            moment().format("YYYYMMDDHHmmss") +
            "-p" +
            page +
            ".xlsx";
          let url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: "application/xlsx",
            })
          );
          let link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
          link.remove();
          if (page * limit < this.nousers.pagination.total) {
            this.getReportNoUsers(page + 1);
          } else {
            this.reportNoUserDisable = false;
          }
        } else {
          this.$dialog
            .alert(this.$ml.get("advanced.nopass"), {
              loader: false,
              okText: "OK",
              backdropClose: true,
            })
            .then(function (dialog) {});
        }
      });
    },
    // getParams() {
    //     axios.get("/setting/default").then((response) => {
    //     if (response.status == 200) {
    //       this.$ml.change(response.data.result.lang);
    //       localStorage.setItem(
    //         "ovision-ml",
    //         JSON.stringify(response.data.result)
    //       );
    //     }
    //   });
    // }
  },
  created() {
    this.$root.$off("newvisit");
    this.$root.$off("beforevisit");
    this.$root.$off("alarmvisit");
    let self = this;
    this.$root.$on("newvisit", function (item) {
      self.newvisit(item);
    });
    this.$root.$on("beforevisit", function (item) {
      self.beforevisit(item);
    });
    this.$root.$on("alarmvisit", function (item) {
      self.alarmvisit(item);
    });
  },
  mounted() {
    if (localStorage.getItem("ovision-ml")) {
      try {
        this.l = JSON.parse(localStorage.getItem("ovision-ml"));
        //this.settings = response.data.settings || {};
        if (this.l.temperature_range != undefined) {
          let range = this.l.temperature_range.split(",");
          if (range.length > 1) this.tAlarm = range[1];
        }
        this.loaded = true;
      } catch (err) {
        this.loaded = true;
      }
    }
    localStorage.setItem("terminal.target.page", "/");

    if (localStorage.getItem("terminal.dascboard.tabs") !== null) {
      this.v = localStorage.getItem("terminal.dascboard.tabs");
    }
    this.setTab(this.v);

    setTimeout(function () {
      //console.log("!");
      document.location.reload();
    }, 3600000);
  },
};
</script>
